import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid2,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";

/*import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";*/
import MyPieChart from "../../../componentes/MyPieChart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Add, Delete, Edit } from "@mui/icons-material";
import api from "../../../services/api";
import { ptBR } from "@mui/x-data-grid/locales";

const columns = [
  { field: "categoria", headerName: "Categoria", width: 130 },
  {
    field: "subCategoria",
    headerName: "Sub-categoria",
    width: 400,
  },
  {
    field: "value",
    headerName: "Qtd",
    width: 80,
  },
];

const styles = {
  cardPie: {
    //height: 250,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "1rem"
  },
  cardContainer: {
    height: "100%",
  },
  cardTable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    gap: "20px",
  },
  boxDataGrid: {
    height: 280,
    // minHeight: 280,
    width: "100%",
    //paddingBottom: "5px",
    //paddingBottom: "10px"
  },
  boxPieChart: { width: "100%", height: 280 },
};

export default function DashboardPage() {
  const [dataFiltroInicial, setDataFiltroInicial] = useState(dayjs(new Date()));
  const [dataFiltroFinal, setDataFiltroFinal] = useState(dayjs(new Date()));
  const [data, setData] = useState(dayjs(new Date()));
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [qtdPorPessoa, setQtdPorPessoa] = useState([]);
  const [qtdCategorias, setQtdCategorias] = useState([]);
  const [qtdSubCategoria, setQtdSubCategoria] = useState([]);
  const [qtdClientes, setQtdClientes] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [qtdSubCategoriaPorData, setQtdSubCategoriaPorData] = useState();
  /* eslint-disable */

  //Demandas diáiras
  const [rowsDemandas, setRowsDemandas] = useState([]);
  const [qtdDemandasConcluidas, setQtdDemandasConcluidas] = useState(0);
  ////Demandas totais
  const [demandasTotaisInicial, setDemandasTotaisInicial] = useState(0);
  const [demandasTotaisFinal, setDemandasTotaisFinal] = useState(0);
  ////Demandas NOC
  const [demandasNOCInicial, setDemandasNOCInicial] = useState(0);
  const [demandasNOCFinal, setDemandasNOCFinal] = useState(0);
  const [comentario, setComentario] = useState("");
  const [demandaIdUpdate, setDemandaIdUpdate] = useState(0);

  useEffect(() => {
    async function handleDados() {
      const response = await api.post("/dashboard", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });

      if (response.data.qtdPorPessoa) {
        setQtdPorPessoa(response.data.qtdPorPessoa);
        setQtdCategorias(response.data.qtdCategorias);
        setQtdSubCategoria(response.data.qtdSubCategoria);
        setQtdClientes(response.data.qtdClientes);

        if (response.data.qtdSubCategoriaPorData) {
          const auxQtdSubCategoriaPorData =
            response.data.qtdSubCategoriaPorData.map((item) => {
              const formmatedDate = dayjs(item.date).format("DD/MM/YYYY");
              return { ...item, name: formmatedDate };
            });
          setQtdSubCategoriaPorData(auxQtdSubCategoriaPorData);
        }
      }
    }
    async function handleDemandas() {
      try {
        const response = await api.post("/demandas", {
          dataInicial: dataFiltroInicial.toString(),
          dataFinal: dataFiltroFinal.toString(),
        });
        if (response.data) {
          setRowsDemandas(response.data);
        }
      } catch (error) {
        console.error();
      }
    }

    handleDados();

    handleDemandas();
  }, [dataFiltroInicial, dataFiltroFinal]);

  useEffect(() => {
    const initialValue = 0;
    const sumWithInitial = qtdPorPessoa.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      initialValue
    );

    setQtdDemandasConcluidas(sumWithInitial);
  }, [qtdPorPessoa]);

  const clearModal = () => {
    ////demandas totais
    setDemandasTotaisInicial(0);
    setDemandasTotaisFinal(0);
    ////deemandas NOC
    setDemandasNOCInicial(0);
    setDemandasNOCFinal(0);
    setComentario("");
  };

  const sendRequest = async () => {
    try {
      const newElement = {
        demandasTotais_inicial: demandasTotaisInicial,
        demandasNoc_inicial: demandasNOCInicial,
        demandasTotais_final: demandasTotaisFinal,
        demandasNoc_final: demandasNOCFinal,
        comentario: comentario,
        demandasConcluidas: 0,
        data: data.toString(),
      };

      const response = await api.post("/createDemanda", newElement);

      const responseDemanda = await api.post("/demandas", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });
      setRowsDemandas(responseDemanda.data);
      clearModal();
      setOpenModal(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível salvar dados",
        severity: "error",
      });
    }
  };

  const sendUpdate = async () => {
    try {
      const newElement = {
        demandasTotais_inicial: demandasTotaisInicial,
        demandasNoc_inicial: demandasNOCInicial,
        demandasTotais_final: demandasTotaisFinal,
        demandasNoc_final: demandasNOCFinal,
        comentario: comentario,
        demandasConcluidas: 0,
        data: new Date(data),
        id: demandaIdUpdate,
      };

      const response = await api.put("/updateDemandas", newElement);

      const responseDemanda = await api.post("/demandas", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });
      setRowsDemandas(responseDemanda.data);
      clearModal();
      setOpenModalUpdate(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível salvar dados",
        severity: "error",
      });
    }
  };

  const sendRequestDelete = async (id) => {
    try {
      const newElement = {
        id: id,
      };

      const response = await api.post("/deleteDemandas", newElement);

      const responseDemanda = await api.post("/demandas", {
        dataInicial: dataFiltroInicial.toString(),
        dataFinal: dataFiltroFinal.toString(),
      });
      setRowsDemandas(responseDemanda.data);
      clearModal();
      setOpenModalUpdate(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível salvar dados",
        severity: "error",
      });
    }
  };

  const columnsDemandas = [
    { field: "id", headerName: "Id", width: 60 },
    {
      field: "demandasNoc_inicial",
      headerName: "Demandas inicio do dia",
      width: 160,
    },

    {
      field: "demandasNoc_final",
      headerName: "demandas final do dia",
      width: 170,
    },

    {
      field: "demandasConcluidas",
      headerName: "Demandas concluidas",
      width: 170,
    },

    {
      field: "data",
      headerName: "Data",
      width: 170,
    },
    {
      field: "comentario",
      headerName: "Comentários",
      //width: 370,
      flex: 1
    },
    {
      field: "actions",
      headerName: "ações",
      width: 100,
      sortable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          // const api = params.api;
          let thisRow = {};
          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );
          thisRow = params.row
          setDemandasNOCInicial(thisRow.demandasNoc_inicial);
          setDemandasNOCFinal(thisRow.demandasNoc_final);
          setComentario(thisRow.comentario);
          setDemandaIdUpdate(thisRow.id);
          setOpenModalUpdate(true);
          setData(dayjs(dayjs(thisRow.data, "DD-MM-YYYY").toDate()));
        };

        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          // const api = params.api;
          let thisRow = {};
          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );
          thisRow = params.row
          setDemandaIdUpdate(thisRow.id);
          sendRequestDelete(thisRow.id);
        };
        return (
          <Box>
            <IconButton onClick={onClick}>
              <Edit color={"primary"} />
            </IconButton>
            <IconButton onClick={onDelete}>
              <Delete color={"secondary"} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "30px",
      }}
    >
      <Box
        style={{
          width: "80vw",
          // padding: "20px",
          //minWidth: "900px",
        }}
      >
        <Grid2 container spacing={"1rem"}>
          <Grid2 size={6}  >
            <Stack ml={3}>
              <Typography component={"div"} variant={"h5"}>
                Filtros
              </Typography>
              <Stack direction={"row"} m={1}>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    sx={{ m: 1, width: "150px" }}
                    value={dataFiltroInicial}
                    onChange={(newValeu) => {
                      setDataFiltroInicial(newValeu);
                    }}
                    label="Data Inicial"
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"pt-br"}
                >
                  <DatePicker
                    sx={{ m: 1, width: "150px" }}
                    value={dataFiltroFinal}
                    onChange={(newValeu) => {
                      setDataFiltroFinal(newValeu);
                    }}
                    label="Data final"
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                  />
                </LocalizationProvider>
              </Stack></Stack>
          </Grid2>
          <Grid2 size={6}>
            <Card
              sx={{
                height: "200px",
                padding: "20px",
                display: "flex",
                justifyContent: "center",
              }}
              elevation={2}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  {"Quantidade de demandas concluídas"}
                </Typography>
                <Typography variant="h4" color={"TextPrimary"}>
                  {qtdDemandasConcluidas}
                </Typography>
                <Typography color={"TextSecondary"}>
                  entre {dayjs(dataFiltroInicial).format("DD/MM/YYYY")} e{" "}
                  {dayjs(dataFiltroFinal).format("DD/MM/YYYY")}
                </Typography>
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 size={12}>
            <Card
              sx={styles.cardContainer}
              elevation={2}
            >
              <CardContent
                sx={styles.cardTable}
              >
                <Stack direction={"row"} sx={{ justifyContent: "space-between", width: "100%", alignItems: "center" }}                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    color={"primary"}
                  >
                    {"Controle de Demandas"}
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<Add sx={{ marginTop: "-3px" }} />}
                    // sx={{
                    //   height: "30px",
                    //   marginTop: -1,
                    // }}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    NOVO
                  </Button>
                </Stack>
                <Box sx={styles.boxDataGrid}>
                  <DataGrid
                    rows={rowsDemandas}
                    columns={columnsDemandas}
                    density="compact"
                    pageSize={5}
                    disableSelectionOnClick
                    pageSizeOptions={[5]}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    sx={{
                      fontSize: 12,
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      '.MuiDataGrid-cell': { display: "flex", alignItems: 'center' },
                    }}
                    disableColumnMenu={true}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid2>

          <Grid2 size={6}>

            <Card sx={styles.cardContainer} elevation={2}>
              <CardContent
                sx={styles.cardTable}
              >
                <Typography
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  {"Demandas por categoria"}
                </Typography>

                <Box sx={styles.boxDataGrid}>
                  <DataGrid
                    rows={qtdSubCategoria}
                    columns={columns}
                    density="compact"
                    pageSize={5}
                    pageSizeOptions={[5]}
                    disableSelectionOnClick
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    sx={{
                      fontSize: 12,
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      '.MuiDataGrid-cell': { display: "flex", alignItems: 'center' },
                    }}
                    disableColumnMenu={true}
                  />
                </Box>
              </CardContent>
            </Card>

          </Grid2>

          <Grid2 size={6}>
            <Card elevation={2} sx={styles.cardContainer}>
              <CardContent
                sx={styles.cardPie}
              >
                <Typography
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  Demandas por cliente
                </Typography>
                <Box sx={styles.boxPieChart}>
                  <MyPieChart data={qtdClientes} />
                </Box>
              </CardContent>
            </Card>
          </Grid2>

          <Grid2 size={6}>

            <Card elevation={2} sx={styles.cardContainer}>
              <CardContent
                sx={styles.cardPie}
              >
                <Typography
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  Demandas por colaborador
                </Typography>
                <Box sx={styles.boxPieChart}>
                  <MyPieChart data={qtdPorPessoa} />
                </Box>
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 size={6}>
            <Card elevation={2} sx={styles.cardContainer}>
              <CardContent
                sx={styles.cardPie}
              >
                <Typography
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={"primary"}
                >
                  Quantidade por categoria
                </Typography>
                <Box sx={styles.boxPieChart}>
                  <ResponsiveContainer>
                    <MyPieChart data={qtdCategorias} />
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid2>

        </Grid2>
      </Box>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ padding: "15px" }}>
          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"h6"}>
              Demandas diárias
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DatePicker
                sx={{ m: 1, width: "150px" }}
                value={data}
                onChange={(newValeu) => {
                  setData(newValeu);
                }}
                label="Data"
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "7px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Inicio do dia
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  type="number"
                  variant="standard"
                  label="Demandas Noc"
                  sx={{ width: "150px", m: 1 }}
                  value={demandasNOCInicial}
                  onChange={(event) => {
                    setDemandasNOCInicial(event.target.value);
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Fim do dia
                </Typography>
              </Box>
              <TextField
                type="number"
                variant="standard"
                label="Demandas Noc"
                value={demandasNOCFinal}
                onChange={(event) => {
                  setDemandasNOCFinal(event.target.value);
                }}
                sx={{ width: "150px", m: 1 }}
              />
            </Box>
          </Box>

          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"subtitle1"}>
              Comentários
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
              id="input-comentario-create"
              value={comentario}
              variant="standard"
              sx={{ width: "330px" }}
              onChange={(event) => {
                if (event.target.value.length <= 255)
                  setComentario(event.target.value);
              }}
              type="text"
              multiline
              rows={3}
              slotProps={{ input: { maxLength: 255 } }}
              helperText="Limite de 255 dígitos"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                clearModal();
                setOpenModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={sendRequest}>
              Salvar
            </Button>
          </Box>
        </Paper>
      </Modal>

      <Modal
        open={openModalUpdate}
        onClose={() => {
          setOpenModalUpdate(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ padding: "15px" }}>
          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"h6"}>
              Demandas diárias
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={"pt-br"}
            >
              <DatePicker
                sx={{ m: 1, width: "150px" }}
                value={data}
                onChange={(newValeu) => {
                  setData(newValeu);
                }}
                label="Data"
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "7px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Inicio do dia
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  type="number"
                  variant="standard"
                  label="Demandas Noc"
                  sx={{ width: "150px", m: 1 }}
                  value={demandasNOCInicial}
                  onChange={(event) => {
                    setDemandasNOCInicial(event.target.value);
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "7px",
              }}
            >
              <Box sx={{ marginLeft: "7px" }}>
                <Typography color={"primary"} variant={"subtitle1"}>
                  Fim do dia
                </Typography>
              </Box>
              <TextField
                type="number"
                variant="standard"
                label="Demandas Noc"
                value={demandasNOCFinal}
                onChange={(event) => {
                  setDemandasNOCFinal(event.target.value);
                }}
                sx={{ width: "150px", m: 1 }}
              />
            </Box>
          </Box>

          <Box sx={{ marginLeft: "7px" }}>
            <Typography color={"primary"} variant={"subtitle1"}>
              Comentários
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
              id="input-comentario-update"
              value={comentario}
              variant="standard"
              sx={{ width: "330px" }}
              onChange={(event) => {
                if (event.target.value.length <= 255)
                  setComentario(event.target.value);
              }}
              type="text"
              multiline
              rows={3}
              slotProps={{ input: { maxLength: 255 } }}
              helperText="Limite de 255 dígitos"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                clearModal();
                setOpenModalUpdate(false);
              }}
            >
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={sendUpdate}>
              Salvar
            </Button>
          </Box>
        </Paper>
      </Modal>
      {
        !!snackbar && (
          <Snackbar
            open
            onClose={() => setSnackbar(null)}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )
      }
    </div >
  );
}
