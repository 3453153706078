import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";

export default function EditModalPort(params) {
  const {
    setOpenEditModal,
    openEditModal,
    setSnackbar,
    setLoadingTable,
    styles,
    selectedRow,
    handleEditPorts,
  } = params;
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [port, setPort] = useState("");
  const [operator, setOperator] = useState(null);
  useEffect(() => {
    if (selectedRow) {
      setPort(selectedRow.port);
      setOperator(selectedRow.operator);
    }
  }, [selectedRow]);
  useEffect(() => {
    if (!port || !operator) {
      setDisableEditButton(true);
    } else {
      setDisableEditButton(false);
    }
  }, [port, operator]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableEditButton === false) {
      handleApiPutPort();
    }
  };
  async function handleApiPutPort() {
    try {
      setLoadingTable(true);
      setDisableEditButton(true);
      const response = await api.put("/relatoriosFlow/port/update", {
        id: selectedRow.id,
        port: parseInt(port),
        operator,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleEditPorts(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableEditButton(false);
      setOpenEditModal(false);
    }
  }
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Edite Port </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"}>
              <FormControl sx={styles.TextField} variant="standard">
                <InputLabel>Operador</InputLabel>
                <Select
                  value={operator}
                  label="Operador"
                  onChange={(event) => {
                    setOperator(event.target.value);
                  }}
                >
                  <MenuItem value={">"}>{">"}</MenuItem>
                  <MenuItem value={"<"}>{"<"}</MenuItem>
                  <MenuItem value={"="}>{"="}</MenuItem>
                </Select>
              </FormControl>{" "}
              <TextField
                sx={styles.TextField}
                id="textfield-porta"
                label="Porta"
                variant="standard"
                type="number"
                onKeyDown={handleKeyDown}
                value={port}
                onChange={(e) => setPort(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenEditModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableEditButton}
              onClick={() => {
                handleApiPutPort();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
