import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import MyResponsiveBar from "../../../componentes/nivo/responsiveBar";
import MyResponsiveBarHorizontal from "../../../componentes/nivo/responsiveBarHorizontal";
import GroupIcon from "@mui/icons-material/Group";
import GradingIcon from "@mui/icons-material/Grading";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import api from "../../../services/api";
import { useEffect, useState } from "react";
import flow from "../../../assets/flow.png";
import mon from "../../../assets/mon.png";
import preguicoso from "../../../assets/preguicoso.svg";
import feliz from "../../../assets/criancas-felizes.svg";
import { useTheme } from "@mui/material/styles";

export default function Acompanhamento() {
  const [snackbar, setSnackbar] = useState(null);
  const [colaboradores, setColaboradores] = useState([]);
  const [demandasSemResp, setDemandasSemResp] = useState([]);
  const [statusAtual, setStatusAtual] = useState([]);
  const [demandasConcluidas, setDemandasConcluidas] = useState([]);
  const [sizeBarHorizontal, setSizeBarHorizontal] = useState(200);
  const theme = useTheme();
  const styles = {
    backgroundTagCard: {
      background: theme.palette.mode == "dark" ? "rgba(0,0,0,0.5)" : "#5B2E87",
      color: "white",
    },
    nowrap: {
      whiteSpace: "nowrap" /* Impede a quebra de linha */,
      overflow: "hidden" /* Oculta o texto que não couber */,
      textOverflow: "ellipsis",
    },
    titleGrafico: {
      fontSize: "19px",
      padding: "10px",
      whiteSpace: "nowrap" /* Impede a quebra de linha */,
      overflow: "hidden" /* Oculta o texto que não couber */,
      textOverflow: "ellipsis",
    },
    cardContent: {
      padding: "0px",
      mt: "0px",
      mb: "-20px",
      width: "100%",
      height: "200px",
    },

    titleContent: {
      display: "flex",
      direction: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    customScrollbar: {
      overflow: "auto",
      scrollbarWidth: "thin" /* For Firefox */,
      scrollbarColor: "#888888 #f0f0f0" /* For Firefox */,

      "&::-webkit-scrollbar": {
        width: "10px",
      },

      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.5)",
        borderRadius: "10px",
      },
    },
  };

  useEffect(() => {
    let concluidas = demandasConcluidas.length;
    if (concluidas < 6) {
      setSizeBarHorizontal(170);
    } else {
      setSizeBarHorizontal(demandasConcluidas.length * 30);
    }
  }, [demandasConcluidas]);

  useEffect(() => {
    handleApiGetdashboard();
    setInterval(() => {
      handleApiGetdashboard();
    }, 3 * 60000);
    async function handleApiGetdashboard() {
      try {
        const response = await api.get("/formularios/dashboard/noc");

        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar dados",
            severity: "error",
          });
        } else {
          if (response.data) {
            response.data.colaboradores.map((colab, index) => {
              let labels = [
                "Pendente",
                "Andamento ",
                "",
                "Revisão",
                "Agr. Retorno",
              ];
              let cores = ["#881324", "#BE5602", "", "#077CB1", "#502876"];
              let cont = 0;

              colab.demandas = Object.entries(colab.demandas).map(
                ([id, value]) => {
                  cont += value;
                  return { id: labels[id], value: value, color: cores[id] };
                }
              );
              let demandas = [];
              if (colab.demandas.length > 0) {
                demandas = labels
                  .map((label, index) => {
                    return { id: label, value: 0, color: cores[index] };
                  })
                  .filter((dem) => dem.id != "");

                demandas.map((demandax) => {
                  colab.demandas.map((dem) => {
                    if (dem.id === demandax.id) demandax.value = dem.value;
                  });
                });
              }

              colab.demandas = demandas.reverse();
              colab["cont"] = cont;
            });
            setColaboradores(response.data.colaboradores);

            if (
              response.data.demandasAbertas !== 0 ||
              response.data.demandasAtrasadas !== 0 ||
              response.data.demandasAbertas30dias !== 0
            ) {
              setStatusAtual([
                {
                  id: "Em aberto",
                  value: response.data.demandasAbertas,
                },
                {
                  id: "Vencidas",
                  value: response.data.demandasAtrasadas,
                },
                {
                  id: "30 dias Aberto",
                  value: response.data.demandasAbertas30dias,
                },
              ]);
            }

            let concluidas = response.data.demandasConcluidasnoMes;

            response.data.colaboradores.map((colab) => {
              if (!concluidas.some((con) => con.id === colab.nome))
                concluidas.push({
                  id: colab.nome,
                  value: 0,
                  color: "#75ae33",
                });
            });
            concluidas.sort((a, b) => a.value - b.value);

            setDemandasConcluidas(concluidas);

            let semResp = response.data.demandasSemResp;
            semResp = Object.entries(semResp).map(([id, value]) => {
              return { id: id, value: value };
            });
            setDemandasSemResp(semResp);
          }
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      }
    }
  }, []);

  return (
    <Grid container spacing={2} columns={5}>
      <Grid item key={"grid1"} xs={5} smd={1.75}>
        <Card>
          <Box sx={styles.titleContent}>
            <PendingActionsIcon />
            <Typography sx={styles.titleGrafico}>
              Status Atual das Demandas
            </Typography>
          </Box>
          <CardContent sx={styles.cardContent}>
            {statusAtual.length !== 0 && (
              <MyResponsiveBar
                key={"graf1"}
                keyy={"grafi1"}
                data={statusAtual}
              />
            )}

            {statusAtual.length == 0 && (
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="80%"
                sx={{ opacity: "0.5" }}
              >
                <img height="70px" width="70px" src={preguicoso} />
                <Box sx={{ color: "black", fontSize: "20px" }}>
                  Sem Pendências
                </Box>
              </Stack>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid key={"grid2"} item xs={5} smd={1.5}>
        <Card>
          <CardContent
            sx={[styles.cardContent, { padding: "10px", height: "250px" }]}
          >
            <Stack
              direction="column"
              sx={{
                background: "rgba(0,0,0,0.2)",
                padding: "10px",
                height: "95%",
              }}
            >
              <Stack direction="row">
                <Typography
                  width="50%"
                  sx={[styles.nowrap, { fontWeight: "600" }]}
                >
                  Provedor
                </Typography>
                <Typography
                  width="50%"
                  sx={[
                    styles.nowrap,
                    { fontWeight: "600", textAlign: "center" },
                  ]}
                >
                  Demandas
                </Typography>
              </Stack>
              <Divider sx={{ mt: 0.5, mb: 0.5 }}></Divider>
              <Box
                sx={[
                  styles.customScrollbar,
                  { maxHeight: "160px", padding: "5px" },
                ]}
              >
                {demandasSemResp.map((row, index) => (
                  <Box key={"line" + index}>
                    <Stack direction="row">
                      <Typography width="50%" sx={styles.nowrap}>
                        {row.id}
                      </Typography>
                      <Typography width="50%" sx={{ textAlign: "center" }}>
                        {row.value}
                      </Typography>
                    </Stack>
                    <Divider sx={{ mt: 0.5, mb: 0.5 }}></Divider>
                  </Box>
                ))}
                {demandasSemResp.length < 1 && (
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="130px"
                    sx={{ opacity: "0.6", filter: "invert(20%)" }}
                  >
                    <img height="50px" width="50px" src={feliz} />
                    <Box sx={{ color: "black", fontSize: "18px" }}>
                      Sem Demandas
                    </Box>
                  </Stack>
                )}
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid key={"grid3"} item xs={5} smd={1.75}>
        <Card>
          <Box sx={styles.titleContent}>
            <GradingIcon />
            <Typography sx={styles.titleGrafico}>
              Demandas Concluidas nesse Mês
            </Typography>
          </Box>
          <CardContent sx={[styles.cardContent, styles.customScrollbar]}>
            <Box sx={{ height: `${sizeBarHorizontal}px` }}>
              <MyResponsiveBarHorizontal
                key={"graf2"}
                keyy={"grafi2"}
                data={demandasConcluidas}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid key={"grid4"} item xs={5}>
        <Paper sx={styles.titleContent}>
          <GroupIcon />
          <Typography sx={styles.titleGrafico}>Colaboradores</Typography>
        </Paper>
      </Grid>
      {colaboradores.map((col, index) => {
        return (
          <Grid
            item
            xs={5}
            smd={5 / 2}
            lg={5 / 3}
            xl={5 / 4}
            fh={1}
            key={"grid" + (index + 4)}
          >
            <Card>
              <Stack direction="row">
                <Box
                  sx={{
                    backgroundColor:
                      col.setor == "NOC-EASYFLOW"
                        ? "#24c784"
                        : col.setor == "NOC-EASYMON"
                        ? "#a83a1c"
                        : "",
                    width: "1%",
                    height: "250px",
                  }}
                ></Box>
                <Box sx={{ width: "99%" }}>
                  <Stack
                    justifyContent={"space-between"}
                    direction="row"
                    alignItems={"center"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        direction: "row",
                        alignItems: "center",
                        m: "10px 0px 0px 10px",
                      }}
                    >
                      <Avatar
                        sx={{ width: "45px", height: "45px" }}
                        alt={col.nome}
                        src={col.img}
                      />
                      <Typography
                        sx={[
                          styles.nowrap,
                          {
                            fontSize: "16px",
                            ml: "10px",
                            width: "100px",
                          },
                        ]}
                      >
                        {col.nome}{" "}
                      </Typography>
                    </Box>
                    <Stack sx={{ ml: "10px" }} direction="row">
                      <Box
                        sx={[
                          styles.backgroundTagCard,
                          {
                            padding: "7px",
                            mr: "10px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                          },
                        ]}
                      >
                        <img
                          width="85px"
                          src={
                            col.setor == "NOC-EASYFLOW"
                              ? flow
                              : col.setor == "NOC-EASYMON"
                              ? mon
                              : null
                          }
                        />
                      </Box>

                      <Typography
                        sx={[
                          styles.backgroundTagCard,
                          { fontSize: "20px", padding: "15px" },
                        ]}
                      >
                        {col.cont}
                      </Typography>
                    </Stack>
                  </Stack>
                  <CardContent
                    sx={[
                      styles.cardContent,
                      {
                        mt: 1,
                        display: "flex",
                        direction: "column",
                        alignItems: "start",
                        justifyContent: "start",
                      },
                    ]}
                  >
                    {col.demandas.length !== 0 && (
                      <MyResponsiveBar
                        key={"grafx" + index}
                        keyy={"grafix" + index}
                        data={col.demandas}
                      />
                    )}
                    {col.demandas.length === 0 && (
                      <Stack
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        height="80%"
                        sx={{ opacity: "0.5" }}
                      >
                        <img height="70px" width="70px" src={preguicoso} />
                        <Box sx={{ color: "black", fontSize: "20px" }}>
                          Sem Demandas
                        </Box>
                      </Stack>
                    )}
                  </CardContent>
                </Box>
              </Stack>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
