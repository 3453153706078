import { memo } from "react";
import EditCellAutoComplete from "../../../componentes/easyAdd/editCellAutoComplete";
import { CellExibitionAlerts } from "./exibitionCell";
import { handleAlert } from "../../../utils/easyAdd/alert";
import EditCellAutoCompleteModelo from "../../../componentes/easyAdd/editCellAutoCompleteModelo";

const validaColObrigatoria = (params) => {
  let errorMessage = null;
  errorMessage = !params?.value ? "Campo obrigatório" : null;
  return errorMessage;
};

export const RenderModeloCellWithMemo = memo(
  ({ params, predefinicoes, selected }) => {
    // Encontra as funções predefinidas para o fabricante específico
    const { Funcoes: funcoes = [] } =
      predefinicoes?.find(
        ({ fabricante }) =>
          fabricante?.toLowerCase() === params.row?.fabricante?.toLowerCase()
      ) || {};

    // Encontra os modelos predefinidos para a função específica
    const { Modelos: modelos = [] } =
      funcoes?.find(
        ({ funcao }) =>
          funcao?.toLowerCase() === params.row?.funcao?.toLowerCase()
      ) || {};

    let alert = handleAlert(modelos, "modelo", params?.value);

    return (
      <CellExibitionAlerts
        value={params.value?.toUpperCase()}
        alert={alert}
        selected={selected}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.params.row.fabricante?.toLowerCase() ===
        nextProps.params.row.fabricante?.toLowerCase() &&
      prevProps.params.row.funcao?.toLowerCase() ===
        nextProps.params.row.funcao?.toLowerCase() &&
      prevProps.selected === nextProps.selected
    );
  }
);

// Componente renderEditCell otimizado com React.memo
export const RenderModeloEditCellWithMemo = memo(
  ({ params, predefinicoes, setIsEdit, selected, setHostsEdit }) => {
    // Encontra as funções predefinidas para o fabricante específico
    const { Funcoes: funcoes = [] } =
      predefinicoes?.find(
        ({ fabricante }) =>
          fabricante?.toLowerCase() === params.row?.fabricante?.toLowerCase()
      ) || {};

    // Encontra os modelos predefinidos para a função específica
    const { Modelos: modelos = [] } =
      funcoes?.find(
        ({ funcao }) =>
          funcao?.toLowerCase() === params.row?.funcao?.toLowerCase()
      ) || {};

    return (
      <EditCellAutoCompleteModelo
        {...params}
        lista={modelos}
        setIsEdit={setIsEdit}
        selected={selected}
        setHostsEdit={setHostsEdit}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.params.row.fabricante?.toLowerCase() ===
        nextProps.params.row.fabricante?.toLowerCase() &&
      prevProps.params.row.funcao?.toLowerCase() ===
        nextProps.params.row.funcao?.toLowerCase() &&
      prevProps.selected === nextProps.selected
    );
  }
);

export const RenderCellWithMemo = memo(
  ({ params, proxys, selected }) => {
    let proxs = proxys.map((a) => ({ coletor: a.host }));
    let alert = !proxs.some((a) => a.coletor === params.value);

    return (
      <CellExibitionAlerts
        value={params.value?.toUpperCase()}
        alert={alert}
        text="no cliente"
        selected={selected}
        //error={validaColObrigatoria(params)}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params.value ou proxys mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.proxys === nextProps.proxys &&
      prevProps.selected === nextProps.selected
    );
  }
);

export const RenderEditCellWithMemo = memo(
  ({ params, proxys, setIsEdit, selected }) => {
    let proxs = proxys.map((a) => ({ coletor: a.host }));

    return (
      <EditCellAutoComplete
        {...params}
        lista={proxs}
        error={validaColObrigatoria(params)}
        setIsEdit={setIsEdit}
        selected={selected}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou proxys mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.proxys === nextProps.proxys &&
      prevProps.selected === nextProps.selected
    );
  }
);

export const RenderFuncaoCellWithMemo = memo(
  ({ params, predefinicoes, selected }) => {
    // Encontra as funções predefinidas para o fabricante específico
    const { Funcoes: funcoes = [] } =
      predefinicoes.find(
        ({ fabricante }) =>
          fabricante?.toLowerCase() === params.row?.fabricante?.toLowerCase()
      ) || {};
    // Verifica se há algum alerta com base na função
    let alert = handleAlert(funcoes, "funcao", params?.value);

    return (
      <CellExibitionAlerts
        value={params.value?.toUpperCase()}
        alert={alert}
        selected={selected}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.params.row.fabricante?.toLowerCase() ===
        nextProps.params.row.fabricante?.toLowerCase() &&
      prevProps.selected === nextProps.selected
    );
  }
);

// Componente renderEditCell otimizado com React.memo
export const RenderFuncaoEditCellWithMemo = memo(
  ({ params, predefinicoes, setIsEdit, selected }) => {
    // Encontra as funções predefinidas para o fabricante específico
    const { Funcoes: funcoes = [] } =
      predefinicoes.find(
        ({ fabricante }) =>
          fabricante?.toLowerCase() === params.row?.fabricante?.toLowerCase()
      ) || {};

    return (
      <EditCellAutoComplete
        {...params}
        lista={funcoes}
        setIsEdit={setIsEdit}
        selected={selected}
      />
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.params.value?.toLowerCase() ===
        nextProps.params.value?.toLowerCase() &&
      prevProps.params.row.fabricante?.toLowerCase() ===
        nextProps.params.row.fabricante?.toLowerCase() &&
      prevProps.selected === nextProps.selected
    );
  }
);
