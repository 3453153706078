import {
  Alert,
  Autocomplete,
  Box,
  Fade,
  LinearProgress,
  Paper,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import Router from "../relatoriosFlow/router";
import Interfaces from "./interfaces";
import Blocos from "./blocos";
import ASNs from "./asn";
import CDNs from "./cdn";
import Ports from "./port";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    gap: "2rem",
    marginTop: "30px",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    minWidth: "100%", width: "100%",
    flexDirection: "column",
  },
};

export default function RelatoriosFlow() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [cliente, setCliente] = useState(null);
  const tipo = "easyflow";
  const [easyflow, setEasyFlow] = useState(null);
  const [acessoClientesId, setAcessoClientesId] = useState("");
  const [acessoProdutoId, setAcessoProdutoId] = useState("");
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [loadingProdutos, setLoadingProdutos] = useState(false);
  const [disableButtonEasyFLow, setDisableButtonEasyFLow] = useState(true);

  const getClientesLabel = (clientes) =>
    `${clientes.numero} - ${clientes.empresa}`;
  const getProdutosLabel = (produtos) => `${produtos.nome}`;

  useEffect(() => {
    handleApiGetClientes();
  }, []);
  useEffect(() => {
    if (cliente) {
      setDisableButtonEasyFLow(false);
      handleApiGetProdutos(cliente.id, tipo);
    }
  }, [cliente]);
  async function handleApiGetClientes() {
    try {
      setLoadingClientes(true);
      const response = await api.get("/clienteAcesso");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingClientes(false);
    }
  }
  async function handleApiGetProdutos(acessoClientesId, tipo) {
    try {
      setLoadingProdutos(true);
      const response = await api.post("/produtoCliente", {
        acessoClientesId,
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
        if (response.data?.length === 1) {
          setEasyFlow(response.data[0]);
          setAcessoProdutoId(response.data[0].id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingProdutos(false);
    }
  }
  return (
    <>
      <Box sx={styles.container}>
        <Paper sx={styles.paperCliente}>
          <Box>
            <Stack direction={"row"} gap={4}>
              <Box>
                <Typography
                  color={"primary"}
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  Selecione um Cliente
                </Typography>
                <Autocomplete
                  sx={{ m: 1, width: "180px", ml: 0 }}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                    setAcessoClientesId(newValue.id);
                    setEasyFlow(null);
                    setAcessoProdutoId("");
                  }}
                  value={cliente}
                  options={clientes}
                  getOptionLabel={getClientesLabel}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                      <Fade in={loadingClientes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  disabled={disableButtonEasyFLow}
                  sx={{ m: 1, mt: 4.8, width: "200px" }}
                  onChange={(event, newValue) => {
                    setEasyFlow(newValue);
                    setAcessoProdutoId(newValue.id);
                  }}
                  value={easyflow}
                  options={produtos}
                  getOptionLabel={getProdutosLabel}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Selecione o EasyFlow"
                        variant="standard"
                      />
                      <Fade in={loadingProdutos}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Box>{" "}
        </Paper>
        <Paper
          sx={{
            borderRadius: "15px",
            minWidth: "260px",
            maxWidth: "870px",
            padding: "0px 10px",
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons={true}
            value={value}
            sx={styles.tabs}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <Tab
              label={"Router"}
              iconPosition="start"
              value={0}
              sx={styles.tab}
            />
            <Tab
              label={"Interfaces"}
              iconPosition="start"
              value={1}
              sx={styles.tab}
            />
            <Tab
              label={"Blocos"}
              iconPosition="start"
              value={2}
              sx={styles.tab}
            />
            <Tab
              label={"ASNs"}
              iconPosition="start"
              value={3}
              sx={styles.tab}
            />
            <Tab
              label={"CDNs"}
              iconPosition="start"
              value={4}
              sx={styles.tab}
            />
            <Tab
              label={"Ports"}
              iconPosition="start"
              value={5}
              sx={styles.tab}
            />
          </Tabs>
        </Paper>

        {value === 0 && (
          <Router
            acessoClientesId={acessoClientesId}
            acessoProdutoId={acessoProdutoId}
            key={1}
          />
        )}
        {value === 1 && (
          <Interfaces
            acessoClientesId={acessoClientesId}
            acessoProdutoId={acessoProdutoId}
            key={2}
          />
        )}
        {value === 2 && (
          <Blocos
            acessoClientesId={acessoClientesId}
            acessoProdutoId={acessoProdutoId}
            key={3}
          />
        )}
        {value === 3 && (
          <ASNs
            acessoClientesId={acessoClientesId}
            acessoProdutoId={acessoProdutoId}
            key={4}
          />
        )}
        {value === 4 && (
          <CDNs
            acessoClientesId={acessoClientesId}
            acessoProdutoId={acessoProdutoId}
            key={5}
          />
        )}
        {value === 5 && (
          <Ports
            acessoClientesId={acessoClientesId}
            acessoProdutoId={acessoProdutoId}
            key={6}
          />
        )}
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
