import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";

export default function CreateModalBlocos(params) {
  const {
    setOpenCreateModal,
    openCreateModal,
    setSnackbar,
    setLoadingTable,
    acessoProdutoId,
    acessoClientesId,
    styles,
    setErrorName,
    errorName,
    comparaNome,
    handleNewBlocos,
    validarIp,
  } = params;
  const [nome, setNome] = useState("");
  const [listaBloco, setListaBloco] = useState("");
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const validarIps = (ips) => {
    const arrayDeIps = ips?.split(",");
    let verificaIps = false;
    if (Array.isArray(arrayDeIps) && arrayDeIps.length > 0) {
      verificaIps = arrayDeIps.some((ip) => !validarIp(ip));
    }
    return verificaIps;
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableCreateButton === false) {
      handleApiPostBlocos();
    }
  };
  const handleHelperText = (ips) => {
    if (ips) {
      return validarIps(ips)
        ? "Formato de algum(s) do(s) IP(s) é inválido"
        : "Os endereços são válidos!";
    }
    return null;
  };

  useEffect(() => {
    if (comparaNome(nome) || validarIps(listaBloco) || !listaBloco) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [nome, listaBloco]);
  function clearModal() {
    setNome("");
    setListaBloco("");
  }
  async function handleApiPostBlocos() {
    try {
      setLoadingTable(true);
      setDisableCreateButton(true);
      const response = await api.post("/relatoriosFlow/blocos/create", {
        nome,
        blocos: listaBloco,
        acessoClientesId,
        acessoProdutoId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleNewBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableCreateButton(false);
      setOpenCreateModal(false);
      clearModal();
    }
  }

  return (
    <>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          clearModal();
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Novo Bloco </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="column" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-nome"
                label="Nome"
                variant="standard"
                multiline
                value={nome}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  if (comparaNome(e.target.value)) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setNome(e.target.value);
                }}
              />
              <TextField
                sx={styles.TextField}
                id="textfield-blocos"
                label="Bloco(s)"
                variant="standard"
                onKeyDown={handleKeyDown}
                value={listaBloco}
                error={listaBloco ? validarIps(listaBloco) : false}
                helperText={handleHelperText(listaBloco)}
                onChange={(e) =>
                  setListaBloco(e.target.value.replace(/\s/g, ""))
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCreateModal(false);
                clearModal();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableCreateButton}
              onClick={() => {
                handleApiPostBlocos();
              }}
            >
              Criar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
