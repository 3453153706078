import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import background from "../../assets/headerHome.jpeg";
import { useParams } from "react-router-dom";
import logo from "../../assets/logoRoxa.svg";

export default function RedefinirSenha() {
  const [textFeedback, setTextFeedback] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [senhaValid, setSenhaValid] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const url = `${window?.config?.REACT_APP_BASE_URL || ''}`;
  const { id, token } = useParams();

  useEffect(() => {
    if (senha.length >= 6 && /[a-zA-Z]/.test(senha) && /\d/.test(senha))
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [senha]);
  useEffect(() => {
    async function permissionValidate() {
      try {
        await axios.get(
          `${url}/authenticationTokenUpdatePassword`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

      } catch (error) {
        if (error?.response?.status === 401) {
          setTextFeedback(
            "Infelizmente o link expirou, solicite outro link no site da MW e tente novamente!"
          );
          setFeedback(true);
        } else {
          setAlertServiceOpen(true);
        }
      }
    }
    permissionValidate();
  }, []);

  const handleUpdate = async () => {
    if (!senha || !confirmSenha || confirmSenha !== senha || !senhaValid) {
      setNull(true);
      return;
    }


    try {
      await axios.put(
        `${url}/usuario/resetPassword/update`,
        {
          password: senha,
          usuarioId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTextFeedback(
        "Senha atualizada com sucesso!"
      );
      setFeedback(true);
    } catch (error) {

      if (error?.response?.status === 404) {
        setAlertOpen(true);
      } else {
        setAlertServiceOpen(true);
      }
    }

  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };

  return (
    <>
      <Box
        sx={{
          zIndex: -1,
          height: "100vh",
          width: "100vw",
          position: "absolute",
          top: 0,
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          filter: "blur(5px)",
        }}
      />
      <Paper
        sx={{
          minWidth: "350px",
          padding: "20px 50px",
          width: { xs: "90vw", sm: "500px" },
          margin: "auto",
          marginTop: "20vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Stack
          direction={"column"}
          gap={"15px"}
          alignItems={"center"}
          sx={{ padding: "0px", width: "100%" }}
        >
          <img
            src={logo}
            style={{ height: "auto", width: "250px" }}
            alt="logo mw"
          />
          {!feedback && (
            <>
              <Typography
                sx={{
                  fontSize: "1em",
                  fontFamily: "Rubik",
                  textAlign: "center",
                }}
              >
                Digite a nova senha e confirme em seguida.
              </Typography>

              <FormControl
                sx={{ m: 1, width: "90%", marginTop: "10px" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Senha
                </InputLabel>
                <OutlinedInput
                  error={(!senha || !senhaValid) && Null}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => {
                    setSenha(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Senha"
                />
                {(!senha || !senhaValid) && Null && (
                  <FormHelperText error id="accountId-error">
                    A senha precisa ter no mínimo 6 digítos, uma letra e um
                    número.
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                sx={{ m: 1, width: "90%", marginTop: "10px" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password2">
                  Confirmar Senha
                </InputLabel>
                <OutlinedInput
                  error={(!senha && Null) || (Null && senha !== confirmSenha)}
                  id="outlined-adornment-password2"
                  type={showPassword ? "text" : "password"}
                  onKeyDown={handleKeyDown}
                  onChange={(event) => {
                    setConfirmSenha(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirmar Senha"
                />

                {Null && (!senha || senha !== confirmSenha) && (
                  <FormHelperText error id="accountId-error">
                    As senhas não conferem!
                  </FormHelperText>
                )}
              </FormControl>

              <Button
                variant="contained"
                onClick={() => {
                  handleUpdate();
                }}
                sx={{
                  width: "90%",
                  borderRadius: "5px",
                  marginTop: "10px",
                  height: "40px",
                  marginBottom: "20px",
                }}
              >
                Redefinir a Senha
              </Button>
            </>
          )}
          {feedback && (
            <>
              <Typography
                sx={{
                  fontSize: "1.2em",
                  fontFamily: "Rubik",
                  textAlign: "center",
                  mb: "20px",
                  padding: "10px",
                  backgroundColor: "rgba(0, 0, 0,0.1)",
                }}
              >
                {textFeedback}
              </Typography>
              <Button
                variant="contained"
                href="/login"
                sx={{
                  width: "90%",
                  borderRadius: "5px",
                  marginTop: "10px",
                  height: "40px",
                  marginBottom: "20px",
                  textTransform: "none",
                }}
              >
                Página de login
              </Button>
            </>
          )}
          <hr style={{ width: "100%" }} />
          <Typography sx={{ textAlign: "center " }}>
            Precisa de ajuda?{"  "}
            <br></br>
            <Link href="/login" sx={{ textDecoration: "none" }}>
              Entre em contato com nossa equipe
            </Link>
          </Typography>
        </Stack>
      </Paper>

      <Snackbar
        autoHideDuration={6000}
        open={alertServiceOpen}
        onClose={() => {
          setAlertServiceOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Não foi possível se conectar ao Servidor
        </Alert>
      </Snackbar>
    </>
  );
}
