import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button, DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  TextField,
  Stack,
  Icon,
  CircularProgress,
  Autocomplete
} from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Add } from "@mui/icons-material";

import api from "../../../services/api";

import Table from "../../../componentes/table/table";

import mwIcon from "../../../assets/logo4.png";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function Relatorios() {
  //modal variaveis
  const [open, setOpen] = useState(false);
  const [categoria, setCategoria] = useState("");
  const [subcategoria, setSubcategoria] = useState("");
  const [codigo, setCodigo] = useState("");
  const [cliente, setCliente] = useState(null);
  const [parceiro, setParceiro] = useState(null);
  const [colaborador, setColaborador] = useState("");
  const [data, setData] = useState(dayjs(new Date()));
  const [snackbar, setSnackbar] = useState(null);
  const [confirmarLoading, setConfirmarLoading] = useState(false);
  //loading variavel
  const [loadingPage, setLoadingPage] = useState(false);
  const [opacity, setOpacity] = useState("0%");
  //linhas da tabela
  const [rows, setRows] = useState([]);
  //api variaveis
  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubcategorias] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);

  const [clientes, setClietes] = useState([]);
  useEffect(() => {


    async function apiGetCategorias() {
      try {
        const response = await api.get("/categorias");
        return response.data;
      } catch (error) {
        console.log(error)
        setSnackbar({
          children: "Error: Não foi possível buscar categorias",
          severity: "error",
        });
      }

    }

    async function apiGetSubCategoria() {
      try {
        const response = await api.get("/subCategorias");

        return response.data;
      } catch (error) {
        console.log(error)
        setSnackbar({
          children: "Error: Não foi possível buscar subCategorias",
          severity: "error",
        });
      }
    }


    async function apiGetColaboradores() {
      try {
        const response = await api.get("/colaboradores");

        return response.data;
      } catch (error) {
        console.log(error)
        setSnackbar({
          children: "Error: Não foi possível buscar colaboradores",
          severity: "error",
        });
      }
    }

    async function apiGetRelatorios() {
      try {
        const response = await api.get("/relatorios");

        return response.data;
      } catch (error) {
        console.log(error)
        setSnackbar({
          children: "Error: Não foi possível buscar Relatorios",
          severity: "error",
        });
      }
    }

    async function apiGetClientes() {
      try {
        const response = await api.get("/clientes");

        return response.data;
      } catch (error) {
        console.log(error)
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      }
    }

    async function apiget(params) {
      try {
        const data = await Promise.all([apiGetCategorias(), apiGetSubCategoria(), apiGetColaboradores(), apiGetRelatorios(), apiGetClientes()]);

        setCategorias(data[0]);
        setSubcategorias(data[1]);
        setColaboradores(data[2]);
        setRows(data[3]);
        setClietes(data[4]);
        setLoadingPage(true);
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar ao servidor",
          severity: "error",
        });
      }
    }
    apiget();
  }, []);
  useEffect(() => {
    const opacitySpeed = 200;
    let timer = setTimeout(() => {
      setOpacity("20%");
    }, 1 * opacitySpeed);
    timer = setTimeout(() => {
      setOpacity("40%");
    }, 2 * opacitySpeed);
    timer = setTimeout(() => {
      setOpacity("60%");
    }, 3 * opacitySpeed);
    timer = setTimeout(() => {
      setOpacity("80%");
    }, 4 * opacitySpeed);
    timer = setTimeout(() => {
      setOpacity("100%");
    }, 5 * opacitySpeed);

    return () => clearTimeout(timer);
  }, []);

  const clearModal = () => {
    setColaborador("");
    setCodigo("");
    setCategoria("");
    setSubcategoria("");
    setCliente(null);
    setParceiro(null);
    setData(dayjs(new Date()));
  };

  const sendRequest = async () => {
    try {
      setConfirmarLoading(true);
      const newElement = {
        nome: colaborador,
        codigo: codigo,
        categoria: categoria,
        subcategoria: subcategoria,
        parceiro: parceiro.parceiro.toUpperCase(),
        cliente: `${cliente.numero} ${cliente.cliente}`,
        AcessoClienteId: cliente.id,
        data: dayjs(data).format("DD/MM/YYYY"),
      };

      const response = await api.post("/createRelatorio", newElement);
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        const responseRelatorios = await api.get("/relatorios");
        setRows(responseRelatorios.data);
        clearModal();
        setOpen(false);
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
      setConfirmarLoading(false);
    } catch (error) {
      setConfirmarLoading(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar ao servidor",
        severity: "error",
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "30px",
      }}
    >
      <Paper
        style={{
          padding: "20px 40px",
          width: "80vw",
          minWidth: "800px",
          minHeight: "80vh",
        }}
      >
        {!loadingPage && (
          <Box
            sx={{
              m: 1,
              position: "relative",
              marginLeft: "50%",
              marginTop: "20%",
              opacity: `${opacity}`,
            }}
          >
            <Icon style={{ width: 80, height: 80 }}>
              <img
                src={mwIcon}
                style={{ width: 80, height: 80 }}
                alt="mw icon"
              />
            </Icon>

            <CircularProgress
              size={100}
              sx={{
                color: "primary",
                position: "absolute",
                top: -9,
                left: -6,
                zIndex: 1,
              }}
              thickness={2.5}
            />
          </Box>
        )}
        {loadingPage && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%"
                // width: 1150,
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
                endIcon={<Add />}
              >
                NOVO
              </Button>
            </Box>
            <Box
              style={{
                //width: 1150,
                width: "100%",
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Table
                rows={rows}
                setRows={setRows}
                categorias={categorias}
                subCategorias={subCategorias}
                colaboradores={colaboradores}
                clientes={clientes}
              />
            </Box>
          </Box>
        )}
      </Paper>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          clearModal();
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper sx={{ width: "580px", padding: "10px", paddingRight: "25px" }}>
          <Box>
            <DialogTitle color={"primary"}>Preencha o relatorio</DialogTitle>
            <DialogContent>
              <Stack direction={"row"}>
                <Box>
                  <FormControl variant="standard" sx={{ m: 1, width: "180px" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Colaborador
                    </InputLabel>
                    <Select
                      label={"Colaborador"}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={colaborador}
                      onChange={(event) => {
                        setColaborador(event.target.value);
                      }}
                    >
                      {colaboradores?.map((colaboradores, index) => (
                        <MenuItem key={index} value={colaboradores.nome}>
                          {colaboradores.nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <TextField
                    sx={{ m: 1, width: "140px" }}
                    variant="standard"
                    label={"Código"}
                    value={codigo}
                    onChange={(event) => {
                      setCodigo(event.target.value);
                    }}
                  />
                </Box>
                <Box>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"pt-br"}
                  >
                    <DatePicker
                      sx={{ m: 1, width: "100%" }}
                      value={data}
                      onChange={(newValeu) => {
                        setData(newValeu);
                      }}
                      variant="standard"
                      label="data"
                      slotProps={{
                        textField: {
                          variant: 'standard',
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Stack>
              <Stack direction={"row"}>
                <Box>
                  <FormControl variant="standard" sx={{ m: 1, width: "180px" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Categorias
                    </InputLabel>
                    <Select
                      label={"Categorias"}
                      value={categoria}
                      onChange={(event) => {
                        setCategoria(event.target.value);
                        setSubcategoria("");
                      }}
                    >
                      {categorias.map((name, index) => (
                        <MenuItem key={index} value={name.categoria}>
                          {
                            //   <Checkbox checked={categoria.indexOf(name.categoria) > -1} />
                          }
                          {name.categoria}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <Autocomplete
                    sx={{ m: 1, width: "300px" }}
                    onChange={(event, newValue) => {
                      setSubcategoria(newValue);
                    }}
                    value={subcategoria}
                    options={subCategorias
                      .filter((val) => val.categoria.includes(categoria))
                      .map((name, index) => name.subCategoria)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub-categoria"
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              </Stack>
              <Stack direction={"row"}>
                <Box>
                  <Autocomplete
                    sx={{ m: 1, width: "180px" }}
                    onChange={(event, newValue) => {
                      setParceiro(newValue);
                      setCliente(null);
                    }}
                    value={parceiro}
                    options={clientes.map((name, index) => name)}
                    getOptionLabel={(option) => option.parceiro.toUpperCase()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Parceiro"
                        variant="standard"
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Autocomplete
                    disabled={!parceiro}
                    sx={{ m: 1, width: "180px" }}
                    onChange={(event, newValue) => {
                      setCliente(newValue);
                    }}
                    value={cliente}
                    options={
                      parceiro ? parceiro.clientes.map((name) => name) : null
                    }
                    getOptionLabel={(option) =>
                      `${option.numero} ${option.cliente}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                    )}
                  />
                </Box>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color={"secondary"}
                onClick={() => {
                  setOpen(false);
                  clearModal();
                }}
              >
                CANCELAR
              </Button>
              <Button
                variant="contained"
                onClick={sendRequest}
                disabled={confirmarLoading}
              >
                CONFIRMAR
              </Button>
            </DialogActions>
          </Box>
        </Paper>
      </Modal>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </div>
  );
}
