import {
  Box,
  Collapse,
  Drawer,
  Icon,
  IconButton,
  Paper,
  useTheme,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import mwIconDark from "../../assets/logo.png";
import mwIcon from "../../assets/logo3.png";
import MenuIcon from "@mui/icons-material/Menu";
import CustomTreeView from "./customTreeView";
export default function RightBar(props) {
  const {titlePage, setTitlePage} = props
  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const [openRightBar, setOpenRightBar, openDrawer, setOpenDrawer] =
    useOutletContext();

  const handleDrawerToggle = () => {
    setOpenDrawer((prevState) => !prevState);
  };

  const widthRightBar = (open) => (open ? 270 : 56);
  const styles = {
    container: { display: "flex" },
    paper: {
      top: 64,
      overflowY: "auto",
      display: "flex",
      flex: "0 0 auto",
      position: "sticky",
      height: "calc( 100vh - 64px )",

      zIndex: 1,
      display: { xs: "none", lg: "flex" },
    },
    drawer: {
      display: { xs: "inline", lg: "none" },
    },
    icons: {
      color:
        colorMode === "light"
          ? "rgba(0, 0, 0, 0.6)"
          : "rgba(255, 255, 255, 0.7)",
    },
    containerOutlet: { flexGrow: 1, paddingTop: 5, paddingInline: 3 },
    buttonMenuDawer: {
      color: colorMode === "light" ? "black" : "white",
    },
    boxMenuAndLogo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingX: 1,
      width: "270px",
    },
    iconLogo: {
      width: "fit-content",
      height: "fit-content",
      margin: 2,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    imgIcon: {
      width: 170,
      height: "auto",
    },
  };

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={styles.drawer}
      >
        <>
          <Box sx={styles.boxMenuAndLogo}>
            <IconButton
              sx={styles.buttonMenuDawer}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Icon sx={styles.iconLogo}>
              <img
                src={colorMode === "light" ? mwIcon : mwIconDark}
                style={styles.imgIcon}
              />
            </Icon>
          </Box>
          <CustomTreeView
            open={openDrawer}
            setOpen={setOpenDrawer}
            titlePage={titlePage}
            setTitlePage={setTitlePage}
            closeOnClickItem
            {...props}
          />
        </>
      </Drawer>
      <Paper sx={styles.paper}>
        <Collapse
          in={openRightBar}
          orientation="horizontal"
          collapsedSize={widthRightBar(false)}
        >
          <Box sx={{ margin: 0, padding: 0, pt: 1 }}>
            <CustomTreeView
              open={openRightBar}
              setOpen={setOpenRightBar}
              titlePage={titlePage}
              setTitlePage={setTitlePage}
              {...props}
            />
          </Box>
        </Collapse>
      </Paper>
    </>
  );
}
