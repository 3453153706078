import axios from "axios";

export default async function CheckToken() {
  const url = `${window?.config?.REACT_APP_BASE_URL || ""}`;
  let result = localStorage.getItem("1");
  if (result) {
    result = JSON.parse(result);

    try {
      const response = await axios.get(`${url}/authenticationToken`, {
        headers: { Authorization: `Bearer ${result.token}` },
      });

      if (response.data.message === "Token is valid") {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
