import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Stack,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { Add, Delete, Edit } from "@mui/icons-material";
import EditModalBlocos from "./editModalBlocos";
import DeleteDialog from "../../../../componentes/deleteDialog";
import CreateModalBlocos from "./createModalBlocos";
const styles = {
  boxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperContainer: {
    padding: "20px",
    minWidth: "70vw",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  TextField: {
    width: "400px",
  },
  paperModal: {
    padding: "10px",
  },
  dialogContent: { display: "flex", flexDirection: "column", gap: "20px" },
};
export default function Blocos(params) {
  const { acessoClientesId, acessoProdutoId } = params;
  const rows = [{ id: 14 }];
  //modal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  //modal delete
  const [confirmarDelete, setConfirmarDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //tabela
  const [blocos, setBlocos] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hide: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 200,
    },

    {
      field: "blocos",
      headerName: "Blocos",
      //width: 400,
      flex: 3,
    },
    {
      field: "void",
      headerName: "",
      width: 100,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      //width: 40,

      renderCell: (params) => {
        const onClick = (e) => {
          //e.stopPropagation();
          // don't select this row after clicking
          // const api = params.api;
          let thisRow = {};
          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );
          thisRow = params.row

          hanldeUpDateSelectRow(thisRow);
          setOpenEditModal(true);
        };
        function hanldeUpDateSelectRow(thisRow) {
          setSelectedRow(thisRow);
        }
        const onDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          // const api = params.api;
          let thisRow = {};
          // api
          //   .getAllColumns()
          //   .filter((c) => c.field !== "__check__" && !!c)
          //   .forEach(
          //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   );
          thisRow = params.row
          setOpenDeleteModal(true);
          setSelectedRow(thisRow);
        };
        //
        return (
          <Box key={params.id}>
            <Tooltip title={"Editar"} id="basic-button" onClick={onClick}>
              <IconButton size="small">
                <Edit color="primary" />
              </IconButton>
            </Tooltip>

            <IconButton onClick={onDelete}>
              <Delete color={"secondary"} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const comparaNome = (novoNome) => {
    return blocos.some((bloco) => bloco.nome === novoNome);
  };
  const comparaOutrosNomes = (novoNome, nomeAtual) => {
    const filtraBlocos = blocos.filter((bloco) => bloco.nome !== nomeAtual);
    return filtraBlocos.some((bloco) => bloco.nome === novoNome);
  };
  const validarIp = (ip) => {
    const ipv6Regex =
      /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?$/i;
    const ipv6RegexWithMask =
      /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?\/(12[0-8]|1[01][0-9]|[1-9]?[0-9])$/i;
    const ipv6RegexWithPort =
      /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?:\d{1,5}$/i;
    const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/;
    const ipv4RegexWithMask =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[1-2]?[0-9])$/;
    const ipv4RegexWithPort =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(\d{1,5})$/;

    if (
      ipv6Regex.test(ip) ||
      ipv6RegexWithMask.test(ip) ||
      ipv6RegexWithPort.test(ip)
    ) {
      return 2;
    }
    if (
      ipv4Regex.test(ip) ||
      ipv4RegexWithMask.test(ip) ||
      ipv4RegexWithPort.test(ip)
    ) {
      return 1;
    }
    return 0;
  };

  function handleNewBlocos(newBlocos) {
    setBlocos((blocos) => [...blocos, newBlocos]);
  }
  function handleEditBlocos(blocoEditado) {
    setBlocos((blocos) =>
      blocos.map((bloco) => {
        if (bloco.id === blocoEditado.id) {
          return blocoEditado;
        } else {
          return bloco;
        }
      })
    );
  }
  function handleDeleteBloco(id) {
    setBlocos((blocos) => blocos.filter((bloco) => bloco.id !== id));
  }
  useEffect(() => {
    if (acessoProdutoId) {
      handleApiGetBlocos();
    }
  }, [acessoProdutoId]);

  async function handleApiGetBlocos() {
    try {
      setLoadingTable(true);
      const response = await api.post("/relatoriosFlow/blocos/findMany", {
        acessoProdutoId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
      } else {
        if (response.data) {
          setBlocos(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function handleApiDeleteBlocos() {
    try {
      setLoadingTable(true);
      setConfirmarDelete(true);
      const response = await api.put("/relatoriosFlow/blocos/delete", {
        id: selectedRow.id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleDeleteBloco(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setConfirmarDelete(false);
      setOpenDeleteModal(false);
    }
  }
  return (
    <>
      <Box sx={styles.boxContainer}>
        <Paper sx={styles.paperContainer}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {/*    <Typography variant="h6">Blocos Flow</Typography>*/}{" "}
            <Box></Box>
            <Button
              variant="contained"
              disabled={loadingTable || !acessoClientesId}
              startIcon={<Add />}
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              Adicionar
            </Button>
          </Stack>
          <DataGrid
            sx={{
              fontSize: 15,
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              '.MuiDataGrid-cell': { display: "flex", alignItems: 'center' },
            }}
            //getRowHeight={() => "auto"}
            autoHeight={true}
            //localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            // rows={filteredRows(rowsOffComplete(rowsPast(rows)))}
            rows={blocos}
            columns={columns}
            pageSizeOptions={[10, 50, 100]}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            loading={loadingTable}
            disableColumnMenu
            //checkboxSelection
            disableRowSelectionOnClick={true}
          />{" "}
        </Paper>
      </Box>{" "}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <CreateModalBlocos
        setOpenCreateModal={setOpenCreateModal}
        openCreateModal={openCreateModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        styles={styles}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaNome={comparaNome}
        handleNewBlocos={handleNewBlocos}
        validarIp={validarIp}
        acessoProdutoId={acessoProdutoId}
        acessoClientesId={acessoClientesId}
      />
      <EditModalBlocos
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        setSnackbar={setSnackbar}
        setLoadingTable={setLoadingTable}
        acessoProdutoId={acessoProdutoId}
        styles={styles}
        selectedRow={selectedRow}
        setErrorName={setErrorName}
        errorName={errorName}
        comparaOutrosNomes={comparaOutrosNomes}
        handleEditBlocos={handleEditBlocos}
        validarIp={validarIp}
      />
      <DeleteDialog
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        confirmarDelete={confirmarDelete}
        handleApiDelete={handleApiDeleteBlocos}
      />
    </>
  );
}
