import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Paper,
  Link,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Divider,
  Modal,
  Checkbox,
  Snackbar,
  Alert,
  FormHelperText,
  TextField,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import api from "../../../services/api";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import RoomIcon from "@mui/icons-material/Room";

import { useNavigate, useParams } from "react-router-dom";

const style = {
  selectImg: {
    padding: "0px",
    minWidth: "10px",
    height: "10px",
    borderRadius: "5px",
    backgroundColor: "rgba(0,0,0,0.8)",
    margin: "5px",
  },
  paperProduct: {
    justifyContent: "center",
    height: "100%",
    background: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "none",
  },
  textRubikCenter: { fontFamily: "Rubik", textAlign: "left" },
  textIcon: { fontSize: "0.8rem", display: "flex", alignItems: "center" },
  displayFlexRow: { display: "flex", flexDirection: "row" },
  buttonCompra: {
    fontSize: "0.8rem",
    height: "1.6rem",
    width: "9rem",
    margin: "auto",
  },
  optionEntregas: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
const products = {
  id: "",
  nome: "",
  modelo: "",
  desconto: null,
  categoria: null,
  descricao: "",
  especificacoes: [],
  preco: null,
  status: true,
  MidiaProduto: [],
  Estoque: [],
};

function ImageProduct({ product }) {
  const [img, setImg] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  useEffect(() => {
    setImg(product.MidiaProduto[0]?.foto);
  }, [product]);

  return (
    <>
      <Paper sx={style.paperProduct}>
        <Button sx={{ "&:hover": { background: "none" } }}>
          <Box
            onClick={handleOpen}
            component="img"
            src={img}
            height="350px"
            maxWidth="525px"
          />
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {product.MidiaProduto.map((imgProduct, i) => (
            <Box key={"foto" + i}>
              {i < 4 && (
                <Button sx={{ "&:hover": { background: "none" } }}>
                  <Box
                    component="img"
                    src={imgProduct.foto}
                    height="60px"
                    maxWidth="90px"
                    onClick={() => setImg(imgProduct.foto)}
                    onMouseEnter={() => setImg(imgProduct.foto)}
                    padding="5px"
                    sx={{
                      border: "1px solid rgba(0,0,0,0.5)",
                      "&:hover": {
                        border: "1px solid #5B2E87",
                        transform: "scale(1.2)",
                      },
                    }}
                  />
                </Button>
              )}
              {i == 3 && (
                <Button
                  key={product.id + "-" + i}
                  sx={{ "&:hover": { background: "none" } }}
                >
                  <Box
                    key={"more" + i}
                    height="60px"
                    onClick={handleOpen}
                    width="70px"
                    padding="5px"
                    sx={{
                      border: "1px solid rgba(0,0,0,0.5)",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "rgba(0,0,0,0.3)",
                      "&:hover": {
                        border: "1px solid #5B2E87",
                      },
                    }}
                  >
                    +{product.MidiaProduto.length - i}
                  </Box>
                </Button>
              )}
            </Box>
          ))}
        </Box>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            height: "80vh",
            margin: "20px",
            display: "flex",
            flexDirection: "row",
            padding: "20px",
          }}
        >
          <Box
            width="70%"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box component="img" src={img} height="400px" maxWidth="100%" />
          </Box>
          <Box
            width="30%"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ marginLeft: "93%", width: "40px", marginTop: "-10px" }}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={[style.textRubikCenter, { fontSize: "2rem" }]}>
              {product.nome}
            </Typography>
            <Typography
              sx={[
                style.textRubikCenter,
                { fontSize: "1rem", marginTop: "-5px" },
              ]}
            >
              Código:{product.modelo}
            </Typography>

            <Grid container sx={{ marginTop: "20px" }} spacing={1}>
              {product.MidiaProduto.map((imgProduct, i) => (
                <Grid key={"fotos" + i} item xs={6} md={3}>
                  <Button sx={{ "&:hover": { background: "none" } }}>
                    <Box
                      component="img"
                      src={imgProduct.foto}
                      height="100%"
                      width="100%"
                      onClick={() => setImg(imgProduct.foto)}
                      onMouseEnter={() => setImg(imgProduct.foto)}
                      padding="5px"
                      sx={{
                        border: "1px solid rgba(0,0,0,0.5)",

                        "&:hover": {
                          border: "1px solid #5B2E87",
                          transform: "scale(1.2)",
                        },
                      }}
                    />
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default function IotPreview() {
  const { id } = useParams();
  const [product, setProduct] = useState(products);
  const [frete, setFrete] = useState([]);
  const [cep, setCep] = useState("");
  const [busca, setBusca] = useState(false);
  const [cepIsValid, setCepIsValid] = useState(true);
  const [snackbar, setSnackbar] = useState(false);
  // solitar dados do produto
  const navigate = useNavigate();
  const [showButtonMoreDesc, setshowButtonMoreDesc] = useState(false);
  const [showMoreDesc, setshowMoreDesc] = useState(false);

  useEffect(() => {
    async function getProduts() {
      try {
        const response = await api.post("/getIotProduto", {
          id: id,
        });

        setProduct(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    getProduts();
  }, []);

  useEffect(() => {
    const paragraph = document.getElementById("myParagraph");
    if (paragraph.scrollHeight > paragraph.clientHeight) {
      setshowButtonMoreDesc(true);
    } else {
      setshowButtonMoreDesc(false);
    }
  }, [product]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "1050px",
          margin: "auto",
          mb: 1,
        }}
      >
        <Button
          startIcon={<ArrowBackIosIcon />}
          variant="contained"
          onClick={() => navigate(`/iot/administracao`)}
        >
          Voltar
        </Button>
      </Box>
      <Paper
        variant="outlined"
        sx={{
          width: "1050px",
          margin: "auto",
          padding: "25px",
          backgroundColor: "transparent",
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            sx={{ display: { xs: "block", lg: "none" } }}
            xs={12}
            sm={12}
            md={12}
            lg={7}
            fh={7}
          >
            <ImageProduct key="image" product={product} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={5}
            fh={5}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography sx={[style.textRubikCenter, { fontSize: "2rem" }]}>
              {product.nome}
            </Typography>
            <Typography
              sx={[
                style.textRubikCenter,
                { fontSize: "1rem", marginTop: "-5px" },
              ]}
            >
              Código: {product.modelo}
            </Typography>

            <Typography
              sx={[
                style.textRubikCenter,
                { fontSize: "2rem", fontWeight: "500", marginTop: "20px" },
              ]}
            >
              R$ {product.preco?.toFixed(2)}
            </Typography>
            <Link>
              <Typography
                sx={[
                  style.textRubikCenter,
                  { fontSize: "0.8rem", display: "flex", alignItems: "center" },
                ]}
              >
                <HelpOutlineIcon
                  sx={{ fontSize: "1rem", marginRight: "5px" }}
                />{" "}
                Em até 12x de R$ 100{" "}
              </Typography>{" "}
            </Link>
            <Typography
              sx={[
                style.textRubikCenter,
                {
                  fontSize: "0.8rem",
                  marginTop: "5px",
                  display: "flex",
                  alignItems: "center",
                },
              ]}
            >
              R$ {product.desconto?.toFixed(2)} à vista (
              {(
                100 -
                (parseFloat(product.desconto) / parseFloat(product.preco)) * 100
              ).toFixed(2)}
              % de desconto)
            </Typography>
            <Typography sx={[style.textIcon, { marginTop: "20px" }]}>
              <LocalShippingIcon
                sx={{ fontSize: "1.4rem", marginRight: "5px" }}
              />{" "}
              Opções de Entrega:{" "}
            </Typography>

            <FormControl
              sx={{ width: "100%", marginTop: "12px" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-senha">CEP</InputLabel>
              <OutlinedInput
                id="outlined-adornment-senha"
                type={"text"}
                value={cep}
                onChange={(event) => {
                  setCep(event.target.value);
                }}
                label="CEP"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button
              variant="contained"
              sx={{ height: "50px", marginTop: "20px" }}
              startIcon={<ShoppingCartIcon />}
            >
              {" "}
              Compre agora{" "}
            </Button>
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                direction: "row",
                justifyContent: "center",
                alignItems: "start",
                marginTop: "10px",
                padding: "10px",
              }}
            >
              <ContactSupportIcon
                sx={{ fontSize: "2rem", marginRight: "5px" }}
              />
              <Stack>
                <Typography sx={[style.textIcon, { fontSize: "1rem" }]}>
                  {" "}
                  Ficou em dúvida sobre o produto?{" "}
                </Typography>
                <Typography sx={[style.textIcon]}>
                  {" "}
                  Nossa equipe especializada está a disposição para atendimento
                  via whatsApp!{" "}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid
            item
            sx={{ display: { xs: "none", lg: "block" } }}
            xs={12}
            sm={12}
            md={12}
            lg={7}
            fh={7}
          >
            <ImageProduct product={product} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} fh={12}>
            <Paper
              sx={{ padding: "20px", display: "flex", flexDirection: "column" }}
            >
              <Typography color="primary.main" fontSize="1.2rem">
                Descrição:
              </Typography>
              <Divider />
              <Typography
                dangerouslySetInnerHTML={{ __html: product.descricao }}
                id="myParagraph"
                sx={[
                  {
                    fontSize: "0.9rem",
                    textAlign: "justify",
                    margin: "10px 5px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                  },
                  showMoreDesc ? {} : { WebkitLineClamp: 5 },
                ]}
              ></Typography>
              {showButtonMoreDesc && (
                <>
                  <Button
                    sx={style.buttonCompra}
                    onClick={() => setshowMoreDesc(!showMoreDesc)}
                  >
                    {showMoreDesc ? <p>Mostrar menos</p> : <p>Mostrar mais</p>}{" "}
                  </Button>
                </>
              )}
            </Paper>
            <Paper sx={{ padding: "20px", marginTop: "10px" }}>
              <Typography color="primary.main" fontSize="1.2rem">
                Especificações:
              </Typography>
              <Divider />
              <TableContainer sx={{ marginTop: "10px" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    {product.especificacoes.map((row, i) => (
                      <TableRow
                        key={row.caracteristica}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: "none",
                          },
                        }}
                      >
                        <TableCell
                          width="30%"
                          sx={{
                            fontWeight: "600",
                            color: "primary.main",
                            background: "rgba(0,0,0,0.2)",
                            fontFamily: "Rubik",
                            marginBottom: "1px",
                          }}
                          align="left"
                        >
                          {row.caracteristica}
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Rubik", fontWeight: "500" }}
                          align="left"
                        >
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
