import { SimpleTreeView, useTreeViewApiRef } from "@mui/x-tree-view";
import CustomTreeItem from "./customTreeItem";
import { Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";

export default function CustomTreeView(props) {
  const {
    defaultExpandedItems = [],
    defaultSelectedItems = [],
    items = [],
    title = "",
    open = false,
    setOpen = () => {},
    closeOnClickItem = false,
    titlePage = "",
    setTitlePage = () => {},
  } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const theme = useTheme();
  const apiRef = useTreeViewApiRef();

  const styles = {
    simpleTreeView: {
      width: open ? 270 : 56,
      paddingX: 1,
      paddingY: "5px",
    },
    title: {
      flexGrow: 1,
      fontWeight: 600,
      fontSize: "14px",
      marginX: 1,
      marginY: 1,
      color: theme.palette.color.selected,
      opacity: 0.7,
    },
  };

  return (
    <SimpleTreeView
      selectedItems={selectedItems}
      defaultExpandedItems={defaultExpandedItems}
      defaultSelectedItems={defaultSelectedItems}
      expandedItems={expandedItems}
      multiSelect
      sx={styles.simpleTreeView}
      apiRef={apiRef}
    >
      <Stack>
        {open && (
          <Typography variant="h6" component="div" sx={styles.title}>
            {title}
          </Typography>
        )}
        {Array.isArray(items)
          ? items.map((item, index) => (
              <CustomTreeItem
                setSelectedItems={setSelectedItems}
                setExpandedItems={setExpandedItems}
                selectedItems={selectedItems}
                open={open}
                setOpen={setOpen}
                key={item.itemId}
                apiRef={apiRef}
                closeOnClickItem={closeOnClickItem}
                titlePage={titlePage}
                setTitlePage={setTitlePage}
                {...item}
              >
                {item?.nodes && Array.isArray(item?.nodes) && open
                  ? item?.nodes.map((node) => (
                      <CustomTreeItem
                        key={node.itemId}
                        sx={{ pl: -2 }}
                        setSelectedItems={setSelectedItems}
                        setExpandedItems={setExpandedItems}
                        open={open}
                        setOpen={setOpen}
                        closeOnClickItem={closeOnClickItem}
                        paternal={item}
                        titlePage={titlePage}
                        setTitlePage={setTitlePage}
                        {...node}
                      />
                    ))
                  : null}
              </CustomTreeItem>
            ))
          : null}
      </Stack>
    </SimpleTreeView>
  );
}
