import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Avatar,
  Icon,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
  Select,
  InputAdornment,
  Autocomplete,
  Modal,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Tooltip,
} from "@mui/material";
import "dayjs/locale/pt-br";
import api from "../../services/api";
import mwIcon from "../../assets/logo4.png";
import {
  Add,
  Clear,
  Close,
  Edit,
  Person,
  PersonOff,
  Search,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ModalPerfil from "./modalPerfil";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const styles = {
  ContainerBox: {
    padding: "1vw",
    width: "100%",
    minWidth: "1200px",
    alignItems: "center",
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper: {
    alignItems: "center",
    width: "60%",
    padding: "20px",
    minWidth: "1500px",
    justifyContent: "center",
  },
  CabecalhoBox: {
    alignItems: "center",
    width: "60%",
    padding: "20px 0px",
    minWidth: "1500px",
    justifyContent: "center",
  },
  LoadingLogo: {
    // m: 1,
    position: "relative",
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
  },
  CircularProgress: {
    color: "primary",
    position: "absolute",
    top: -9,
    left: -6,
    zIndex: 1,
  },
  TextCampo: {
    mt: 1,
    mb: 2,
    width: "100%",
  },
  BoxStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "20px",
  },
  dataGrid: {
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
    '.MuiDataGrid-cell': { display: "flex", alignItems: 'center', justifyContent: 'center', },
  },
  textDataGrid: {
    fontSize: "15px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};

export default function Permissoes() {
  const [snackbar, setSnackbar] = useState(null);
  const [perfisDefault, setPerfisDefault] = useState([]);
  const [perfisOrder, setPerfisOrder] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [setor, setSetor] = useState("");
  const [habilitarCriar, setHabilitarCriar] = useState(false);
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [selectTableShow, setSelectTableShow] = useState("TODOS");
  const [openModalPerfil, setOpenModalPerfil] = useState(false);
  const [profileOpenModal, setProfileOpenModal] = useState({});
  const [buscarUsuario, setBuscarUsuario] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    apigetPerfis();
  }, []);

  useEffect(() => {
    const handleUsuarioId = () => {
      let result = localStorage.getItem("2");
      result = JSON.parse(result);
      if (result?.codigoPermissao) {
        if (
          result?.codigoPermissao?.indexOf("adm001") !== -1 ||
          result?.codigoPermissao?.indexOf("adm002") !== -1
        ) {
          return;
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    };
    handleUsuarioId();
  }, []);

  useEffect(() => {
    if (login !== "" && senha !== "" && setor !== "") {
      setHabilitarCriar(true);
    } else {
      setHabilitarCriar(false);
    }
  }, [login, senha, setor]);

  useEffect(() => {
    if (buscarUsuario?.trim()?.length >= 1) {
      handleFindUsuario(perfisDefault);
    } else if (selectTableShow === "TODOS") {
      setSelectTableShow("TODOS");
      handleFindPerfilSetor("TODOS", perfisDefault);
    }
  }, [buscarUsuario]);

  async function apigetPerfis(params) {
    try {
      const responsePerfis = await api.get("/administracao/perfis");
      setPerfisDefault(responsePerfis.data);
      if (selectTableShow === "TODOS" && buscarUsuario?.trim()?.length < 1) {
        handleOrderPerfis(responsePerfis.data);
      } else if (buscarUsuario?.trim()?.length >= 1) {
        handleFindUsuario(responsePerfis.data);
      } else {
        handleFindPerfilSetor(selectTableShow, responsePerfis.data);
      }
      setLoadingPage(true);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar ao servidor",
        severity: "error",
      });
    }
  }

  async function handleOrderPerfis(perfis) {
    const statusTrue = perfis.filter(
      (perfil) => perfil.status === true && perfil.ultimoAcesso
    );
    const statusFalse = perfis.filter(
      (perfil) => perfil.status === false && perfil.ultimoAcesso
    );
    const ultimoAcesso = perfis.filter((perfil) => !perfil.ultimoAcesso);

    const perfisOrder = [...statusTrue, ...statusFalse, ...ultimoAcesso];

    setPerfisOrder(perfisOrder);
  }

  async function handleFindUsuario(perfisDefault) {
    // Filtrar perfisDefault para encontrar o perfil com o ID especificado

    const filteredRows = perfisDefault?.filter((row, index) => {
      row.numero = index;

      return (
        row.login?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row.nome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row.email?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row.sobrenome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
        row.codigoPermissao
          ?.toLowerCase()
          ?.includes(buscarUsuario?.toLowerCase())
      );
    });
    // Atualizar o estado com o perfil encontrado
    setSelectTableShow("TODOS");
    setPerfisOrder(filteredRows);
  }

  async function handleFindPerfilSetor(perfilFilterSetor, perfisDefault) {
    if (perfilFilterSetor === "TODOS") {
      setSelectTableShow("TODOS");
      handleOrderPerfis(perfisDefault);
    } else {
      const perfil = perfisDefault.filter(
        (perfil) => perfil.setor === perfilFilterSetor
      );

      const statusTrue = perfil.filter(
        (perfil) => perfil.status === true && perfil.ultimoAcesso
      );
      const statusFalse = perfil.filter(
        (perfil) => perfil.status === false && perfil.ultimoAcesso
      );
      const ultimoAcesso = perfil.filter((perfil) => !perfil.ultimoAcesso);

      const perfisOrder = [...statusTrue, ...statusFalse, ...ultimoAcesso];

      setBuscarUsuario("");
      setPerfisOrder(perfisOrder);
    }
  }

  const handleChangeSetor = (event) => {
    handleFindPerfilSetor(event.target.value, perfisDefault);
    setSelectTableShow(event.target.value);
  };

  function handleOpenModalAddUser() {
    setOpenModalAddUser(true);
  }

  function handleCloseModalAddUser() {
    setOpenModalAddUser(false);
    setLogin("");
    setSenha("");
    setSetor("");
  }

  function handleOpenModalPerfil() {
    setOpenModalPerfil(true);
  }

  function handleCloseModalPerfil() {
    setOpenModalPerfil(false);
    setProfileOpenModal({});
  }

  const setorCellView = (params) => {
    return (
      <Box sx={{ cursor: "pointer" }}>
        <Tooltip title="Clique para copiar">
          <span
            onClick={() => {
              handleCopy(params.row.setor);
            }}
          >
            {params.row.setor}
          </span>
        </Tooltip>
      </Box>
    );
  };

  const userCellView = (params) => {
    return (
      <Box sx={{ cursor: "pointer", width: "100%" }}>
        <Tooltip title="Clique para copiar">
          <span
            onClick={() => {
              handleCopy(params.row.login);
            }}
          >
            <Typography sx={styles.textDataGrid}>{params.row.login}</Typography>
          </span>
        </Tooltip>
      </Box>
    );
  };

  const nomeCellView = (params) => {
    return (
      <>
        {params.row.nome !== null ? (
          <Box sx={{ cursor: "pointer", width: "100%" }}>
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(`${params.row.nome} ${params.row.sobrenome}`);
                }}
              >
                <Typography sx={styles.textDataGrid}>
                  {params.row.nome} {params.row.sobrenome}
                </Typography>
              </span>
            </Tooltip>
          </Box>
        ) : (
          " "
        )}
      </>
    );
  };

  const emailCellView = (params) => {
    return (
      <>
        {params.row.email !== null ? (
          <Box sx={{ cursor: "pointer", width: "100%" }}>
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(params.row.email);
                }}
              >
                <Typography sx={styles.textDataGrid}>
                  {params.row.email}
                </Typography>
              </span>
            </Tooltip>
          </Box>
        ) : (
          " "
        )}
      </>
    );
  };

  const permissoesCellView = (params) => {
    return (
      <>
        {params?.row?.codigoPermissao ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "6px",
              alignItems: "center",
              justifyContent: "flex-start",
              overflow: "hidden",
              margin: "2px 0px",
            }}
          >
            {params?.row?.codigoPermissao
              ?.split(",")
              ?.map((permissao, index) => (
                <Chip
                  key={index}
                  label={permissao}
                  sx={{
                    backgroundColor: "chip.waitingReturn",
                    height: "22px",
                    color: "#ffffff",
                  }}
                />
              ))}
          </Box>
        ) : params?.row?.ultimoAcesso !== undefined ? (
          ""
        ) : (
          " "
        )}
      </>
    );
  };

  const statusCellView = (params) => {
    return (
      <>
        {params?.row?.status ? (
          <Box sx={{ cursor: "pointer" }}>
            <Tooltip title="Perfil ativo">
              <Person sx={{ color: "primary.main" }} />
            </Tooltip>
          </Box>
        ) : params?.row?.nome?.length > 1 ? (
          <Box sx={{ cursor: "pointer" }}>
            <Tooltip title="Perfil desativado">
              <PersonOff sx={{ color: "secondary.main" }} />
            </Tooltip>
          </Box>
        ) : (
          <Box sx={{ cursor: "pointer", position: "relative" }}>
            <Tooltip title="Aguardando primeiro login">
              <Box>
                <Person sx={{ color: "chip.inProgress" }} />
                <AccessTimeFilledIcon
                  sx={{
                    fontSize: "15px",
                    color: "chip.inProgress",
                    zIndex: 1,
                    position: "absolute",
                    bottom: 3,
                    right: -3,
                    borderRadius: "50%",
                    backgroundColor: "background.paper",
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </>
    );
  };

  const lastLoginCellView = (params) => {
    function formatarData(dataISO) {
      // Crie um objeto Date a partir da string de data
      const data = new Date(dataISO);
      // Extraia o dia, mês e ano da data
      const dia = data?.getDate()?.toString()?.padStart(2, "0");
      const mes = (data?.getMonth() + 1)?.toString()?.padStart(2, "0"); // Os meses são indexados de 0 a 11
      const ano = data?.getFullYear();
      // Extraia a hora, minuto e segundo da data
      const hora = data?.getHours()?.toString()?.padStart(2, "0");
      const minuto = data?.getMinutes()?.toString()?.padStart(2, "0");
      const segundo = data?.getSeconds()?.toString()?.padStart(2, "0");
      // Retorne a data formatada no padrão brasileiro seguida pela hora

      return (
        <>
          {params?.row?.ultimoAcesso !== undefined ? (
            <Typography sx={{ textAlign: "center" }}>
              {dia}/{mes}/{ano} {hora}:{minuto}:{segundo}
            </Typography>
          ) : (
            " "
          )}
        </>
      );
    }
    return formatarData(params.row.ultimoAcesso);
  };

  const perfilCellView = (params) => {
    return (
      <Tooltip title="Visualizar perfil">
        <IconButton
          onClick={() => {
            setProfileOpenModal(params?.row);
            handleOpenModalPerfil();
          }}
        >
          <Visibility mode="view" params={params} color="primary" />
        </IconButton>
      </Tooltip>
    );
  };

  const colunas = [
    {
      headerAlign: "center",
      align: "center",
      sortable: false,
      field: "foto",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        const foto = params.row.foto ? params.row.foto : "";
        return (
          <Avatar
            alt={params.row.foto ? params.row.nome : ""}
            src={foto ? foto : "/"}
            sx={{ width: "34px", height: "34px", margin: "5px 0px" }}
          />
        );
      },
    },
    {
      sortable: false,
      editable: false,
      field: "login",
      headerName: "Login",
      width: 180,
      renderCell: userCellView,
    },
    {
      sortable: false,
      editable: false,
      field: "nome",
      headerName: "Nome",
      flex: 1,
      renderCell: nomeCellView,
    },
    {
      sortable: false,
      editable: false,
      field: "email",
      headerName: "E-mail",
      flex: 1,
      renderCell: emailCellView,
    },
    {
      sortable: false,
      editable: false,
      field: "codigoPermissao",
      headerName: "Permissoes",
      flex: 1,
      renderCell: permissoesCellView,
    },
    {
      sortable: false,
      editable: false,
      field: "setor",
      headerName: "Setor",
      width: 150,
      renderCell: setorCellView,
    },
    {
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: statusCellView,
    },
    {
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      field: "ultimoAcesso",
      headerName: "Último acesso",
      width: 140,
      renderCell: lastLoginCellView,
    },
    {
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      field: "Detalhes",
      headerName: "Detalhes",
      width: 90,
      renderCell: perfilCellView,
    },
  ];

  async function handleApiNovoUsuario() {
    try {
      const response = await api.post("/createUser", {
        login,
        senha,
      });
      let responsePerfil = {};
      if (response.data.id) {
        responsePerfil = await api.post("/novoPerfil", {
          nome: null,
          sobrenome: null,
          email: null,
          dataDeNascimento: null,
          foto: null,
          status: false,
          setor,
          codigosPermissao: null,
          usuarioId: response.data.id,
          novaSenha: "",
        });
      }
      if (
        response.data.status === "Error" ||
        responsePerfil.data.status === "Error"
      ) {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        apigetPerfis();
        handleCloseModalAddUser();
        setLogin("");
        setSenha("");
        setSetor("");
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: `Error: ${error?.response?.status === 404
          ? error?.response?.data?.error
          : "Não foi possível se conectar com o servidor"
          }`,
        severity: "error",
      });
    }
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  const combinedOptions = perfisDefault?.flatMap((perfil) => {
    const options = [];
    if (perfil.nome && perfil.sobrenome) {
      options?.push({
        ...perfil,
        label: `${perfil.nome} ${perfil.sobrenome}`,
        type: "nome",
      });
    }
    if (perfil.login) {
      options?.push({ ...perfil, label: perfil.login, type: "login" });
    }
    return options;
  });

  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Box sx={styles.CabecalhoBox}>
          <Typography width="100%" variant="h5">
            Painel de Administração
          </Typography>
        </Box>
        <Paper sx={styles.paper}>
          {!loadingPage && (
            <Box sx={styles.LoadingLogo}>
              <Icon style={{ width: 80, height: 80 }}>
                <img
                  src={mwIcon}
                  style={{ width: 80, height: 80 }}
                  alt="mw icon"
                />
              </Icon>

              <CircularProgress
                size={100}
                sx={styles.CircularProgress}
                thickness={2.5}
              />
            </Box>
          )}

          {loadingPage && (
            <Stack>
              <Stack sx={styles.BoxStack}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    label="Buscar usuário"
                    variant="standard"
                    value={buscarUsuario}
                    onChange={(event) => {
                      setBuscarUsuario(event.target.value);
                    }}
                    sx={{ m: 1, width: "280px" }}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                        endAdornment: buscarUsuario ? (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setBuscarUsuario("")}
                              edge="end"
                            >
                              <Clear />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    sx={{
                      minWidth: 180,
                      marginRight: "25px",
                      display: "flex",
                      mt: "-12px",
                    }}
                    size="small"
                  >
                    <InputLabel
                      id="demo-select-small-label"
                      sx={{ ml: "-14px", top: "5px" }}
                    >
                      Escolher setor
                    </InputLabel>
                    <Select
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          display: "flex!important",
                        },
                      }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectTableShow}
                      label="Escolher setor"
                      onChange={handleChangeSetor}
                      variant="standard"
                    >
                      <MenuItem value={"TODOS"}>TODOS</MenuItem>
                      <MenuItem value={"NOC"}>NOC</MenuItem>
                      <MenuItem value={"NOC-EASYFLOW"}>NOC-EASYFLOW</MenuItem>
                      <MenuItem value={"NOC-EASYMON"}>NOC-EASYMON</MenuItem>
                      <MenuItem value={"DEV"}>DEV</MenuItem>
                      <MenuItem value={"IOT"}>IOT</MenuItem>
                      <MenuItem value={"FINANCEIRO"}>FINANCEIRO</MenuItem>
                      <MenuItem value={"VENDAS"}>VENDAS</MenuItem>
                    </Select>
                  </FormControl>
                  <Button
                    endIcon={<Add sx={{ marginTop: "-3px" }} />}
                    onClick={handleOpenModalAddUser}
                    sx={{ fontSize: "13px", marginRight: "10px" }}
                    variant="contained"
                  >
                    Novo Usuário
                  </Button>
                </Box>
              </Stack>

              <Stack spacing={2} alignItems={"center"}>
                <Box sx={{ width: "100%" }}>
                  <DataGrid
                    editMode="row"
                    pageSizeOptions={[25, 50, 100]}
                    getRowHeight={() => "auto"}
                    rows={perfisOrder}
                    columns={colunas}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    autoHeight={true}
                    sx={styles.dataGrid}
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </Paper>
        <Modal
          open={openModalAddUser}
          onClose={handleCloseModalAddUser}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{
              width: "450px",
              margin: "auto",
              marginTop: "15vh",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "10px",
            }}
          >
            <DialogTitle color="primary.main">Cadastro de Usuários</DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <TextField
                sx={styles.TextCampo}
                label="Login"
                id="login"
                variant="standard"
                color="primary"
                value={login}
                onChange={(event) => {
                  setLogin(event.target.value);
                }}
              />
              <TextField
                sx={styles.TextCampo}
                name="senha"
                label="Senha"
                variant="standard"
                color="primary"
                type={showPass ? "text" : "password"}
                value={senha}
                onChange={(event) => {
                  setSenha(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPass((event) => !event)}
                      >
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl variant="standard" sx={styles.TextCampo}>
                <InputLabel id="demo-simple-select-label">Setor</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={setor}
                  onChange={(e) => {
                    setSetor(e.target.value);
                  }}
                  name="setor"
                  color="primary"
                >
                  <MenuItem value={"NOC"}>NOC</MenuItem>
                  <MenuItem value={"NOC-EASYFLOW"}>NOC-EASYFLOW</MenuItem>
                  <MenuItem value={"NOC-EASYMON"}>NOC-EASYMON</MenuItem>
                  <MenuItem value={"DEV"}>DEV</MenuItem>
                  <MenuItem value={"IOT"}>IOT</MenuItem>
                  <MenuItem value={"FINANCEIRO"}>FINANCEIRO</MenuItem>
                  <MenuItem value={"VENDAS"}>VENDAS</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseModalAddUser}
                sx={{ color: "secondary.main" }}
                variant="text"
              >
                {" "}
                Cancelar
              </Button>
              <Button
                disabled={!habilitarCriar}
                variant="text"
                onClick={handleApiNovoUsuario}
              >
                {" "}
                Cadastrar
              </Button>
            </DialogActions>
          </Paper>
        </Modal>
        <ModalPerfil
          openModalPerfil={openModalPerfil}
          handleCloseModalPerfil={handleCloseModalPerfil}
          profileOpenModal={profileOpenModal}
          apigetPerfis={apigetPerfis}
        />
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
