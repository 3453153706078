import { ContentCopy } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./styles";

function GerarScript() {
  const [inputValue, setInputValue] = useState("");
  const [outputText, setOutputText] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const [outputStatus, setOutputStatus] = useState("success");

  const transformar = () => {
    const input = inputValue.trim();

    // Divide o input em linhas e remove as que não contêm '[' ou ']'
    const linhas = input
      .split("\n")
      .filter((linha) => linha.includes("[") && linha.includes("]"));
    if (linhas.length === 0) {
      setOutputText("Nenhuma linha válida encontrada.");
      setOutputStatus("error");
      return;
    }

    // Processa a primeira linha válida
    const linha = linhas[0].trim();

    // Remove "launch_" do nome antes do colchete
    const nomeAntesColchete = linha
      .split("[")[0]
      .trim()
      .replace(/^launch_/, "");
    if (!nomeAntesColchete) {
      setOutputText("Não foi possível extrair o nome antes dos colchetes.");
      return;
    }

    // Extrai o conteúdo entre os colchetes
    const match = linha.match(/\[(.*)\]/);
    if (!match || !match[1]) {
      setOutputText("Não foi possível extrair os parâmetros.");
      return;
    }

    // Remove "launch_" do conteúdo dentro dos colchetes
    const conteudo = match[1].replace(/launch_/g, "").trim();
    if (!conteudo) {
      setOutputText("Nenhum parâmetro válido encontrado.");
      return;
    }

    // Divide a string em parâmetros separados por vírgulas
    const parametros = conteudo.split(",").map((param) => param.trim());

    // Formata o comando inicial com ./
    const comandoInicial = `./${nomeAntesColchete}`;

    // Formata os parâmetros para o comando final
    const comando = `${comandoInicial} ${parametros
      .map((param) => `'${param}'`)
      .join(" ")}`;

    // Exibe o comando gerado
    setOutputText(comando);
    setOutputStatus("success");
  };

  const copiarParaAreaDeTransferencia = () => {
    const texto = outputText;

    if (
      texto === "Clique aqui para copiar o comando gerado" ||
      texto.startsWith("Erro")
    ) {
      return;
    }

    navigator.clipboard.writeText(texto).then(() => {
      // Atualiza a mensagem para informar que o texto foi copiado
      setOutputText("Comando copiado para a área de transferência!");
      setTimeout(() => {
        setOutputText(texto); // Reseta após 2 segundos
      }, 2000);
    });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  return (
    <Box sx={styles.constainer}>
      <Paper sx={styles.paper}>
        <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>
          Formatação de Debug
        </Typography>
        <Typography>
          Insira a chave do item de monitoramento externo abaixo:
        </Typography>
        <TextField
          id="input"
          variant="outlined"
          placeholder="Cole ou digite a chave aqui..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          multiline
          rows={4}
        />
        <Button onClick={transformar} variant="contained">
          Transformar
        </Button>
        <Box
          sx={{
            ...styles.content,
            cursor: outputStatus === "success" ? "pointer" : "default",
          }}
        >
          {outputText && outputStatus === "success" ? (
            <Tooltip
              title="Clique para copiar"
              onClick={() => {
                handleCopy(outputText);
              }}
            >
              <Alert
                severity={outputStatus}
                variant="outlined"
                sx={styles.alert}
              >
                <AlertTitle>Sucesso</AlertTitle>
                <IconButton sx={styles.copyIcon}>
                  <ContentCopy fontSize="small" color="primary" />
                </IconButton>

                {outputText}
              </Alert>
            </Tooltip>
          ) : (
            outputText &&
            outputStatus === "error" && (
              <Alert
                severity={outputStatus}
                variant="outlined"
                sx={styles.alert}
              >
                <AlertTitle>Erro</AlertTitle>

                {outputText}
              </Alert>
            )
          )}
          <Typography
            id="output"
            onClick={copiarParaAreaDeTransferencia}
          ></Typography>
        </Box>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}

export default GerarScript;
