const styles = {
  constainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "16px",
    gap: "16px",
    borderRadius: "8px",
    width: "100%",
  },
  content: {
    display: "flex",
    width: "100%",
  },
  alert: { display: "flex", width: "100%", position: "relative" },
  copyIcon: { position: "absolute", right: 1, top: 1 },
};
export default styles;
