import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
const modules = {
  toolbar: [
    //[{ 'font': [] }],
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }], // dropdown with defaults from theme
    ["clean"],
  ],
};

const formats = [
  //'font',
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "color",
  "background",
];
let StyledEditor = styled.div`
  .editor {
    margin-right: 0;
  }

  .editor .ql-container {
    border-color:"blue"
    min-height: 5em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .editor .ql-toolbar {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    border-bottom: none;
    background: #f9fafb;
  }
  .ql-editor.ql-blank::before {
    color: #f9fafb;
  }
`;
function Editor({ value, onChange }) {
  const handleChange = (content) => {
    onChange(content);
  };
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  useEffect(() => {
    const colorToolbar = colorMode === "light" ? "#f9fafb" : "#f9fafb";
    const colorPlaceholder = colorMode === "light" ? "#f9fafb" : "#868686";

    const styleElement = document.createElement("style");
    styleElement.type = "text/css";
    if (colorMode) {
      styleElement.textContent = `
        .editor {
          margin-right: 0px;
          width: 550px;
        }

        .editor .ql-container {
          min-height: 5em;
          border-bottom-left-radius: 0.5em;
          border-bottom-right-radius: 0.5em;
        }

        .editor .ql-toolbar {
          border-top-left-radius: 0.5em;
          border-top-right-radius: 0.5em;
          border-bottom: none;
          background: ${colorToolbar};
        }
        .ql-editor.ql-blank::before {
          color: ${colorPlaceholder};
        }
      `;
    }
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  return (
    <ReactQuill
      className="editor"
      placeholder="Escreva a descrição aqui..."
      modules={modules}
      formats={formats}
      value={value}
      onChange={handleChange}
      bounds={".editor"}
    />
  );
}

export default Editor;
