import { useEffect, useMemo, useState, Suspense } from "react";
import ImageList from "@mui/material/ImageList";
import Resizer from "react-image-file-resizer";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { PhotoCamera } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import api from "../../../services/api";
import mwIcon from "../../../assets/logo4.png";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  CircularProgress,
  Modal,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  Typography,
  Dialog,
  TextField,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import Carousel from "react-material-ui-carousel";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import TablePagination from "@mui/material/TablePagination";

const styles = {
  customScrollbar: {
    overflow: "auto",
    scrollbarWidth: "thin" /* For Firefox */,
    scrollbarColor: "#888888 #f0f0f0" /* For Firefox */,

    "&::-webkit-scrollbar": {
      width: "8px",
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,0.5)",
      borderRadius: "4px",
    },
  },
  buttoTabelType: {
    borderRadius: "0px",
    fontSize: "15px",
    opacity: "0.3",
  },
  buttonPermissoa: {
    minWidth: "0px",
    background: "rgba(0,0,0,0.2)",
    height: "30px",
    marginTop: "5px",

    hover: {
      background: "red",
    },
  },
  LoadingLogo: {
    // m: 1,
    position: "relative",
    marginLeft: "45%",
    marginTop: "20%",
  },
  CircularProgress: {
    color: "primary",
    position: "absolute",
    top: -9,
    left: -6,
    zIndex: 1,
  },
};
export default function MidiaGrafana() {
  const [snackbar, setSnackbar] = useState(null);
  const [imagens, setImagens] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [opeNew, setOpenNew] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [foto, setFoto] = useState([]);
  const [fotoC, setFotoC] = useState([]);
  const [fotoSelecionada, setfotoSelecionada] = useState({ url: "", id: 0 });
  const [imagensSelected, setImagensSelected] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [showDialogDelete, setShowDialogDelete] = useState({
    open: false,
    name: "",
  });
  const [numImg, setNumImg] = useState(0);
  const md = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const OpenDialogDelete = () => {
    function handleAccept() {
      handleDeleteImagen(showDialogDelete.name);
      setShowDialogDelete({ open: false, name: "" });
    }
    function handleNoAccept() {
      setShowDialogDelete({ open: false, name: "" });
    }

    return (
      <Dialog
        maxWidth="xs"
        open={showDialogDelete.open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <DialogTitle variant="h6" color="secondary">
          <DeleteForeverIcon sx={{ fontSize: "40px", mb: "-10px" }} />
          <br />
          Deletar Imagem
        </DialogTitle>
        <DialogContent>
          {
            " Ao clicar em sim você apagará essa imagem definitivamente do banco, deseja continuar?"
          }
        </DialogContent>

        <DialogActions>
          <Button color="secondary" variant="text" onClick={handleNoAccept}>
            Não
          </Button>
          <Button background="primary" variant="text" onClick={handleAccept}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  async function handleSendImagens() {
    if (foto.length === 0) {
      setSnackbar({
        children: "Aviso: Nenhuma imagem selecionada!",
        severity: "info",
      });
      return;
    }
    try {
      setLoadingModal(false);
      await api.post("/midiaGrafana/upload", {
        files: foto,
      });
      setLoadingModal(true);
      setNumImg(Date.now());
      setSearchText("");
      setCurrentPage(1);
      let uploadimg = [];
      foto.map((f) => {
        if (!imagens.some((imagem) => imagem.title + imagem.ext === f.name)) {
          uploadimg.push({
            title: f.name.slice(0, f.name.lastIndexOf(".")),
            ext: f.name.slice(f.name.lastIndexOf(".")),
            link: `https://logos.mw-solucoes.com/${f.name}`,
          });
        }
      });
      setImagens((imagens) => [...imagens, ...uploadimg]);

      setOpenNew(false);
      setSnackbar({
        children: "Aviso: Imagens enviadas com sucesso",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível upar as imagens, tente novamente!",
        severity: "error",
      });
    }
  }

  async function handleDeleteImagen(name) {
    try {
      setLoadingPage(false);
      await api.post("/midiaGrafana/delete", {
        file: name,
      });

      let newlistfoto = imagens.filter((imagem) => {
        return imagem.title != name.slice(0, name.lastIndexOf("."));
      });
      setImagens(newlistfoto);
      setLoadingPage(true);

      setSnackbar({
        children: "Aviso: Imagem deletada com sucesso",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível deletar imagem",
        severity: "error",
      });
    }
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    "&:hover, &.Mui-focusVisible": {
      opacity: 0.5,
      "& .MuiImageBackdrop-root": {
        opacity: 0.7,
      },
      "& .MuiImageMarked-root": {
        opacity: 0,
      },
      "& .MuiTypography-root": {
        border: "4px solid currentColor",
      },
    },
  }));
  let filter = imagens.filter((img) => {
    return img.title.toLowerCase().startsWith(searchText.toLowerCase());
  });

  useEffect(() => {
    function paginationImage() {
      let img = filter,
        select = [];

      for (
        let index = currentPage * rowsPerPage - rowsPerPage;
        index < currentPage * rowsPerPage;
        index++
      ) {
        if (img[index] !== undefined) select.push(img[index]);
      }
      setImagensSelected(select);
    }
    paginationImage();
  }, [rowsPerPage, currentPage, searchText, imagens]);

  useEffect(() => {
    // Função para agrupar as fotos em grupos de cinco
    const agruparFotos = () => {
      const novoGruposDeFotos = [];
      for (let i = 0; i < foto.length; i += 5) {
        novoGruposDeFotos.push(foto.slice(i, i + 5));
      }
      return novoGruposDeFotos;
    };

    // Atualiza o estado dos grupos de fotos
    setFotoC(agruparFotos());
    setfotoSelecionada({ url: foto[0]?.foto, id: 0 });
  }, [foto]);

  useEffect(() => {
    if (!imagens.length > 0) setNumImg(Date.now());
    getImageGrafana();
    async function getImageGrafana() {
      try {
        const response = await api.get("/midiaGrafana/findMany");

        if (response.data !== null) {
          setImagens(response.data);
          setRowsPerPage(50);
          setLoadingPage(true);
        }
      } catch (error) {
        setLoadingPage(true);
        setSnackbar({
          children: "Erro: Não foi possível consultar as imagens",
          severity: "error",
        });
      }
    }
  }, []);

  const handleNewOpen = () => {
    setFoto([]);
    setOpenNew(true);
  };
  const removerFoto = (indexToRemove) => {
    const novaListaDeFotos = [...foto];
    novaListaDeFotos.splice(indexToRemove, 1);
    setFoto(novaListaDeFotos);
  };
  async function processarFotos(files) {
    let novasFotos = [];

    if (foto.length >= 5) return;

    for (let i = 0; i < files.length; i++) {
      if (foto.length + (i + 1) <= 5) {
        const uri = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            files[i],
            600,
            600,
            "PNG,JPEG",
            80,
            0,
            (resizedUri) => {
              resolve(resizedUri);
            },
            "base64"
          );
        });

        novasFotos.push({ foto: uri, name: files[i].name });
      }
    }

    setFoto((fotosAntigas) => [...fotosAntigas, ...novasFotos]);
  }

  function copiarTexto(texto) {
    try {
      navigator.clipboard.writeText(texto);
      setSnackbar({
        children: "Aviso: Link copiado!",
        severity: "info",
      });
    } catch (err) {
      setSnackbar({
        children: "Erro: O link não foi copiado",
        severity: "error",
      });
    }
  }

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          label="Buscar"
          variant="standard"
          disabled={!loadingPage}
          id="outlined-adornment-amount"
          value={searchText}
          onChange={(e) => {
            setCurrentPage(1);
            setSearchText(e.target.value);
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          }}
        />

        <Button
          disabled={!loadingPage}
          endIcon={<AddIcon sx={{ marginTop: "-3px" }} />}
          onClick={handleNewOpen}
          sx={{ fontSize: "13px", marginRight: "10px" }}
          variant="contained"
        >
          Nova Imagem
        </Button>
      </Paper>

      {!loadingPage && (
        <Box sx={styles.LoadingLogo}>
          <Icon style={{ width: 80, height: 80 }}>
            <img src={mwIcon} style={{ width: 80, height: 80 }} alt="mw icon" />
          </Icon>

          <CircularProgress
            size={100}
            sx={styles.CircularProgress}
            thickness={2.5}
          />
        </Box>
      )}
      {loadingPage && (
        <>
          <ImageList sx={{ width: "100%" }} cols={md ? 4 : 6} gap={15}>
            <ImageListItem key="Subheader" cols={md ? 4 : 6}>
              <ListSubheader
                sx={{
                  boxShadow:
                    "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                }}
                component="div"
              >
                {searchText === ""
                  ? imagens.length + " resultados "
                  : imagensSelected.length + " resultados  para " + searchText}
              </ListSubheader>
            </ImageListItem>
            {imagensSelected.map((item, i) => {
              return (
                <ImageListItem
                  sx={{ background: "rgba(0,0,0,0.15)" }}
                  key={item.title + i}
                >
                  <ImageButton
                    disableRipple={true}
                    onClick={() => copiarTexto(item.link)}
                  >
                    <img
                      alt={item.name}
                      src={`${item.link}#${numImg}?w=100%&h=164&fit=crop`}
                      loading="lazy"
                      style={{ width: "100%", height: "164px" }}
                    />
                  </ImageButton>
                  <ImageListItemBar
                    title={item.title}
                    actionIcon={
                      <Tooltip followCursor title="Delete">
                        <IconButton
                          onClick={() =>
                            setShowDialogDelete({
                              open: true,
                              name: item.title + item.ext,
                            })
                          }
                          sx={{ mr: "5px", color: "white" }}
                          aria-label={`info about ${item.title}`}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ marginTop: "20px", mb: "50px" }}
          >
            <TablePagination
              component="div"
              count={filter.length}
              page={currentPage - 1}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[50, 100, 200]}
              labelRowsPerPage="Linhas por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
            />
          </Stack>
        </>
      )}
      <Fab
        size="medium"
        color="primary"
        sx={{
          height: "55px",
          width: "55px",
          borderRadius: "50%",
          position: "fixed",
          right: "56px",
          bottom: "105px",
          backgroundColor: "#5B2E87",
        }}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <KeyboardArrowUpIcon sx={{ fontSize: "35px" }} />
      </Fab>

      <Modal
        open={opeNew}
        onClose={() => setOpenNew(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowX: "auto" }}
      >
        <Paper
          sx={{
            width: "700px",
            margin: "auto",
            mt: "10vh",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <DialogTitle color="primary.main">Cadastro de Imagens</DialogTitle>

          {!loadingModal && (
            <Box sx={{ position: "relative", margin: "50px auto" }}>
              <Icon style={{ width: 80, height: 80 }}>
                <img
                  src={mwIcon}
                  style={{ width: 80, height: 80 }}
                  alt="mw icon"
                />
              </Icon>

              <CircularProgress
                size={100}
                sx={styles.CircularProgress}
                thickness={2.5}
              />
            </Box>
          )}
          {loadingModal && (
            <DialogContent>
              {foto[0] && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      height: "350px",
                      background: "rgba(0,0,0,0.1)",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{ maxHeight: "350px", width: "100%" }}
                      key={"fotoselectq"}
                      component="img"
                      src={fotoSelecionada.url}
                      alt="Imagem"
                    />
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      background: "rgba(0,0,0,0.2)",
                      marginTop: "10px",
                      marginBottom: "10px",
                      padding: "0px 40px",
                    }}
                    width="100%"
                    height="76px"
                  >
                    <Stack direction="row" gap={1}>
                      {foto.map((f, index) => (
                        <Box>
                          <Button
                            onClick={() =>
                              setfotoSelecionada({
                                url: f.foto,
                                id: index,
                              })
                            }
                            sx={{ padding: "7px" }}
                          >
                            {imagens.some(
                              (imagem) => imagem.title + imagem.ext === f.name
                            ) && (
                                <Tooltip title="Nome já existe no banco!">
                                  <Box
                                    sx={{
                                      width: "90px",
                                      height: "60px",
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={f.foto}
                                      alt="Imagem"
                                      sx={{
                                        border: "1px solid #BE5602",
                                        objectFit: "cover",
                                        width: "90px",
                                        height: "60px",
                                      }}
                                    />
                                    <ReportProblemIcon
                                      sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        color: "#BE5602",
                                      }}
                                    />
                                  </Box>
                                </Tooltip>
                              )}

                            {!imagens.some(
                              (imagem) => imagem.title + imagem.ext === f.name
                            ) && (
                                <Box
                                  component="img"
                                  src={f.foto}
                                  alt="Imagem"
                                  sx={{
                                    objectFit: "cover",
                                    width: "90px",
                                    height: "60px",
                                  }}
                                />
                              )}
                          </Button>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button variant="contained" component="label">
                      <PhotoCamera sx={{ mr: "5px" }} /> Adicionar mais
                      <input
                        hidden
                        multiple
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        onChange={(e) => {
                          const files = e.target.files;
                          processarFotos(files);
                        }}
                      />{" "}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => removerFoto(fotoSelecionada.id)}
                    >
                      <DeleteIcon />
                      Remover Atual
                    </Button>
                    <Button variant="contained" onClick={() => setFoto([])}>
                      <DeleteSweepIcon />
                      Remover todas
                    </Button>
                  </Box>
                </>
              )}
              {!foto[0] && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <BurstModeIcon
                      sx={{ fontSize: "200px", color: "rgba(0,0,0,0.3)" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "25px",
                        marginTop: "-40px",
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Preview
                    </Typography>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ mt: "20px" }}
                    >
                      <PhotoCamera /> Adicionar
                      <input
                        hidden
                        multiple
                        accept=".png, .jpg, .jpeg"
                        type="file"
                        onChange={(e) => {
                          const files = e.target.files;
                          processarFotos(files);
                        }}
                      />{" "}
                    </Button>
                  </Box>
                </>
              )}
            </DialogContent>
          )}

          <DialogActions>
            <Button
              onClick={() => setOpenNew(false)}
              sx={{ color: "secondary.main" }}
              variant="text"
            >
              {" "}
              Cancelar
            </Button>
            <Button variant="text" onClick={handleSendImagens}>
              {" "}
              Cadastrar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
      <OpenDialogDelete />

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
