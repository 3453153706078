import {
  AddAPhoto,
  AttachMoneyOutlined,
  Close,
  Code,
  MemoryOutlined,
  Person,
  PersonOff,
  SellOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Resizer from "react-image-file-resizer";
import AlertModal from "../../../componentes/alertModal";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const styles = {
  modalPerfil: {
    backgroundColor: "background.paperOutlined",
    width: "530px",
    minHeight: "522px",
    maxHeight: "80vh",
    margin: "auto",
    marginTop: "10vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "10px",
    overflowY: "auto",
    gap: "15px",
    border: "1px solid rgba(255, 255, 255, 0.12)",
    borderRadius: "12px",
  },
  boxAreaModalPerfil: {
    /* background: "red", */
    width: "100%",
    minHeight: "502px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  boxAreaTituloModalPerfil: {
    /* background: "blue", */
    position: "relative",
    width: "100%",
    height: "114px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  boxAreaConteudoModalPerfil: {
    /* background: "green", */
    backgroundColor: "background.default",
    width: "100%",
    minHeight: "388px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    padding: "30px",
    paddingTop: "10px",
  },
  boxAreaInputsModalPerfil: {
    /* background: "purple", */
    width: "470px",
    minHeight: "292px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  boxAreaButtomModalPerfil: {
    /* background: "pink", */
    width: "100%",
    height: "36px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  TextCampo: {
    fontSize: "16px",
    fontWeight: "600",
  },
  TextField: {
    fontSize: "16px",
    fontWeight: "600",
    margin: "10px",
  },
  TypographyCampo: {
    fontSize: "14px",
    fontWeight: "400",
  },
  TypographyCampoInput: {
    fontSize: "14px",
    fontWeight: "400",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  TypographyCampoSetor: {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize",
  },
  StackFoto: {
    alignItems: "center",
    height: "220px",
    position: "absolute",
    marginTop: "50px",
    marginRight: "275px",
  },
  BoxAvatar: {
    width: "126px",
    height: "126px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    bgcolor: "background.paperOutlined",
  },
  Avatar: {
    width: "110px",
    height: "110px",
  },
  boxStatusItem: {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    gap: "6px",
    mt: "-1px",
  },
  boxAreaChipsModalPerfil: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "32px",
    maxHeight: "312px",
    overflowY: "auto",
    gap: "4px",
    alignItems: "center",
    mb: "2.2px",
  },
};

export default function ModalPerfil(params) {
  const {
    openModalPerfil,
    handleCloseModalPerfil,
    profileOpenModal,
    apigetPerfis,
  } = params;
  const [snackbar, setSnackbar] = useState(null);
  const [nomePerfil, setNomePerfil] = useState("");
  const [sobrenomePerfil, setSobrenomePerfil] = useState("");
  const [loginPerfil, setLoginPerfil] = useState("");
  const [emailPerfil, setEmailPerfil] = useState("");
  const [codigoPermissao, setCodigoPermissao] = useState([]);
  const [statusPerfil, setStatusPerfil] = useState(true);
  const [setorPerfil, setSetorPerfil] = useState("");
  const [fotoPerfil, setFotoPerfil] = useState("");
  const [editModalPerfil, setEditModalPerfil] = useState(false);
  const [checked, setChecked] = useState({});
  const [disableSaveButtom, setDisableSaveButtom] = useState(true);
  const [openModalAlertSave, setOpenModalAlertSave] = useState(false);
  const [selectedPermissoes, setSelectedPermissoes] = useState([]);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailInvalido, setEmailInvalido] = useState(false);
  const [loginInvalido, setLoginInvalido] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const permissoes = [
    "adm001",
    "adm002",
    "noc001",
    "noc002",
    "dev001",
    "dev002",
    "wiki001",
  ];

  useEffect(() => {
    if (openModalPerfil) {
      handleSetInfoPerfil();
      handleSetInfoPerfilSelected();
    }
  }, [openModalPerfil, profileOpenModal]);

  useEffect(() => {
    if (
      !emailRegex.test(emailPerfil) &&
      emailPerfil !== "" &&
      emailPerfil !== null &&
      editModalPerfil
    ) {
      setEmailInvalido(true);
    } else {
      setEmailInvalido(false);
    }
  }, [emailPerfil]);

  useEffect(() => {
    if (editModalPerfil && (loginPerfil === "" || loginPerfil === null)) {
      setLoginInvalido(true);
    } else {
      setLoginInvalido(false);
    }
  }, [loginPerfil]);

  useEffect(() => {
    let newPermissoes = Object.keys(checked).filter(
      (key) => checked[key]?.valor === true
    );

    if (
      openModalPerfil &&
      profileOpenModal &&
      (nomePerfil === profileOpenModal?.nome ||
        (profileOpenModal?.nome === null && nomePerfil === "")) &&
      (sobrenomePerfil === profileOpenModal?.sobrenome ||
        (profileOpenModal?.sobrenome === null && sobrenomePerfil === "")) &&
      (loginPerfil === profileOpenModal?.login ||
        (profileOpenModal?.login === null && loginPerfil === "")) &&
      (emailPerfil === profileOpenModal?.email ||
        (profileOpenModal?.email === null && emailPerfil === "")) &&
      newPermissoes?.length ===
        (codigoPermissao[0] === "" ? 0 : codigoPermissao?.length) &&
      newPermissoes?.every((permissao) =>
        codigoPermissao?.includes(permissao)
      ) &&
      statusPerfil === profileOpenModal?.status &&
      setorPerfil === profileOpenModal?.setor &&
      fotoPerfil === profileOpenModal?.foto
    ) {
      setDisableSaveButtom(true);
    } else {
      setDisableSaveButtom(false);
    }
  }, [
    openModalPerfil,
    nomePerfil,
    sobrenomePerfil,
    loginPerfil,
    emailPerfil,
    statusPerfil,
    setorPerfil,
    fotoPerfil,
    checked,
    profileOpenModal,
    codigoPermissao,
  ]);

  async function handleApiUpdatePerfil() {
    try {
      const response = await api.put("/administracao/updatePerfil", {
        perfilId: profileOpenModal?.id,
        usuarioId: profileOpenModal?.usuarioId,
        nome: nomePerfil,
        sobrenome: sobrenomePerfil,
        login: loginPerfil,
        email: emailPerfil,
        codigoPermissao: Object.keys(checked)
          .filter((key) => checked[key].valor === true)
          .join(","),
        status: statusPerfil,
        setor: setorPerfil,
        foto: fotoPerfil,
      });
      if (response.status === 200) {
        apigetPerfis();
        setEditModalPerfil(false);
        setDisableSaveButtom(true);
        setSnackbar({
          children: "Perfil atualizado com sucesso",
          severity: "success",
        });
        handleCloseModalPerfil();
        setSelectedPermissoes([]);
        setErrorEmail(false);
        setErrorLogin(false);
        setChecked((prevState) => {
          const newState = {};
          for (const key in prevState) {
            newState[key] = {
              valor: false,
              titulo: prevState[key]?.titulo,
            };
          }
          return newState;
        });
      }
    } catch (error) {
      let dataError = error?.response?.data;

      if (dataError) {
        if (dataError === "Email já existente") {
          setErrorEmail(true);
          setSnackbar({
            children: "E-mail existente",
            severity: "error",
          });
        } else if (dataError === "Login já existente") {
          setErrorLogin(true);
          setSnackbar({
            children: "Login existente",
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao atualizar informações do perfil",
            severity: "error",
          });
        }
      } else {
        setSnackbar({
          children: "Erro ao atualizar informações do perfil",
          severity: "error",
        });
      }
    }
  }

  async function handleSetInfoPerfil() {
    if (openModalPerfil && profileOpenModal?.codigoPermissao) {
      profileOpenModal?.codigoPermissao?.split(",")?.forEach((permissao) => {
        setChecked((prevState) => ({
          ...prevState,
          [permissao]: {
            valor: true,
            titulo: permissao,
          },
        }));
      });
    }
    setNomePerfil(profileOpenModal?.nome);
    setSobrenomePerfil(profileOpenModal?.sobrenome);
    setLoginPerfil(profileOpenModal?.login);
    setEmailPerfil(profileOpenModal?.email);
    setCodigoPermissao(profileOpenModal?.codigoPermissao?.split(",") || []);
    setStatusPerfil(profileOpenModal?.status);
    setSetorPerfil(profileOpenModal?.setor);
    setFotoPerfil(profileOpenModal?.foto);
  }

  async function handleSetInfoPerfilSelected() {
    if (openModalPerfil && profileOpenModal?.codigoPermissao) {
      const permissoes = profileOpenModal.codigoPermissao.split(",");
      setSelectedPermissoes(permissoes);
      const initialChecked = permissoes.reduce((acc, permissao) => {
        acc[permissao] = { valor: true, titulo: permissao };
        return acc;
      }, {});
      setChecked(initialChecked);
    }
  }

  const handleDeleteChip = (chipToDelete) => {
    setChecked((prevChecked) => ({
      ...prevChecked,
      [chipToDelete]: {
        valor: false,
        titulo: chipToDelete,
      },
    }));
    setSelectedPermissoes((prevSelected) =>
      prevSelected.filter((item) => item !== chipToDelete)
    );
  };

  const handleChangeChecked = (
    event,
    permissao,
    checked,
    setChecked,
    selectedPermissoes,
    setSelectedPermissoes
  ) => {
    const isChecked =
      event.target.checked || !selectedPermissoes.includes(permissao);
    if (isChecked) {
      setChecked((prevChecked) => ({
        ...prevChecked,
        [permissao]: {
          valor: true,
          titulo: permissao,
        },
      }));
      setSelectedPermissoes((prevSelected) => [...prevSelected, permissao]);
    } else {
      setChecked((prevChecked) => ({
        ...prevChecked,
        [permissao]: {
          valor: false,
          titulo: permissao,
        },
      }));
      setSelectedPermissoes((prevSelected) =>
        prevSelected.filter((item) => item !== permissao)
      );
    }
  };

  const handleChangeStatusPerfil = (event) => {
    let value = event.target.value;
    if (value === "ATIVO") {
      setStatusPerfil(true);
    } else {
      setStatusPerfil(false);
    }
  };

  const handleChangeSetorPerfil = (event) => {
    setSetorPerfil(event.target.value);
  };

  function handleSairSemSalvar() {
    setOpenModalAlertSave(false);
    handleCloseModalPerfil();
    setEditModalPerfil(false);
    setErrorEmail(false);
    setErrorLogin(false);
    setSelectedPermissoes([]);
    setChecked((prevState) => {
      const newState = {};
      for (const key in prevState) {
        newState[key] = {
          valor: false,
          titulo: prevState[key]?.titulo,
        };
      }
      return newState;
    });
  }

  async function handleCancelEditPerfil() {
    if (openModalPerfil) {
      setChecked((prevState) => {
        const newState = {};
        for (const key in prevState) {
          newState[key] = {
            valor: false,
            titulo: prevState[key]?.titulo,
          };
        }
        return newState;
      });
      setErrorEmail(false);
      setErrorLogin(false);
      setSelectedPermissoes([]);
      handleSetInfoPerfil();
      handleSetInfoPerfilSelected();
      setDisableSaveButtom(true);
      setEditModalPerfil(false);
    }
  }

  const sortedPermissoes = Object?.entries(checked)
    ?.filter(([titulo, valor]) => valor.valor)
    ?.sort((a, b) => {
      if (a[0].startsWith("adm") && !b[0].startsWith("adm")) return -1;
      if (!a[0].startsWith("adm") && b[0].startsWith("adm")) return 1;
      return a[0].localeCompare(b[0]);
    });

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  return (
    <Box>
      <Modal
        open={openModalPerfil}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModalPerfil}>
          <Paper sx={styles.modalPerfil}>
            <Stack sx={styles.StackFoto}>
              <Box sx={styles.BoxAvatar}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    editModalPerfil ? (
                      <Tooltip title={"adicionar imagem"}>
                        <IconButton
                          //variant="outlined"
                          component="label"
                          color="primary"
                          sx={{
                            color: "#FEFEFE",
                            bgcolor: "primary.main",
                            "&:hover": {
                              backgroundColor: "#462865",
                            },
                          }}
                        >
                          <AddAPhoto />{" "}
                          <input
                            hidden
                            accept=".png, .jpg, .jpeg"
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file.size < 1024 * 300) {
                                Resizer.imageFileResizer(
                                  file,
                                  500,
                                  500,
                                  "JPEG",
                                  80,
                                  0,
                                  (uri) => {
                                    setFotoPerfil(uri);
                                  },
                                  "base64",
                                  500,
                                  500
                                );
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(file);
                                fileReader.onload = () => {};
                                fileReader.onerror = (error) => {
                                  console.error("Error:", error);
                                };
                              } else {
                                setSnackbar({
                                  children: "Tamanho máximo 300kb",
                                  severity: "error",
                                });
                              }
                            }}
                          />{" "}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={"Setor: " + setorPerfil.toLowerCase()}>
                        <Box
                          variant="contained"
                          component="label"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            color: "#FEFEFE",
                            bgcolor: "primary.main",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {setorPerfil === "NOC" ||
                          setorPerfil === "NOC-EASYFLOW" ||
                          setorPerfil === "NOC-EASYMON" ? (
                            <SupportAgentOutlined />
                          ) : setorPerfil === "IOT" ? (
                            <MemoryOutlined />
                          ) : setorPerfil === "DEV" ? (
                            <Code />
                          ) : setorPerfil === "FINANCEIRO" ? (
                            <AttachMoneyOutlined />
                          ) : setorPerfil === "VENDAS" ? (
                            <SellOutlined />
                          ) : (
                            <Person />
                          )}
                        </Box>
                      </Tooltip>
                    )
                  }
                >
                  <Avatar
                    src={fotoPerfil}
                    color="secondary"
                    sx={styles.Avatar}
                  />
                </Badge>
              </Box>
            </Stack>
            <Box sx={styles.boxAreaModalPerfil}>
              <Box sx={styles.boxAreaTituloModalPerfil}>
                <IconButton
                  onClick={() => {
                    if (!disableSaveButtom) {
                      setOpenModalAlertSave(true);
                    } else {
                      handleCloseModalPerfil();
                      setEditModalPerfil(false);
                      setSelectedPermissoes([]);
                      setErrorEmail(false);
                      setErrorLogin(false);
                      setChecked((prevState) => {
                        const newState = {};
                        for (const key in prevState) {
                          newState[key] = {
                            valor: false,
                            titulo: prevState[key]?.titulo,
                          };
                        }
                        return newState;
                      });
                    }
                  }}
                  sx={{
                    position: "absolute",
                    right: 4,
                    top: 1,
                  }}
                >
                  <Close />
                </IconButton>
                <Typography
                  variant="h4"
                  sx={{ mb: "16px", color: "primary.main" }}
                >
                  Perfil
                </Typography>
              </Box>
              <Box sx={styles.boxAreaConteudoModalPerfil}>
                <Box sx={styles.boxAreaButtomModalPerfil}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: "-21px",
                      mt: "-6px",
                      gap: "10px",
                    }}
                  >
                    {editModalPerfil && (
                      <Button
                        onClick={() => handleCancelEditPerfil()}
                        sx={{ color: "secondary.main" }}
                        variant="text"
                      >
                        {" "}
                        Cancelar
                      </Button>
                    )}
                    {profileOpenModal?.nome !== null && (
                      <Button
                        disabled={
                          (editModalPerfil && disableSaveButtom) ||
                          (editModalPerfil && emailInvalido) ||
                          (editModalPerfil && loginInvalido)
                        }
                        onClick={() => {
                          setEditModalPerfil(true);
                          if (editModalPerfil && !disableSaveButtom) {
                            handleApiUpdatePerfil();
                          }
                        }}
                        sx={{ color: "primary.main" }}
                        variant="text"
                      >
                        {editModalPerfil ? "Salvar" : "Editar"}{" "}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box sx={styles.boxAreaInputsModalPerfil}>
                  <Stack
                    direction={"column"}
                    padding={"10px"}
                    gap={"30px"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    sx={{
                      marginTop: "20px",
                    }}
                  >
                    <Stack direction={"row"} spacing={"10px"}>
                      {" "}
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                        justifyContent={"center"}
                        // alignItems={"center"}
                      >
                        {" "}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={styles.TextCampo}
                        >
                          NOME
                        </Typography>
                        {!editModalPerfil ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              zIndex: 9,
                            }}
                          >
                            <Tooltip
                              title="Clique para copiar"
                              placement="bottom-start"
                            >
                              <span
                                onClick={() => {
                                  handleCopy(nomePerfil);
                                }}
                              >
                                <Typography sx={styles.TypographyCampoInput}>
                                  {nomePerfil}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Box>
                        ) : (
                          <TextField
                            id="nome"
                            variant="standard"
                            placeholder="Insira seu nome"
                            value={nomePerfil}
                            sx={styles.TextField}
                            onChange={(event) => {
                              setNomePerfil(event.target.value);
                            }}
                          />
                        )}
                      </Stack>
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        {" "}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={styles.TextCampo}
                        >
                          SOBRENOME
                        </Typography>
                        {!editModalPerfil ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              zIndex: 9,
                            }}
                          >
                            <Tooltip
                              title="Clique para copiar"
                              placement="bottom-start"
                            >
                              <span
                                onClick={() => {
                                  handleCopy(sobrenomePerfil);
                                }}
                              >
                                <Typography sx={styles.TypographyCampoInput}>
                                  {sobrenomePerfil}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Box>
                        ) : (
                          <TextField
                            variant="standard"
                            id="sobrenome"
                            placeholder="Insira seu sobrenome"
                            value={sobrenomePerfil}
                            sx={styles.TextField}
                            onChange={(event) => {
                              setSobrenomePerfil(event.target.value);
                            }}
                          />
                        )}
                      </Stack>
                    </Stack>
                    <Divider orientation="horizontal" sx={{ width: "450px" }} />
                    <Stack direction={"row"} spacing={"10px"}>
                      {" "}
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        {" "}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={styles.TextCampo}
                        >
                          LOGIN
                        </Typography>
                        {!editModalPerfil ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              zIndex: 9,
                            }}
                          >
                            <Tooltip
                              title="Clique para copiar"
                              placement="bottom-start"
                            >
                              <span
                                onClick={() => {
                                  handleCopy(loginPerfil);
                                }}
                              >
                                <Typography sx={styles.TypographyCampoInput}>
                                  {loginPerfil}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Box>
                        ) : (
                          <TextField
                            error={errorLogin || loginInvalido}
                            helperText={errorLogin ? "Login existente" : false}
                            variant="standard"
                            id="login"
                            placeholder="Insira seu login"
                            value={loginPerfil}
                            sx={styles.TextField}
                            onChange={(event) => {
                              setLoginPerfil(event.target.value);
                            }}
                          />
                        )}
                      </Stack>
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        {" "}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={styles.TextCampo}
                        >
                          EMAIL
                        </Typography>
                        {!editModalPerfil ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              zIndex: 9,
                            }}
                          >
                            <Tooltip
                              title="Clique para copiar"
                              placement="bottom-start"
                            >
                              <span
                                onClick={() => {
                                  handleCopy(emailPerfil);
                                }}
                              >
                                <Typography sx={styles.TypographyCampoInput}>
                                  {emailPerfil}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Box>
                        ) : (
                          <TextField
                            error={errorEmail || emailInvalido}
                            helperText={errorEmail ? "Email existente" : false}
                            variant="standard"
                            id="email"
                            placeholder="Insira seu email"
                            value={emailPerfil}
                            sx={styles.TextField}
                            onChange={(event) => {
                              setEmailPerfil(event.target.value);
                            }}
                          />
                        )}
                      </Stack>
                    </Stack>{" "}
                    <Divider orientation="horizontal" sx={{ width: "450px" }} />
                    <Stack direction={"row"} spacing={"10px"}>
                      {" "}
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        {" "}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={styles.TextCampo}
                        >
                          STATUS PERFIL
                        </Typography>
                        {!editModalPerfil ? (
                          <Typography
                            variant="h5"
                            sx={styles.TypographyCampoSetor}
                          >
                            {statusPerfil ? (
                              <Box sx={styles.boxStatusItem}>
                                <Person
                                  sx={{
                                    color: "primary.main",
                                    mt: "-3px",
                                    fontSize: "22px",
                                  }}
                                />
                                <Typography
                                  variant="h5"
                                  sx={styles.TypographyCampo}
                                >
                                  ATIVO
                                </Typography>
                              </Box>
                            ) : profileOpenModal?.nome !== null ? (
                              <Box sx={styles.boxStatusItem}>
                                <PersonOff
                                  sx={{
                                    color: "secondary.main",
                                    mt: "-3px",
                                    fontSize: "22px",
                                  }}
                                />
                                <Typography
                                  variant="h5"
                                  sx={styles.TypographyCampo}
                                >
                                  DESATIVADO
                                </Typography>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  ...styles.boxStatusItem,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <Box>
                                    <Person
                                      sx={{
                                        color: "chip.inProgress",
                                        fontSize: "22px",
                                      }}
                                    />
                                    <AccessTimeFilledIcon
                                      sx={{
                                        fontSize: "15px",
                                        color: "chip.inProgress",
                                        zIndex: 1,
                                        position: "absolute",
                                        bottom: 3,
                                        right: -3,
                                        borderRadius: "50%",
                                        backgroundColor: "background.paper",
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="h5"
                                  sx={styles.TypographyCampo}
                                >
                                  ESPERANDO LOGIN
                                </Typography>
                              </Box>
                            )}
                          </Typography>
                        ) : (
                          <FormControl variant="standard" sx={styles.TextField}>
                            <Select
                              labelId="demo-simple-select-label"
                              value={statusPerfil ? "ATIVO" : "DESATIVADO"}
                              onChange={(event) => {
                                handleChangeStatusPerfil(event);
                              }}
                              name="status"
                              color="primary"
                              //inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={"ATIVO"}>
                                <Box sx={styles.boxStatusItem}>
                                  <Person
                                    sx={{
                                      color: "primary.main",
                                      mt: "-3px",
                                      fontSize: "22px",
                                    }}
                                  />
                                  <Typography>ATIVO</Typography>
                                </Box>
                              </MenuItem>
                              <MenuItem value={"DESATIVADO"}>
                                <Box sx={styles.boxStatusItem}>
                                  <PersonOff
                                    sx={{
                                      color: "secondary.main",
                                      mt: "-3px",
                                      fontSize: "22px",
                                    }}
                                  />
                                  <Typography>DESATIVADO</Typography>
                                </Box>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </Stack>{" "}
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        {" "}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={styles.TextCampo}
                        >
                          SETOR
                        </Typography>
                        {!editModalPerfil ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              width: "100%",
                              zIndex: 9,
                            }}
                          >
                            <Tooltip
                              title="Clique para copiar"
                              placement="bottom-start"
                            >
                              <span
                                onClick={() => {
                                  handleCopy(setorPerfil);
                                }}
                              >
                                <Typography sx={styles.TypographyCampoInput}>
                                  {setorPerfil}
                                </Typography>
                              </span>
                            </Tooltip>
                          </Box>
                        ) : (
                          <FormControl variant="standard" sx={styles.TextField}>
                            <Select
                              labelId="demo-simple-select-label"
                              value={setorPerfil}
                              onChange={(event) => {
                                handleChangeSetorPerfil(event);
                              }}
                              name="setor"
                              color="primary"
                              //inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={"NOC"}>NOC</MenuItem>
                              <MenuItem value={"NOC-EASYFLOW"}>
                                NOC-EASYFLOW
                              </MenuItem>
                              <MenuItem value={"NOC-EASYMON"}>
                                NOC-EASYMON
                              </MenuItem>
                              <MenuItem value={"DEV"}>DEV</MenuItem>
                              <MenuItem value={"IOT"}>IOT</MenuItem>
                              <MenuItem value={"FINANCEIRO"}>
                                FINANCEIRO
                              </MenuItem>
                              <MenuItem value={"VENDAS"}>VENDAS</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </Stack>{" "}
                    </Stack>
                    <Divider orientation="horizontal" sx={{ width: "450px" }} />
                    <Stack direction={"row"} spacing={"10px"}>
                      {" "}
                      <Stack
                        direction={"column"}
                        width={"450px"}
                        spacing={"10px"}
                        justifyContent={"center"}
                        // alignItems={"center"}
                      >
                        {" "}
                        <Typography
                          variant="h5"
                          color="primary"
                          sx={styles.TextCampo}
                        >
                          PERMISSÕES
                        </Typography>
                        {!editModalPerfil ? (
                          <Box sx={styles.boxAreaChipsModalPerfil}>
                            {sortedPermissoes?.map(([titulo, valor], index) => (
                              <Chip
                                key={index}
                                label={titulo}
                                sx={{
                                  backgroundColor: "chip.waitingReturn",
                                  color: "#ffffff",
                                }}
                              />
                            ))}
                          </Box>
                        ) : (
                          <Autocomplete
                            multiple
                            disableClearable={true}
                            id="checkboxes-tags-demo"
                            options={permissoes}
                            disableCloseOnSelect
                            value={selectedPermissoes}
                            onChange={(event, newValue) =>
                              setSelectedPermissoes(newValue)
                            }
                            sx={{
                              width: "100%",
                              "&.MuiAutocomplete-root .MuiAutocomplete-tag": {
                                backgroundColor: "chip.waitingReturn",
                              },
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li
                                {...props}
                                onClick={(event) =>
                                  handleChangeChecked(
                                    event,
                                    option,
                                    checked,
                                    setChecked,
                                    selectedPermissoes,
                                    setSelectedPermissoes
                                  )
                                }
                              >
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  style={{ marginRight: 8 }}
                                  checked={selected || !!checked[option]?.valor}
                                />
                                {option}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder={
                                  Object.keys(checked).some(
                                    (key) => checked[key].valor
                                  )
                                    ? ""
                                    : "Permissões"
                                }
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={option}
                                  label={option}
                                  {...getTagProps({ index })}
                                  onDelete={() => handleDeleteChip(option)}
                                />
                              ))
                            }
                          />
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Fade>
      </Modal>
      <AlertModal
        openModal={openModalAlertSave}
        setOpenModal={setOpenModalAlertSave}
        textContent={
          "Se você prosseguir com a operação as alterações serão perdidas permanentemente!"
        }
        handleButton={handleSairSemSalvar}
        severity={"warning"}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
