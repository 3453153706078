import {
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,

} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    minWidth: "1000px",
    width: "1600px",
    m: "auto",
    marginTop: "30px",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    width: "100%",

    flexDirection: "column",
  },
};

export default function Zabbix() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [pages, setPages] = useState([
    "/noc/zabbix/verificarTemplates",
    "/noc/zabbix/gerenciamentoDeTags",
    "/noc/zabbix/filtroDeHosts"

  ]);

  useEffect(() => {
    if (window.location.pathname == '/noc/zabbix') navigate(pages[0])
  })
  useEffect(() => {
    const pageName = window.location.pathname;
    pages.map((page, index) => {
      if (pageName.includes(page)) {
        setValue(index);
      }
    });
  }, [navigate]);

  return (
    <Stack justifyContent="center" alignItems="center">
      {/* <Paper
        sx={{
          borderRadius: "15px",
          maxWidth: "100%",
          width: "auto",
          mt: 2,
          mb: 2,
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons={true}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >

          <Tab
            label={"Scanner de Templates"}
            value={0}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/zabbix/verificarTemplates");
            }}
          />
          <Tab
            label={"Gerenciamento de tags "}
            value={1}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/zabbix/gerenciamentoDeTags");
            }}
          />
          <Tab
            label={"Filtro de Host "}
            value={2}
            sx={styles.tab}
            onClick={() => {
              navigate("/noc/zabbix/filtroDeHosts");
            }}
          />




        </Tabs>
      </Paper> */}
      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>

    </Stack>

  )
}
