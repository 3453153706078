import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";

export default function CreateModalASN(params) {
  const {
    setOpenCreateModal,
    openCreateModal,
    setSnackbar,
    setLoadingTable,
    styles,
    setErrorName,
    errorName,
    comparaNome,
    handleNewAsn,
    acessoProdutoId,
    acessoClientesId,
  } = params;
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [nomeAsn, setNomeAsn] = useState("");
  const [asn, setAsn] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableCreateButton === false) {
      handleApiPostAsn();
    }
  };
  useEffect(() => {
    if (comparaNome(nomeAsn) || !asn) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [asn, nomeAsn]);
  function clearModal() {
    setNomeAsn("");
    setAsn("");
  }
  async function handleApiPostAsn() {
    try {
      setLoadingTable(true);
      setDisableCreateButton(true);
      const response = await api.post("/relatoriosFlow/asn/create", {
        nome_asn: nomeAsn,
        asn: parseInt(asn),
        acessoClientesId,
        acessoProdutoId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleNewAsn(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableCreateButton(false);
      setOpenCreateModal(false);
      clearModal();
    }
  }
  return (
    <>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          clearModal();
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Novo ASN </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-nome"
                label="Nome"
                variant="standard"
                value={nomeAsn}
                error={errorName}
                helperText={errorName ? "Nome é uma chave única" : null}
                onChange={(e) => {
                  if (comparaNome(e.target.value)) {
                    setErrorName(true);
                  } else {
                    setErrorName(false);
                  }
                  setNomeAsn(e.target.value);
                }}
              />
              <TextField
                sx={styles.TextField}
                id="textfield-porta"
                label="ASN"
                variant="standard"
                onKeyDown={handleKeyDown}
                type="number"
                value={asn}
                onChange={(e) => setAsn(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCreateModal(false);
                clearModal();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableCreateButton}
              onClick={() => {
                handleApiPostAsn();
              }}
            >
              Criar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
