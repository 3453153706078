import React, { useState } from "react";

import { Box, CssBaseline } from "@mui/material";

//import Notificacoes from "./notificacoes";

import AppBar from "./appBar";

import { Outlet } from "react-router-dom";

//import ModalPerfil from "../perfil";

function Layout(params) {
  const { colorMode, setColorMode, socket } = params;

  const [openRightBar, setOpenRightBar] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleRightBarToggle = () => {
    setOpenRightBar((preValue) => !preValue);
    //setOpenDrawer(false);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer((preValue) => !preValue);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar
        colorMode={colorMode}
        setColorMode={setColorMode}
        handleDrawerToggle={handleDrawerToggle}
        handleRightBarToggle={handleRightBarToggle}
        socket={socket}
        setOpenDrawer={setOpenDrawer}
        params={params}
      />
      <Box sx={{ height: "64px", width: "99vw" }} />
      <Box>
        <Outlet
          context={[openRightBar, setOpenRightBar, openDrawer, setOpenDrawer]}
        />
      </Box>
    </Box>
  );
}

export default Layout;
