import { Add, Close, ExpandMore, OpenInNew } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  AccordionDetails,
  Stack,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Snackbar,
  Alert,
  Paper,
  useMediaQuery,
  createSvgIcon,
  Grid,
  useTheme,
  Grid2,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CardAcessos from "../cardAcessos";
import { ReactComponent as ZabbixIcon } from "../../../assets/icons/zabbix.svg";
import { ReactComponent as EasymonIcon } from "../../../assets/icons/IconEasyMon.svg";
import { ReactComponent as EasyflowIcon } from "../../../assets/icons/IconEasyFlow.svg";
import { ReactComponent as PortainerIcon } from "../../../assets/icons/iconPortainer.svg";
import { ReactComponent as SistemaIcon } from "../../../assets/icons/iconMw.svg";

import { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import Links from "./links";
import useStyles from "./styles";

export default function ProductRow(props) {
  const { atualizarProduto, produto, clienteId } = props;
  const [snackbar, setSnackbar] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [nomeDaMaquina, setNomeDaMaquina] = useState("");
  const [tipo, setTipo] = useState("");
  const [disableButton, setDissableButton] = useState(true);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [arrayLinks, setArrayLinks] = useState(
    Array.isArray(produto?.link)
      ? produto.link.length < 4
        ? [...produto.link, {}]
        : [...produto.link]
      : []
  );
  const componentRef = useRef(null);
  const cardMinWidth = 600;
  const columnsNumber = parseInt(
    width / cardMinWidth < 1 ? 1 : Math.round((width * 0.9) / cardMinWidth)
  );

  const theme = useTheme();
  const styles = useStyles(theme);
  const Zabbix = createSvgIcon(<ZabbixIcon />);
  const Easymon = createSvgIcon(<EasymonIcon />);
  const Easyflow = createSvgIcon(<EasyflowIcon />);
  const Portainer = createSvgIcon(<PortainerIcon />);
  const Sistema = createSvgIcon(<SistemaIcon />);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Limpa o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function atualizarMaquinas(novaMaquina) {
    let aux = produto;

    const indexProduto = aux.maquinas.findIndex(
      (maquina) => maquina.id === novaMaquina.id
    );

    aux.maquinas[indexProduto] = novaMaquina;

    atualizarProduto(aux);
  }

  function excluirMaquinas(novaMaquina) {
    let aux = produto;

    const indexProduto = aux.maquinas.findIndex(
      (maquina) => maquina.id === novaMaquina.id
    );

    aux.maquinas.splice(indexProduto, 1);

    atualizarProduto(aux);
  }

  useEffect(() => {
    if (nomeDaMaquina !== "" && tipo !== "") {
      setDissableButton(false);
    }
  }, [nomeDaMaquina, tipo]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleApiCreateMaquinaApi() {
    try {
      setDissableButton(true);
      const response = await api.post("/criarMaquinaAcesso", {
        acessoClientesId: clienteId,
        acessoProduto: produto.id,
        tipo,
        nomeDaMaquina,
      });

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });

        let aux = produto;

        aux.maquinas.push(response.data);

        atualizarProduto(aux);
        setOpenModal(false);
        setDissableButton(false);
        setTipo("");
        setNomeDaMaquina("");
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setDissableButton(false);

      console.error(error);
    }
  }

  async function handleApiUpdateLink(link, index) {
    try {
      let novoLink = produto.link;
      novoLink[index] = link;

      const response = await api.put("/putLinkAcesso", {
        link: novoLink,
        acessoProduto: produto.id,
        acessoClientesId: clienteId,
      });
      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        return false;
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.error(error);
    }
  }

  const links = {
    zabbix: {
      //icon: <Zabbix sx={{ color: "white", fontSize: "36px" }} />,
      icon: (
        <Typography sx={{ color: "white", fontSize: "22px", fontWeight: 500 }}>
          Z
        </Typography>
      ),
      name: "Zabbix",
      color: "#D30100",
    },
    easymon: {
      icon: <Easymon sx={{ color: "#D50A0F" }} />,
      name: "easymon",
      color: "#15304F",
    },
    easyflow: {
      icon: <Easyflow sx={{ color: "#35CB8E" }} />,
      name: "easyflow",
      color: "#15304F",
    },
    portainer: {
      icon: <Portainer sx={{ color: "white" }} />,
      name: "portainer",
      color: "#15304F",
    },
    sistema: {
      icon: <Sistema sx={{ color: "#ffffff", fontSize: "35px" }} />,
      name: "sistema",
      color: "#15304F",
    },
  };

  const renderIcons = produto?.link?.filter((link) => link.tipo) || [];

  return (
    <Box sx={styles.conteiner}>
      <Box sx={styles.body}>
        <Accordion sx={styles.accordion} ref={componentRef}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack direction={"row"} gap={1} mr={1} alignItems={"center"}>
              {Array.isArray(produto.link) &&
                Object.keys(links) &&
                renderIcons.map((renderIcon, index) => {
                  return (
                    <IconButton
                      key={produto.id + index}
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: links[renderIcon.tipo]?.color,
                        "&:hover": {
                          backgroundColor: links[renderIcon.tipo]?.color,
                        },
                        boxShadow:
                          "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                      }}
                      onClick={(event) => {
                        //abrir link em nova aba
                        event.stopPropagation();
                        window.open(renderIcon.link, "_blank");
                      }}
                    >
                      {links[renderIcon.tipo]?.icon}
                    </IconButton>
                  );
                })}
              <Typography> {produto.nome}</Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Box sx={styles.accordionContent}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                gap={"16px"}
                width={"100%"}
              >
                <Button
                  variant={"outlined"}
                  size="small"
                  onClick={() => {
                    setOpenLinkModal(true);
                  }}
                  endIcon={<OpenInNew />}
                >
                  Links
                </Button>

                <Button
                  size="small"
                  variant={"outlined"}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  endIcon={<Add />}
                >
                  Novo
                </Button>
              </Stack>
              <Box sx={{ flexGrow: 1 }}>
                <Grid2
                  container
                  rowSpacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  sx={styles.girdContainer}
                  columnSpacing={1}
                  columns={columnsNumber}
                >
                  {produto.maquinas &&
                    produto.maquinas.map((maquina, mqIndex) => {
                      return (
                        <Grid2 key={maquina.id + mqIndex} size={1}>
                          <CardAcessos
                            atualizarMaquinas={atualizarMaquinas}
                            excluirMaquinas={excluirMaquinas}
                            maquina={maquina}
                            clienteId={clienteId}
                            produtoId={produto.id}
                            columnsNumber={columnsNumber}
                          />
                        </Grid2>
                      );
                    })}
                </Grid2>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Dialog
        id="dialog-create-modal"
        open={openModal}
        onClose={() => {
          setTipo("");
          setNomeDaMaquina("");
          setOpenModal(false);
        }}
        sx={styles.dialog}
      >
        <DialogTitle color={"primary"}>{"Criar nova máquina"}</DialogTitle>
        <DialogContent>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap={"wrap"}
          >
            <TextField
              variant="standard"
              label="Nome"
              sx={styles.TextField}
              value={nomeDaMaquina}
              onChange={(event) => {
                setNomeDaMaquina(event.target.value);
              }}
            />
            <FormControl sx={styles.TextField} variant="standard">
              <InputLabel>Tipo</InputLabel>
              <Select
                value={tipo}
                label="Tipo"
                onChange={(event) => {
                  setTipo(event.target.value);
                }}
              >
                <MenuItem value={"manager"}>Manager</MenuItem>
                <MenuItem value={"database"}>Data Base</MenuItem>
                <MenuItem value={"proxy"}>Proxy</MenuItem>
                <MenuItem value={"worker"}>Worker</MenuItem>
                <MenuItem value={"outro"}>Outro</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setTipo("");
              setNomeDaMaquina("");
              setOpenModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            onClick={handleApiCreateMaquinaApi}
            disabled={disableButton}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openLinkModal}
        onClose={() => setOpenLinkModal(false)}
        sx={styles.modal}
      >
        <Paper sx={styles.dialogPaper}>
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Typography variant="h6" component="h2" color="primary">
              Gerenciamento de links
            </Typography>

            <IconButton
              onClick={() => {
                setOpenLinkModal(false);
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Stack>

          <Grid2 container columns={{ xs: 1, sm: 1, lg: 2 }} spacing={1}>
            {Array.isArray(produto.link) &&
              Array.isArray(arrayLinks) &&
              arrayLinks.map((link, index) => (
                <Grid2 key={index} size={1}>
                  <Links
                    clienteId={clienteId}
                    linkProduto={produto?.link[index] || []}
                    index={index}
                    handleApiUpdateLink={handleApiUpdateLink}
                  />
                </Grid2>
              ))}
          </Grid2>
        </Paper>
      </Modal>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
