import {
  Alert,
  Autocomplete,
  Box,
  Fade,
  LinearProgress,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Chip,
  Skeleton,
  Link,

} from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import imgEmpty from "../../../../assets/wallet.svg";
import api from "../../../../services/api";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import React, { useCallback } from "react";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    minWidth: "1000px",
    width: "1600px",
    m: "auto",
    marginTop: "30px",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    width: "100%",

    flexDirection: "column",
  },
};

export default function RelatoriosFlow() {
  const [snackbar, setSnackbar] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [cliente, setCliente] = useState(null);
  const tipo = "easymon";
  const [easymon, setEasyMon] = useState(null);
  const [acessoClientesId, setAcessoClientesId] = useState("");
  const [acessoProdutoId, setAcessoProdutoId] = useState("");
  const [rows, setRows] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [loadingProdutos, setLoadingProdutos] = useState(false);
  const [disableButtonEasyFLow, setDisableButtonEasyFLow] = useState(true);
  const [disableButtonStart, setDisableButtonStart] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [link, setLink] = useState(null);


  const getClientesLabel = (clientes) =>
    `${clientes.numero} - ${clientes.empresa}`;
  const getProdutosLabel = (produtos) => `${produtos.nome}`;

  useEffect(() => {
    handleApiGetClientes();
  }, []);

  useEffect(() => {
    if (cliente) {
      setDisableButtonEasyFLow(false);
      handleApiGetProdutos(cliente.id, tipo);
    }
  }, [cliente]);

  useEffect(() => {
    if (acessoClientesId === undefined || acessoProdutoId === undefined)
      setDisableButtonStart(true)
  }, [acessoClientesId, acessoProdutoId])


  async function handleApiGetClientes() {
    try {
      setLoadingClientes(true);
      const response = await api.get("/clienteAcesso");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
        setLoadingPage(true);
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingClientes(false);
    }
  }

  async function handleApiUpdateTags() {
    try {
      setLoadingPage(true);
      const response = await api.post("/host/tags/create", {
        acessoClientesId,
        acessoProdutoId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível atualizar as tags",
          severity: "error",
        });

      } else {
        setRows(response.data.responseUpdateTag);
        setLink(response.data.link)
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingPage(false);
    }
  }


  async function handleApiGetProdutos(acessoClientesId, tipo) {
    try {
      setLoadingProdutos(true);
      const response = await api.post("/produtoCliente", {
        acessoClientesId,
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setProdutos(response.data);
        if (response.data?.length === 1) {
          setEasyMon(response.data[0]);
          setAcessoProdutoId(response.data[0].id);
          setDisableButtonStart(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingProdutos(false);
    }
  }
  const columns = [

    {
      field: 'host',
      headerName: 'Host',
      flex: 1,
      editable: false,
      renderCell: (params) => {

        return (

          <Link

            href={link +
              "/hosts.php?filter_host=" +
              params.value?.replace(/ /g, "+") +
              "&filter_set=1"
            }
            underline="none"
            target="blank_"
          >
            {params.value}
          </Link>
        );
      },


    },
    {
      headerAlign: "center",
      field: 'fabricante',
      headerName: 'Fabricante',
      width: 150,
      editable: false,
      renderCell: (params) => {

        return (
          <Chip
            label={params.value}
            sx={{
              bgcolor: params.value == "[MANUAL]" ? "chip.arquive" : "chip.waitingReturn",
              color: "white",
              width: "100%"
            }}
          />
        );
      },
    },
    {
      headerAlign: "center",
      field: 'funcao',
      headerName: 'Função',
      type: 'number',
      width: 150,
      editable: false,
      renderCell: (params) => {

        return (
          <Chip
            label={params.value}
            sx={{
              bgcolor: params.value == "[MANUAL]" ? "chip.arquive" : "chip.waitingReturn",
              color: "white",
              width: "100%"
            }}
          />
        );
      },
    },
    {
      headerAlign: "center",
      field: 'modelo',
      headerName: 'Modelo',
      type: 'number',
      width: 150,
      editable: false,
      renderCell: (params) => {

        return (
          <Chip
            label={params.value}
            sx={{
              bgcolor: params.value == "[MANUAL]" ? "chip.arquive" : "chip.waitingReturn",
              color: "white",
              width: "100%"
            }}
          />
        );
      },
    },
    {
      headerAlign: "center",
      field: 'status',
      headerName: 'Status',
      type: 'number',
      width: 200,
      editable: false,
      renderCell: (params) => {
        let status = ["Atualização completa", "Atualização parcial", "Atualização inconclusiva", "Erro ao atualizar"]

        return (
          <Chip
            label={status[params.value]}
            sx={{
              bgcolor: params.value == 0 ? "chip.completed" : params.value == 1 ? "chip.inProgress" : params.value == 2 ? "chip.arquive" : "chip.pending",
              color: "white",
              width: "100%"
            }}
          />
        );
      },
    },

  ];




  return (
    <>
      <Box sx={styles.container}>
        <Box width="90%">
          <Paper sx={styles.paperCliente}>
            <Typography
              color={"primary"}
              fontWeight={500}
              sx={{
                fontSize: "1.25rem",
              }}
            >
              Selecione um Cliente
            </Typography>
            <Stack
              direction={"row"}
              gap={4}
              justifyContent={"space-between"}
              width="100%"
              alignItems={"center"}
            >
              <Stack direction={"row"} gap={3}>
                <Autocomplete
                  sx={{ width: "180px" }}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                    setAcessoClientesId(newValue.id);
                    setEasyMon(null);
                    setAcessoProdutoId("");
                    setDisableButtonStart(true);
                  }}
                  value={cliente}
                  options={clientes}
                  getOptionLabel={getClientesLabel}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                      <Fade in={loadingClientes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />

                <Autocomplete
                  disabled={disableButtonEasyFLow}
                  sx={{ width: "200px" }}
                  onChange={(event, newValue) => {
                    setEasyMon(newValue);
                    setAcessoProdutoId(newValue?.id);
                    setDisableButtonStart(false);
                  }}
                  value={easymon}
                  options={produtos}
                  getOptionLabel={getProdutosLabel}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Selecione o EasyMon"
                        variant="standard"
                      />
                      <Fade in={loadingProdutos}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Stack>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  variant="contained"
                  sx={{ height: "40px" }}
                  disabled={disableButtonStart || loadingPage}
                  onClick={handleApiUpdateTags}
                >
                  Iniciar Tagueamento <TroubleshootIcon />
                </Button>
                {loadingPage && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Paper>
          {loadingPage && (
            <Stack width="100%" flexDirection="row" gap={2} padding="20px 0px">
              <Skeleton variant="rounded" width="100%" height={500} />

            </Stack>
          )}
          {rows.length > 0 && !loadingPage && (
            <Box mt={2} sx={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={25}
                pageSizeOptions={[25, 50, 100]}

              //experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          )}
          {rows.length == 0 && !loadingPage && (
            <Box
              width="100%"
              height="300px"
              sx={{
                backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                filter: "opacity(0.6)",
                mt: 1,
              }}
            >
              <img
                style={{
                  width: "100px",
                  filter:
                    "invert(21%) sepia(35%) saturate(14%) hue-rotate(317deg) brightness(90%) contrast(87%)",
                }}
                src={imgEmpty}
              />
              <Typography color="#525353" fontSize="25px">
                Cliente não Selecionado
              </Typography>
            </Box>
          )}


        </Box>
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
}
