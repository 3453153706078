const useStyles = ({ theme, openRightBar }) => ({
  cardContainer: {
    //border: "1px solid #5b2e87",
    backgroundColor: theme.palette.mode == "dark" ? "#1b1b1b" : "#EFF2F5",
    borderRadius: "16px",
    padding: "16px",
    gap: "8px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    padding: 0,
    width: "100%",
  },
  cardContentHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  cardContentTitle: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    alingItens: "center",
  },

  boxForm: {
    display: "flex",
    flexDirection: "column",
    flexWrap: { xs: "wrap", smd: "nowrap" },
    maxWidth: {
      xs: "calc(100vw - 260px)",
      lg: openRightBar ? "calc(100vw - 490px )" : "calc(100vw - 1200px)",
    },
  },

  boxContainer: {
    display: "flex",
    flexDirection: { xs: "column", smd: "row" },
    justifyContent: "space-between",
    width: "100%",
    gap: 1,
  },

  boxCampo: {
    display: "flex",
    flexDirection: "column",
    alingItens: "flex-start",
    gap: "0px",
    width: { xs: "100%", smd: "48%" },
    minHeight: "48px",
  },

  titleCampo: {
    fontWeight: "600",
    fontSize: "16px",
    color: "primary.main",
    display: "flex",
    alingItens: "center",
  },
  textCampo: {
    fontSize: "16px",
    lineHeight: "22px",
    cursor: "pointer",
    marginTop: "6px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: "0px",
    minHeight: "40px",
  },

  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardForm: {
    display: "flex",
    flexDirection: "column",
    alingItens: "flex-end",
    gap: "4px",
    height: "48px",
    marginTop: "5px",
  },
  divderForm: { borderBottomWidth: 2, paddingY: "10px" },
  nomeDaMaquina: {
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "32px",
    color: "primary.main",
  },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "error.main" },
  updateDisabledIcon: {
    color: "secondary.main",
    fontSize: "28px",
  },
});

export default useStyles;
