import { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Stack,
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Box,
  Autocomplete,
} from "@mui/material";

import api from "../../../../services/api";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
const stylesModal = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    padding: "10px",
  },
  campoFiltro: {
    width: "200px",
  },
  campoFiltroSecondary: {
    width: "420px",
  },
  button: { height: "40px", minWidth: "120px" },
};

export default function ModalNovaDemanda(props) {
  const {
    openCreateModal,
    setOpenCreateModal,
    setNewRow,
    colaboradores,
    clientes,
    categorias,
    subCategorias,
  } = props;
  //campos do modal
  const [responsavel, setResponsavel] = useState(null);
  //const [solicitante, setSolicitante] = useState("");
  const [cliente, setCliente] = useState(null);
  const [parceiro, setParceiro] = useState(null);
  const status = 0;
  const [categoria, setCategoria] = useState("");
  const [subCategoria, setSubCategoria] = useState(null);
  const [data, setData] = useState(dayjs(new Date()));
  const [previsao, setPrevisao] = useState(dayjs(new Date().getTime() + 86400000 * 3));
  const [descricao, setDescricao] = useState("");
  //snackbar
  const [snackbar, setSnackbar] = useState(null);
  //button
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    if (!cliente || !parceiro || !descricao) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [cliente, parceiro, descricao]);
  function handleCreateRow(params) {
    let objectRow = {};
    objectRow.id = params.id;
    objectRow.responsavelId = params.responsavel;
    objectRow.solicitanteId = params.solicitante;
    objectRow.responsavel = params.usuario ? params.usuario.Perfil : "";
    objectRow.solicitante = params.solicitante
      ? params.usuarioCliente.PerfilCliente
      : "";
    objectRow.codigo = params.codigo;
    objectRow.descricao = params.descricao;
    objectRow.categoria = params.categoria;
    objectRow.subCategoria = params.subCategoria;
    objectRow.status = params.status;
    objectRow.previsao = params.previsao;
    objectRow.data = new Date(new Date(params.createAt) - 3 * 60 * 60 * 1000); //correção de fuso horario, com diferença de 3 horas
    objectRow.topicos = 0;
    objectRow.cliente = {
      cliente: params.acessoClientes?.empresa,
      numero: params.acessoClientes?.numero,
      id: params.acessoClientes?.id,
    };
    objectRow.parceiro = params.acessoClientes.parceiro;
    objectRow.acessoClientesId = params.acessoClientesId;
    setNewRow(objectRow);
  }

  function clearModal() {
    setResponsavel(null);
    setCategoria("");
    setSubCategoria(null);
    setData(dayjs(dayjs(new Date())));
    setPrevisao(dayjs(new Date().getTime() + 86400000 * 3));
    setDescricao("");
    setParceiro(null);
    setCliente(null);
  }
  async function handleApiPostRelatorios() {
    try {
      //setLoadingPage(true);
      const response = await api.post("/suporte/create", {
        descricao,
        previsao,
        responsavel: responsavel?.usuarioId,
        acessoClientesId: cliente?.id,
        status,
        categoria: categoria ? categoria : null,
        subCategoria: subCategoria ? subCategoria : null,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
        // setLoadingPage(false);
      } else {
        //  setLoadingPage(false);
        if (response.data) {
          handleCreateRow(response.data);
        }
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      //setLoadingPage(false);
    }
  }
  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={() => {
          clearModal();
          setOpenCreateModal(false);
        }}
      >
        <Paper sx={stylesModal.paper} elevation={3}>
          <DialogTitle color="primary">{"Nova Demanda"}</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Stack direction={"row"} gap={"20px"}>
              <Autocomplete
                sx={stylesModal.campoFiltro}
                onChange={(event, newValue) => {
                  setParceiro(newValue);
                  setCliente(null);
                }}
                value={parceiro ? parceiro : null}
                options={clientes.map((cliente) => cliente)}
                getOptionLabel={(option) => option.parceiro.toUpperCase()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parceiro"
                    variant="standard"
                    required
                  />
                )}
              />
              <Autocomplete
                disabled={!parceiro}
                sx={stylesModal.campoFiltro}
                onChange={(event, newValue) => {
                  setCliente(newValue);
                }}
                value={cliente ? cliente : null}
                options={
                  parceiro ? parceiro?.clientes?.map((name) => name) || [] : null
                }
                getOptionLabel={(option) =>
                  `${option.numero} ${option.cliente}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    variant="standard"
                    required
                  />
                )}
              />
            </Stack>
            <Autocomplete
              sx={stylesModal.campoFiltroSecondary}
              id="combo-box-demo"
              options={colaboradores}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Responsável" />
              )}
              value={responsavel ? responsavel : null}
              onChange={(event, newValue) => {
                setResponsavel(newValue);
              }}
              getOptionLabel={(option) => option.nome}
            />
            <TextField
              required
              id="input-descricao"
              variant="standard"
              label="Descrição"
              value={descricao}
              sx={stylesModal.campoFiltroSecondary}
              onChange={(event) => {
                if (event.target.value.length <= 255)
                  setDescricao(event.target.value);
              }}
              type="text"
              multiline
              rows={4}
              slotProps={{ maxLength: 255 }}
              //placeholder="Escreva uma breve descrição da sua demanda"
              helperText="Limite de 255 digitos"
            />
            <Stack direction={"row"} gap={"20px"}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="datadecriacao"
                  label="Data de Criação"
                  color="primary"
                  disabled
                  sx={stylesModal.campoFiltro}
                  value={data}
                  onChange={(newValeu) => {
                    setData(newValeu);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="previsao"
                  label="Previsão"
                  color="primary"
                  disabled
                  sx={stylesModal.campoFiltro}
                  value={previsao}
                  onChange={(newValeu) => {
                    setPrevisao(newValeu);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>{" "}
            <Stack direction={"column"} gap={"20px"}>
              {" "}
              <FormControl variant="standard">
                <InputLabel id="select-status">Categoria</InputLabel>
                <Select
                  labelId="select-categoria"
                  id="select-categoria-id"
                  value={categoria}
                  sx={stylesModal.campoFiltroSecondary}
                  label="Categoria"
                  onChange={(e) => {
                    setCategoria(e.target.value);
                    setSubCategoria("");
                  }}
                >
                  {categorias.map((name, index) => (
                    <MenuItem key={index} value={name.categoria}>
                      {name.categoria}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                <Autocomplete
                  sx={stylesModal.campoFiltroSecondary}
                  onChange={(event, newValue) => {
                    setSubCategoria(newValue);
                  }}
                  value={subCategoria ? subCategoria : null}
                  options={subCategorias
                    .filter((val) => val.categoria.includes(categoria))
                    .map((name, index) => name.subCategoria)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub-categoria"
                      variant="standard"
                    />
                  )}
                />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              // variant="contained"
              sx={stylesModal.button}
              onClick={() => {
                clearModal();
                setOpenCreateModal(false);
              }}
            >
              CANCELAR
            </Button>{" "}
            <Button
              //  variant="contained"
              disabled={disableButton}
              sx={stylesModal.button}
              onClick={() => {
                //  handleCreateRow();
                handleApiPostRelatorios();
                clearModal();
                setOpenCreateModal(false);
              }}
            >
              ADICIONAR
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
      {
        !!snackbar && (
          <Snackbar
            open
            onClose={() => setSnackbar(null)}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
          </Snackbar>
        )
      }
    </div >
  );
}
