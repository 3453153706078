import { Done, ReportProblem } from "@mui/icons-material";
import { Divider, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { Error } from "@mui/icons-material";
import { memo } from "react";

export const CellExibition = memo(
  ({ value, error, selected }) => {
    const theme = useTheme();

    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          padding: "0px 10px",
          height: "100%",
          width: "100%",
          border: selected ? "1px solid" + theme.palette.primary.main : null,
        }}
      >
        <Tooltip
          title={error}
          placement="bottom-start"
          slotProps={{
            tooltip: {
              sx: {
                p: 0.5,
                px: 1,
                bgcolor: theme.palette.mode === "dark" ? "#F44336" : "#D32F2F",
                color: theme.palette.mode === "dark" ? "#fff" : "#fff",
                fontSize: 14,
                borderRadius: "3px",
              },
            },
            popper: {
              sx: { pointerEvents: "none!important" },
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            },
          }}
        >
          <Typography
            sx={{ fontSize: 13, width: "100%" }}
            error={true}
            color={error ? "error" : "inherit"}
          >
            {value ? value : "-"}
          </Typography>
        </Tooltip>
        {error === "Campo obrigatório" && (
          <Error color="error" sx={{ fontSize: "18px" }} />
        )}
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.value?.toLowerCase() === nextProps.value?.toLowerCase() &&
      prevProps.selected === nextProps.selected &&
      prevProps.error === nextProps.error
    );
  }
);
export const CellExibitionAlerts = memo(
  ({ value, alert, text, selected }) => {
    const theme = useTheme();
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          padding: "0px 10px",
          height: "100%",
          width: "100%",
          border: selected ? "1px solid" + theme.palette.primary.main : null,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Tooltip title={value}>
            <Typography
              sx={{
                fontSize: "13px",
                width: "110px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {value}
            </Typography>
          </Tooltip>
          {alert && (
            <Tooltip
              title={"Não consta " + (!text ? "em predefinições" : text)}
            >
              <ReportProblem
                sx={{
                  fontSize: "18px",
                  color: theme.palette.iconsAcesso.customizado,
                }}
              />
            </Tooltip>
          )}
          {!alert && (
            <Tooltip title={"Contém " + (!text ? "em predefinições" : text)}>
              <Done
                sx={{ fontSize: "18px", color: theme.palette.chip.completed }}
              />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    // Renderiza novamente somente se params ou predefinicoes mudarem
    return (
      prevProps.value?.toLowerCase() === nextProps.value?.toLowerCase() &&
      prevProps.selected === nextProps.selected &&
      prevProps.text === nextProps.text &&
      prevProps.alert === nextProps.alert
    );
  }
);
