
export function validateTags(predefinicoes, host) {
    const { Funcoes: funcoes = [] } =
        predefinicoes?.find(
            ({ fabricante }) => fabricante?.toUpperCase() === host?.fabricante?.toUpperCase()
        ) || {};
    const { Modelos: modelos = [] } =
        funcoes?.find(({ funcao }) => funcao?.toUpperCase() === host?.funcao?.toUpperCase()) || {};
    const alertFabricante = predefinicoes.some((a) => a["fabricante"]?.toUpperCase() == host?.fabricante?.toUpperCase())
    const alertFuncao = funcoes.some((a) => a["funcao"]?.toUpperCase() == host?.funcao?.toUpperCase())
    const alertModelo = modelos.some((a) => a["modelo"]?.toUpperCase() == host?.modelo?.toUpperCase())
    return alertFabricante && alertFuncao && alertModelo
}