import {
  CheckCircleRounded,
  ErrorOutlineRounded,
  InfoRounded,
  WarningRounded,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";
const {
  DialogContentText,
  DialogActions,
  Stack,
  Paper,
  Modal,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  Box,
} = require("@mui/material");

export default function AlertModal(props) {
  const {
    openModal,
    setOpenModal,
    confirmar,
    handleButton,
    severity,
    textContent,
    singleButton,
  } = props;

  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const alertText = () => {
    if (textContent) {
      return textContent;
    }
    return severity === "success"
      ? "Sucesso na operação!"
      : severity === "warning"
      ? "Se você prosseguir com a operação o item será editado permanentemente e os dados anteriores são perdidos!"
      : severity === "info"
      ? "Está ação pode ser permanentemente!"
      : "Se você prosseguir com a operação o item será deletado e os dados apagados serão perdidos permanentemente!";
  };
  const alertTitle = () => {
    return severity === "success"
      ? "OK!"
      : severity === "warning"
      ? "Alerta!"
      : severity === "info"
      ? "Informação"
      : "Atenção";
  };
  const buttonText = () => {
    return severity === "success"
      ? "Aceitar"
      : severity === "warning"
      ? "Prosseguir"
      : severity === "info"
      ? "Confirmar"
      : "Excluir";
  };
  const color = () => {
    return severity === "success"
      ? "chip.completed"
      : severity === "warning"
      ? "chip.inProgress"
      : severity === "info"
      ? "chip.waitingReview"
      : "chip.pending";
  };
  const stylesModal = {
    container: { minWidth: "400px" },
    ContainerBox: {
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      height: "100%",
    },
    Paper: {
      minWidth: "400px",
      minHeight: "400px",
      justifyContent: "flex-start",
      alignItems: " center",
      display: "flex",
      flexDirection: "column",
      borderRadius: 3,
      bgcolor: "background.paperOutlined",
    },
    Stack: {
      borderRadius: 3,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      border: "none",
      bgcolor: `${
        severity === "success"
          ? "chip.completed"
          : severity === "warning"
          ? "chip.inProgress"
          : severity === "info"
          ? "chip.waitingReview"
          : "chip.pending"
      }`,
      color: "#ffffff",
      width: " 101%",
      minHeight: "182px",
      marginTop: "-1px",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "14px",
      gap: "44x",
    },
    DialogTitle: {
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      padding: "5px 20px",
    },
    DialogContent: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    DialogContentText: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
      width: "399px",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: 700,
    },
    DialogActions: {
      width: "100%",
      justifyContent: "flex-end",
      padding: "15px",
    },
    ButtonConfirm: {
      fontWeight: "800",
      backgroundColor: `${color()}`,
      "&:hover": {
        backgroundColor: `${color()}`,
        filter: `${
          colorMode === "light" ? "brightness(85%)" : "brightness(120%)"
        }`,
      },
    },
    ButtonCancel: {
      fontWeight: "800",
      color: `${colorMode === "light" ? "chip.arquive" : "#c3bfbf"}`,
      "&:hover": {
        color: `${colorMode === "light" ? "chip.arquive" : "#c3bfbf"}`,
        filter: `${
          colorMode === "light" ? "brightness(85%)" : "brightness(120%)"
        }`,
      },
    },
    Icon: { width: "67px", height: "67px" },
  };

  return (
    <Modal
      open={openModal}
      closeAfterTransition
      onClose={() => {
        setOpenModal(false);
      }}
    >
      <Box sx={stylesModal.ContainerBox}>
        <Paper variant="outlined" sx={stylesModal.Paper}>
          <Stack sx={stylesModal.Stack}>
            {severity === "success" ? (
              <CheckCircleRounded sx={stylesModal.Icon} />
            ) : severity === "warning" ? (
              <ErrorOutlineRounded sx={stylesModal.Icon} />
            ) : severity === "info" ? (
              <InfoRounded sx={stylesModal.Icon} />
            ) : (
              <WarningRounded sx={stylesModal.Icon} />
            )}

            <DialogTitle sx={stylesModal.DialogTitle}>
              {alertTitle()}
            </DialogTitle>
          </Stack>

          <DialogContent sx={stylesModal.DialogContent}>
            <DialogContentText sx={stylesModal.DialogContentText}>
              <Typography variant="h4" fontSize={20}>
                {alertText()}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={stylesModal.DialogActions}>
            {singleButton === true ? (
              <Button
                autoFocus
                variant="contained"
                color={severity}
                sx={stylesModal.ButtonConfirm}
                onClick={handleButton}
                disabled={confirmar}
              >
                {buttonText()}
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  sx={stylesModal.ButtonCancel}
                >
                  Cancelar
                </Button>

                <Button
                  autoFocus
                  variant="contained"
                  color={severity}
                  sx={stylesModal.ButtonConfirm}
                  onClick={handleButton}
                  disabled={confirmar}
                >
                  {buttonText()}
                </Button>
              </>
            )}
          </DialogActions>
        </Paper>
      </Box>
    </Modal>
  );
}
