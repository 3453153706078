import {
  Close,
  Dangerous,
  Delete,
  Edit,
  MoreVert,
  Save,
  UpdateDisabled,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  createSvgIcon,
  Typography,
  Menu,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  useTheme,
} from "@mui/material";
import { ReactComponent as ProxySvg } from "../../../assets/proxyIcon.svg";

import { useState } from "react";
import api from "../../../services/api";
import useStyles from "./styles";
import { useOutletContext } from "react-router-dom";

export default function CardAcessos(props) {
  const { atualizarMaquinas, excluirMaquinas, maquina, clienteId, produtoId } =
    props;

  const [snackbar, setSnackbar] = useState(null);
  const [editable, setEditable] = useState(false);
  const [nomeDaMaquina, setNomeDaMaquina] = useState(maquina.nomeDaMaquina);
  const [tipo, setTipo] = useState(maquina.tipo);
  const [vm, setVm] = useState(maquina.vm);
  const [ip, setIp] = useState(maquina.ip);
  const [sshPort, setSshPort] = useState(maquina.sshPort);
  const [user, setUser] = useState(maquina.user);
  const [senhaVm, setSenhaVM] = useState(maquina.senhaVm);
  const [senhaRoot, setSenhaRoot] = useState(maquina.senhaRoot);
  const [acesso, setAcesso] = useState(maquina.acesso);
  const [acesso2, setAcesso2] = useState(maquina.acesso2);
  const [atualizacaoDeRisco, setAtualizacaoDeRisco] = useState(
    maquina?.classificacao?.atualizacaoDeRisco
  );
  const [disableButton, setDissableButton] = useState(false);
  const [disableButtonDelete, setDissableButtonDelete] = useState(false);
  //modal
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { openRightBar } = useOutletContext();
  const theme = useTheme();

  const styles = useStyles({ theme, openRightBar });

  function resetCard() {
    setNomeDaMaquina(maquina.nomeDaMaquina);
    setTipo(maquina.tipo);
    setVm(maquina.vm);
    setIp(maquina.ip);
    setSshPort(maquina.sshPort);
    setUser(maquina.user);
    setSenhaVM(maquina.senhaVm);
    setSenhaRoot(maquina.senhaRoot);
    setAcesso(maquina.acesso);
    setAcesso2(maquina.acesso2);
    setAtualizacaoDeRisco(maquina?.classificacao?.atualizacaoDeRisco);
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const ProxyIcon = createSvgIcon(<ProxySvg />);

  async function handleApiUpdate() {
    try {
      setDissableButton(true);
      const response = await api.put("/putMaquina", {
        id: maquina.id,
        tipo: tipo,
        nomeDaMaquina,
        classificacao: { atualizacaoDeRisco },
        vm,
        ip,
        sshPort,
        user,
        senhaVm,
        senhaRoot,
        acesso,
        acesso2,
        acessoProduto: produtoId,
        acessoClientesId: clienteId,
      });
      setDissableButton(false);

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        atualizarMaquinas(response.data);
        //resetCard();
        setEditable(false);

        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        setOpenEditModal(false);
      }
    } catch (error) {
      setDissableButton(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    }
  }

  async function handleApiDeleteMaquina() {
    try {
      setDissableButtonDelete(true);
      const response = await api.post("/deleteMaquina", {
        id: maquina.id,
      });
      //atualizar pagina
      setDissableButtonDelete(false);

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });

        excluirMaquinas(response.data);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      setDissableButton(false);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setDissableButtonDelete(false);
      console.error(error);
    }
  }

  const Campo = (props) => {
    const { title, text } = props;
    return (
      <Box sx={styles.boxCampo}>
        <Box sx={styles.titleCampo}>{title}</Box>
        <Tooltip title="Clique para copiar" sx={styles.textCampo}>
          <Typography
            onClick={() => {
              handleCopy(text);
            }}
          >
            {text}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={styles.cardContainer} elevation={2}>
      <CardContent sx={styles.cardContent}>
        {!editable && (
          <Box sx={styles.cardContent}>
            <Box sx={styles.cardContentHeader} id="card-header">
              <Box sx={styles.cardContentTitle}>
                <Box sx={styles.nomeDaMaquina}>{nomeDaMaquina}</Box>
                {maquina?.classificacao?.atualizacaoDeRisco && (
                  <Tooltip title="Atualização de risco">
                    <UpdateDisabled sx={styles.updateDisabledIcon} />
                  </Tooltip>
                )}
                {maquina?.acesso?.includes("-q -D8282") && (
                  <Tooltip title="Proxy HTTP/HTTPS">
                    <ProxyIcon
                      sx={{ color: "iconsAcesso.proritario", fontSize: "32px" }}
                    />
                  </Tooltip>
                )}
                {maquina?.acesso2?.includes("-q -D8282") && (
                  <Tooltip title="Proxy HTTP/HTTPS">
                    <ProxyIcon
                      sx={{ color: "iconsAcesso.proritario", fontSize: "32px" }}
                    />
                  </Tooltip>
                )}
              </Box>
              <Tooltip
                title={"Opções"}
                id="basic-button"
                onClick={(event) => {
                  handleClick(event);
                  console.log(event.currentTarget);
                }}
              >
                <IconButton>
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setEditable(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Edit fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText>Editar</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenDeleteModal(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Delete fontSize="small" color="secondary" />
                  </ListItemIcon>
                  <ListItemText>Deletar</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={styles.boxForm}>
              <Box sx={styles.boxContainer}>
                <Campo title={"Tipo"} text={maquina.tipo} />
                <Campo title={"Ip"} text={maquina.ip} />
              </Box>
              <Box sx={styles.boxContainer}>
                <Campo title={"SSH port"} text={maquina.sshPort} />
                <Campo title={"User"} text={maquina.user} />
              </Box>
              <Box sx={styles.boxContainer}>
                <Campo title={"Senha"} text={maquina.senhaVm} />
                <Campo title={"Senha root"} text={maquina.senhaRoot} />
              </Box>

              {maquina.acesso2 ? (
                <Box sx={styles.boxContainer}>
                  <Campo title={"Acesso"} text={maquina.acesso} />
                  <Campo title={"Acesso 2"} text={maquina.acesso2} />
                </Box>
              ) : (
                <Box sx={{ ...styles.boxCampo, width: "100%" }}>
                  <Box sx={styles.titleCampo}>Acesso</Box>

                  <Tooltip title="Clique para copiar">
                    <Typography
                      onClick={() => {
                        handleCopy(maquina.acesso);
                      }}
                      sx={styles.textCampo}
                    >
                      {maquina.acesso}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {editable && (
          <>
            <Stack
              direction={"row"}
              alignItems="start"
              justifyContent={"space-between"}
              width={"100%"}
              position={"relative"}
            >
              <Stack direction={"column"}>
                <Box sx={styles.titleCampo}>{"Nome"}</Box>
                <Stack
                  direction={"row"}
                  flexWrap={{ xs: "wrap", smd: "nowrap" }}
                  gap={1}
                  alignItems={"center"}
                  mt={-2}
                >
                  <TextField
                    variant="standard"
                    size="small"
                    value={nomeDaMaquina}
                    onChange={(event) => {
                      setNomeDaMaquina(event.target.value);
                    }}
                  />
                  <Tooltip title="Atualização de risco">
                    <p>
                      <IconButton
                        onClick={() => {
                          setAtualizacaoDeRisco(!atualizacaoDeRisco);
                        }}
                      >
                        {atualizacaoDeRisco ? (
                          <UpdateDisabled sx={styles.updateDisabledIcon} />
                        ) : (
                          <UpdateDisabled
                            sx={{
                              fontSize: "28px",
                            }}
                            color="disabled"
                          />
                        )}
                      </IconButton>
                    </p>
                  </Tooltip>
                </Stack>
              </Stack>
              <Box>
                <Tooltip
                  title={"Salvar"}
                  onClick={() => {
                    setOpenEditModal(true);
                  }}
                >
                  <IconButton>
                    <Save color={"primary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={"Cancelar"}
                  onClick={() => {
                    resetCard();
                    setEditable(false);
                  }}
                >
                  <IconButton>
                    <Close color={"secondary"} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
            <Box sx={styles.boxForm} gap={1}>
              <Box sx={styles.boxContainer}>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>Tipo da máquina</Box>
                  <FormControl
                    sx={styles.TextField}
                    variant="standard"
                    style={{ width: "180px" }}
                    fullWidth
                    size="small"
                  >
                    <Select
                      value={tipo}
                      label="Tipo"
                      onChange={(event) => {
                        setTipo(event.target.value);
                      }}
                    >
                      <MenuItem value={"manager"}>Manager</MenuItem>
                      <MenuItem value={"database"}>Data Base</MenuItem>
                      <MenuItem value={"worker"}>Worker</MenuItem>
                      <MenuItem value={"proxyy"}>Proxy</MenuItem>
                      <MenuItem value={"outro"}>Outro</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>{"Ip"}</Box>

                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={ip}
                    onChange={(event) => {
                      setIp(event.target.value);
                    }}
                  />
                </Box>
              </Box>

              <Box sx={styles.boxContainer}>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>{"SSH port"}</Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={sshPort}
                    onChange={(event) => {
                      setSshPort(event.target.value);
                    }}
                  />
                </Box>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>{"User"}</Box>

                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={user}
                    onChange={(event) => {
                      setUser(event.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box sx={styles.boxContainer}>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>{"Senha"}</Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={senhaVm}
                    onChange={(event) => {
                      setSenhaVM(event.target.value);
                    }}
                  />
                </Box>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>{"Senha root"}</Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={senhaRoot}
                    onChange={(event) => {
                      setSenhaRoot(event.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box sx={styles.boxContainer}>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>{"Acesso"}</Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={acesso}
                    onChange={(event) => {
                      setAcesso(event.target.value);
                    }}
                  />
                </Box>
                <Box sx={styles.boxCampo}>
                  <Box sx={styles.titleCampo}>{"Acesso 2"}</Box>
                  <TextField
                    size="small"
                    fullWidth
                    variant="standard"
                    value={acesso2}
                    onChange={(event) => {
                      setAcesso2(event.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </CardContent>
      <Dialog
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salva os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button autoFocus onClick={handleApiUpdate} disabled={disableButton}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
      >
        <DialogTitle sx={styles.dialogText}>
          Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
          esses dados?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={styles.boxDialogs}>
              <Dangerous color={"error"} sx={styles.dangerousIcon} />
            </Box>
            <span style={styles.dialogText}>
              Uma vez após excluidos os dados não podem ser recuperados. Tem
              certeza que de deseja fazer essa exclusão ?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            color={"error"}
            sx={styles.deleButton}
            onClick={handleApiDeleteMaquina}
            disabled={disableButtonDelete}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Card>
  );
}
