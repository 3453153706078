import { useState, useEffect } from "react";
import {
  AppBar as MuiAppBar,
  Avatar,
  Box,
  Container,
  Icon,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Badge,
  useMediaQuery,
  useTheme,
  CssBaseline,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import mwIcon from "../../../assets/logo.png";
import MenuLayout from "./menu";
import { Css, NotificationsNoneOutlined } from "@mui/icons-material";
import api from "../../../services/api";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import Notificacoes from "./notificacoes";
import ModalPerfil from "../../perfil";

export default function AppBar({
  colorMode,
  setColorMode,
  socket,
  handleDrawerToggle,
  handleRightBarToggle,
  setOpenDrawer = () => {},
}) {
  const theme = useTheme();

  const [pageValue, setPageValeu] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [baseImage, setBaseImage] = useState("");
  const [administrador, setAdministrador] = useState(false);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [notificacoes, setNotificacoes] = useState([]);
  const [snackbar, setSnackbar] = useState({});
  const [countNotificacoes, setCountNotificacoes] = useState(0);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const navigate = useNavigate();
  const isScreenSmall = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (isScreenSmall) {
      setOpenDrawer(false); // Fecha a sidebar quando a tela é menor que o valor definido no breakpoint 'lg'
    }
  }, [isScreenSmall]);

  useEffect(() => {
    if (!notificacoes.length > 0) {
      handleApiGetNotificacoes();
    }
  }, []);

  async function handleApiGetNotificacoes() {
    try {
      setLoadingNotifications(true);
      const response = await api.post("/notificacoes/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar notificacoes",
          severity: "error",
        });
        setLoadingNotifications(false);
      } else {
        setLoadingNotifications(false);
        if (response.data) {
          handleNotificacoes(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingNotifications(false);
    }
  }

  useEffect(() => {
    const handleUsuarioId = () => {
      let result = localStorage.getItem("2");
      result = JSON.parse(result);
      if (
        result.codigoPermissao &&
        (result.codigoPermissao.indexOf("adm001") !== -1 ||
          result.codigoPermissao.indexOf("adm002") !== -1)
      ) {
        setAdministrador(true);
      }
      if (result) {
        setBaseImage(result.foto);
      } else {
        return "";
      }
    };
    handleUsuarioId();
  });

  useEffect(() => {
    const pageName = window.location.pathname;
    const pages = ["/noc", "/dev", "/iot", "/vendar", "/financeiro"];
    pages.map((page, index) => {
      if (pageName.includes(page)) {
        setPageValeu(index);
      }
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }

  function handleNovaNotificacao(novaDemanda) {
    let result = {};
    let usuarioId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioId = result.refreshToken.usuarioId;
    }
    if (novaDemanda.usuarioId !== usuarioId) {
      setCountNotificacoes(countNotificacoes + 1);
      let novoArray = notificacoes;
      let objDemanda = handleConteudoNotificacao(novaDemanda.conteudo);
      objDemanda.tipo = novaDemanda.tipo;
      objDemanda.acao = novaDemanda.acao;
      objDemanda.visto = false;
      objDemanda.notificacaoId = novaDemanda.id;
      objDemanda.usuario = novaDemanda.usuario
        ? novaDemanda.usuario.Perfil
        : "";
      objDemanda.usuarioCliente = novaDemanda.usuarioCliente
        ? novaDemanda.usuarioCliente.PerfilCliente
        : "";
      novoArray = [objDemanda, ...novoArray];
      setNotificacoes(novoArray);
    }
  }
  function updateVistoNotificacao(visto, notificacaoId) {
    const novoArray = notificacoes.map((notificacao) => {
      if (notificacao.notificacaoId === notificacaoId) {
        let auxNotificao = notificacao;
        auxNotificao.visto = visto;
        return { ...auxNotificao };
      } else {
        return notificacao;
      }
    });
    if (countNotificacoes > 0) {
      setCountNotificacoes(countNotificacoes - 1);
    } else {
      setCountNotificacoes(0);
    }
    setNotificacoes(novoArray);
  }
  function updateManyVistoNotificacao(visto) {
    const novoArray = notificacoes.map((notificacao) => {
      let auxNotificao = notificacao;
      auxNotificao.visto = visto;
      return { ...auxNotificao };
    });
    setCountNotificacoes(0);
    setNotificacoes(novoArray);
  }
  function handleConteudoNotificacao(demanda) {
    let newObj = {};
    if (newObj) {
      newObj.id = demanda.id;
      /**newObj.usuario = demanda.usuario ? demanda.usuario.Perfil : "";
      newObj.usuarioCliente = demanda.usuarioCliente
        ? demanda.usuarioCliente.PerfilCliente
        : "";*/
      newObj.descricao =
        demanda.descricao ||
        demanda.relatoriosCliente?.descricao ||
        demanda.topicoRelatorio?.relatoriosCliente?.descricao;

      newObj.relatoriosClienteId =
        demanda.topicoRelatorio?.relatoriosCliente?.id ||
        demanda.relatoriosCliente?.id ||
        demanda.id;

      newObj.fixar = demanda?.fixar ? demanda.fixar : false;
      newObj.status = demanda.status;

      if (demanda.updateAt) {
        newObj.tempo = handleTime(demanda.updateAt);
      } else {
        newObj.tempo = handleTime(demanda.createAt);
      }
      newObj.atualizada = demanda.updateAt ? true : false;
      return newObj;
    }
  }
  function handleNotificacoes(res) {
    let result = {};
    let usuarioId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioId = result.refreshToken.usuarioId;
    }
    let auxCount = 0;
    //setCountNotificacoes(response.data.length);
    let novoArray = [];
    res.map((statusNotificacao) => {
      if (statusNotificacao.notificacao.usuarioId !== usuarioId) {
        let objDemanda = handleConteudoNotificacao(
          statusNotificacao.notificacao.conteudo
        );
        objDemanda.tipo = statusNotificacao.notificacao.tipo;
        objDemanda.acao = statusNotificacao.notificacao.acao;
        objDemanda.visto = statusNotificacao.visto;
        objDemanda.notificacaoId = statusNotificacao.notificacao.id;
        objDemanda.usuario = statusNotificacao.notificacao.usuario
          ? statusNotificacao.notificacao.usuario.Perfil
          : "";
        objDemanda.usuarioCliente = statusNotificacao.notificacao.usuarioCliente
          ? statusNotificacao.notificacao.usuarioCliente.PerfilCliente
          : "";
        novoArray.push(objDemanda);
        if (!statusNotificacao.visto) {
          auxCount++;
        }
      }
    });
    setCountNotificacoes(auxCount);
    setNotificacoes(novoArray);
  }

  return (
    <>
      <MuiAppBar sx={styles.appBar}>
        <Container maxWidth="100vw" sx={styles.container}>
          <Toolbar sx={styles.toolbar} disableGutters>
            <Box sx={styles.boxMenuAndLogo}>
              <IconButton
                sx={styles.buttonMenuDawer}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                sx={styles.buttonMenuRightBar}
                onClick={handleRightBarToggle}
              >
                <MenuIcon />
              </IconButton>
              <IconButton href={"/noc/home"} sx={styles.iconButtonLogo}>
                <Icon sx={styles.icon}>
                  <img src={mwIcon} style={styles.imgIcon} />
                </Icon>
              </IconButton>
            </Box>

            <Box sx={styles.boxTabs}>
              <Box>
                <Tabs
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  onChange={(event, newValue) => {
                    setPageValeu(newValue);
                  }}
                  value={pageValue}
                >
                  <Tab
                    label="NOC"
                    value={0}
                    onClick={() => {
                      navigate("/noc/home");
                    }}
                  />
                  <Tab
                    label="DEV"
                    value={1}
                    onClick={() => {
                      navigate("/dev");
                    }}
                  />
                  <Tab
                    label="CS"
                    value={3}
                    onClick={() => {
                      navigate("/cs");
                    }}
                  />
                </Tabs>
              </Box>
            </Box>

            <Box sx={styles.notificationsAndMenu}>
              <Tooltip title="Notificações">
                <IconButton
                  color="inherit"
                  aria-label="notificacoes"
                  onClick={(event) => {
                    setAnchorNotification(event.currentTarget);
                  }}
                >
                  <Badge color="secondary" badgeContent={countNotificacoes}>
                    <NotificationsNoneOutlined />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title="Abrir Opções">
                <IconButton onClick={handleClick}>
                  <Avatar src={baseImage} />
                </IconButton>
              </Tooltip>

              <MenuLayout
                socket={socket}
                colorMode={colorMode}
                setColorMode={setColorMode}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setOpenModal={setOpenModal}
                administrador={administrador}
              />
            </Box>
          </Toolbar>
        </Container>
      </MuiAppBar>
      <ModalPerfil setOpenModal={setOpenModal} openModal={openModal} />
      {socket && (
        <Notificacoes
          notificacoes={notificacoes}
          handleNovaNotificacao={handleNovaNotificacao}
          anchorNotification={anchorNotification}
          setAnchorNotification={setAnchorNotification}
          loadingNotification={loadingNotifications}
          updateVistoNotificacao={updateVistoNotificacao}
          updateManyVistoNotificacao={updateManyVistoNotificacao}
          socket={socket}
        />
      )}
    </>
  );
}
