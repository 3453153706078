const styles = {
  appBar: {
    display: "relative",
    position: "fixed",
    maxWidth: "100vw",
  },
  container: {
    padding: 0,
    "&.MuiContainer-root": { paddingX: 0 },
    maxWidth: "100vw",
  },
  toolbar: {
    width: "100",
    padding: 0,
    display: "flex",

    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxTabs: {
    width: "25%",
    alignSelf: "center",
    justifyContent: "center",
    display: { xs: "none", smd: "flex" },
  },
  boxMenuAndLogo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingX: 1,
    width: "270px",
  },
  iconButtonLogo: {
    flexDirection: "row",
    alignItems: "center",
    marginX: 1,
    padding: 0,
    borderRadius: 1,
  },
  icon: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  imgIcon: {
    width: 170,
    height: "auto",
  },
  buttonMenuDawer: {
    padding: 1,
    color: "white",
    display: { xs: "flex", lg: "none" },
  },
  buttonMenuRightBar: {
    padding: 1,
    color: "white",
    display: { xs: "none", lg: "flex" },
  },
  notificationsAndMenu: {
    mr: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 62,
  },
};
export default styles;
