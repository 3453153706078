import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Avatar,
  Dialog,
  Badge,
  DialogTitle,
  DialogActions,
  Modal,
  Divider,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import {
  Close,
  Person,
  AddAPhoto,
  Code,
  MemoryOutlined,
  AttachMoneyOutlined,
  SellOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../services/api";
import Resizer from "react-image-file-resizer";

const styles = {
  ContainerBox: {
    width: "100%",
    alignItems: "flex-start",
    paddingTop: "100px",
    display: "flex",
    justifyContent: "center",
    minHeight: "60vw",
  },
  Paper: {
    padding: "10px",
    width: "530px",
    height: "auto",
    justifyContent: "flex-start",
    alignItems: " center",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    borderRadius: 3,
    bgcolor: "background.paperOutlined",
  },
  PaperSecondary: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    paddingTop: "10px",
    borderRadius: 3,
    bgcolor: "background.default",
  },
  Title: {
    fontFamily: "Roboto",
    fontWeight: "400",
    letterSpacing: "0.8px",
    marginLeft: "40px",
  },
  TextCampo: {
    fontSize: "16px",
    fontWeight: "600",
  },
  TextField: {
    fontSize: "16px",
    fontWeight: "600",
    margin: "10px",
  },
  TypographyCampo: {
    fontSize: "14px",
    fontWeight: "400",
  },
  TypographyCampoSetor: {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize",
  },
  StyleButtons: {
    margin: "5px",
    marginTop: "-5px",
  },
  StackFoto: {
    alignItems: "center",
    height: "220px",
    position: "absolute",
    marginTop: "50px",
    marginRight: "275px",
  },
  ButtonFoto: {
    width: "130px",
    position: "absolute",
    display: " flex",
    padding: "8px 22px",
    alignItems: "center",
    margin: "100px",
    gap: "8px",
    fontSize: "13px",
  },

  BoxAvatar: {
    width: "126px",
    height: "126px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    bgcolor: "background.paperOutlined",
  },
  Avatar: {
    width: "110px",
    height: "110px",
  },
};

export default function Perfil(props) {
  const { openModal, setOpenModal } = props;
  const [email, setEmail] = useState("");
  const [alertSize, setAlertSize] = useState(false);
  const [editar, setEditar] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [dialogSalvar, setDialogSalvar] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [foto, setFoto] = useState("");
  const [setor, setSetor] = useState("");
  const [usuarioId, setUsuarioId] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(dayjs(new Date()));
  const [checkData, setCheckData] = useState(false);

  async function handleApi() {
    try {
      const response = await api.put("/updatePerfil", {
        nome,
        sobrenome,
        email,
        dataDeNascimento,
        foto,
        status: true,
        setor,
        usuarioId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
        localStorage.setItem("2", JSON.stringify(response.data.perfil));
        handleUsuarioId();
      }
    } catch (error) {
      console.error();
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  useEffect(() => {
    if (!openModal) {
      setEditar(false);
    }
    handleUsuarioId();
  }, [openModal, editar]);

  const handleUsuarioId = () => {
    let result = localStorage.getItem("2");

    if (result !== "undefined") {
      result = JSON.parse(result);
      setNome(result.nome);
      setSobrenome(result.sobrenome);
      setDataDeNascimento(dayjs(new Date(result.dataDeNascimento)));
      setFoto(result.foto);
      setEmail(result.email);
      setSetor(result.setor);
      setUsuarioId(result.usuarioId);
    }
  };
  return (
    <>
      <Modal
        open={openModal}
        closeAfterTransition
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Box sx={styles.ContainerBox}>
          <Paper variant="outlined" sx={styles.Paper}>
            <Stack sx={styles.StackFoto}>
              <Box sx={styles.BoxAvatar}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    editar ? (
                      <Tooltip title={"adicionar imagem"}>
                        <IconButton
                          //variant="outlined"
                          component="label"
                          color="primary"
                          sx={{
                            color: "#FEFEFE",
                            bgcolor: "primary.main",
                            "&:hover": {
                              backgroundColor: "#462865",
                            },
                          }}
                        >
                          <AddAPhoto />{" "}
                          <input
                            hidden
                            accept=".png, .jpg, .jpeg"
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file.size < 1024 * 300) {
                                Resizer.imageFileResizer(
                                  file,
                                  500,
                                  500,
                                  "JPEG",
                                  80,
                                  0,
                                  (uri) => {
                                    setFoto(uri);
                                  },
                                  "base64",
                                  500,
                                  500
                                );
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(file);
                                fileReader.onload = () => { };
                                fileReader.onerror = (error) => {
                                  console.log("Error:", error);
                                };
                              } else {
                                setAlertSize(true);
                              }
                            }}
                          />{" "}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={"setor: " + setor.toLowerCase()}>
                        <Box
                          variant="contained"
                          component="label"
                          sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            color: "#FEFEFE",
                            bgcolor: "primary.main",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {setor === "NOC" ||
                            setor === "NOC-EASYFLOW" ||
                            setor === "NOC-EASYMON" ? (
                            <SupportAgentOutlined />
                          ) : setor === "IOT" ? (
                            <MemoryOutlined />
                          ) : setor === "DEV" ? (
                            <Code />
                          ) : setor === "FINANCEIRO" ? (
                            <AttachMoneyOutlined />
                          ) : setor === "VENDARKETING" ? (
                            <SellOutlined />
                          ) : (
                            <Person />
                          )}
                        </Box>
                      </Tooltip>
                    )
                  }
                >
                  <Avatar src={foto} color="secondary" sx={styles.Avatar} />
                </Badge>
              </Box>
            </Stack>
            <Stack
              sx={{
                width: "500px",
                alignItems: "flex-end",
              }}
            >
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                <Close />
              </IconButton>
            </Stack>
            <Stack direction={"row"}>
              <Typography variant="h4" color="primary" sx={styles.Title}>
                Meu Perfil
              </Typography>
            </Stack>
            <Paper elevation={0} sx={styles.PaperSecondary}>
              {" "}
              <Stack
                direction={"row"}
                sx={{
                  width: "500px",
                  justifyContent: "flex-end",
                }}
              >
                {editar ? (
                  <>
                    <Button
                      variant="text"
                      color="secondary"
                      sx={styles.StyleButtons}
                      onClick={(e) => {
                        setEditar(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      sx={styles.StyleButtons}
                      onClick={(e) => {
                        setDialogSalvar(true);
                      }}
                    >
                      Salvar
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    sx={styles.StyleButtons}
                    onClick={(e) => {
                      setEditar(true);
                    }}
                  >
                    Editar
                  </Button>
                )}{" "}
              </Stack>
              <Stack
                direction={"column"}
                padding={"10px"}
                gap={"30px"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                sx={{
                  marginTop: "20px",
                }}
              >
                <Stack direction={"row"} spacing={"10px"}>
                  {" "}
                  <Stack
                    direction={"column"}
                    width={"220px"}
                    spacing={"10px"}
                    justifyContent={"center"}
                  // alignItems={"center"}
                  >
                    {" "}
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={styles.TextCampo}
                    >
                      NOME
                    </Typography>
                    {!editar ? (
                      <Typography variant="h5" sx={styles.TypographyCampo}>
                        {nome}
                      </Typography>
                    ) : (
                      <TextField
                        id="nome"
                        variant="standard"
                        placeholder="Insira seu nome"
                        value={nome}
                        sx={styles.TextField}
                        onChange={(event) => {
                          setNome(event.target.value);
                        }}
                      />
                    )}
                  </Stack>
                  <Stack direction={"column"} width={"220px"} spacing={"10px"}>
                    {" "}
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={styles.TextCampo}
                    >
                      SOBRENOME
                    </Typography>
                    {!editar ? (
                      <Typography variant="h5" sx={styles.TypographyCampo}>
                        {sobrenome}
                      </Typography>
                    ) : (
                      <TextField
                        variant="standard"
                        id="sobrenome"
                        placeholder="Insira seu sobrenome"
                        value={sobrenome}
                        sx={styles.TextField}
                        onChange={(event) => {
                          setSobrenome(event.target.value);
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
                <Divider orientation="horizontal" sx={{ width: "450px" }} />
                <Stack direction={"row"} spacing={"10px"}>
                  {" "}
                  <Stack direction={"column"} width={"220px"} spacing={"10px"}>
                    {" "}
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={styles.TextCampo}
                    >
                      SETOR
                    </Typography>
                    {!editar ? (
                      <Typography variant="h5" sx={styles.TypographyCampoSetor}>
                        {setor}
                      </Typography>
                    ) : (
                      <FormControl variant="standard" sx={styles.TextField}>
                        <Select
                          labelId="demo-simple-select-label"
                          value={setor}
                          onChange={(event) => {
                            setSetor(event.target.value);
                          }}
                          name="setor"
                          color="primary"
                          slotProps={{ "aria-label": "Without label" }}
                        //inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={"NOC"}>NOC</MenuItem>
                          <MenuItem value={"NOC-EASYFLOW"}>
                            NOC EASYFLOW
                          </MenuItem>
                          <MenuItem value={"NOC-EASYMON"}>NOC EASYMON</MenuItem>
                          <MenuItem value={"DEV"}>DEV</MenuItem>
                          <MenuItem value={"IOT"}>IOT</MenuItem>
                          <MenuItem value={"FINANCEIRO"}>FINANCEIRO</MenuItem>
                          <MenuItem value={"VENDARKETING"}>
                            VENDARKETING
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Stack>{" "}
                  <Stack direction={"column"} width={"220px"} spacing={"10px"}>
                    {" "}
                    <Typography
                      variant="h5"
                      color="primary"
                      sx={styles.TextCampo}
                    >
                      DATA DE NASCIMENTO
                    </Typography>
                    {!editar ? (
                      <Typography variant="h5" sx={styles.TypographyCampo}>
                        {new Date(dataDeNascimento).toLocaleDateString()}
                      </Typography>
                    ) : (

                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"pt-br"}
                      >
                        <DatePicker
                          id="dataDeNascimento"
                          error={checkData}
                          helperText={checkData && "Data inválida."}
                          sx={styles.TextField}
                          color="primary"
                          value={dataDeNascimento}
                          onChange={(newValeu) => {
                            const dataAtual = dayjs();
                            setDataDeNascimento(newValeu);
                            if (
                              newValeu &&
                              newValeu.isAfter(dataAtual, "day")
                            ) {
                              // Verifica se a newValeu é maior (no futuro) do que a dataAtual.
                              setCheckData(true); // Define o estado hasError como true se houver erro.
                            } else {
                              setCheckData(false); // Define o estado hasError como false se não houver erro.
                            }
                          }}
                          slotProps={{
                            textField: {
                              variant: 'standard',
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  </Stack>
                </Stack>{" "}
                <Divider orientation="horizontal" sx={{ width: "450px" }} />
                <Stack direction={"row"} spacing={"10px"}>
                  {" "}
                  <Tooltip title={editar && "Não é possível editar o email"}>
                    <Stack
                      direction={"column"}
                      width={"220px"}
                      spacing={"10px"}
                    >
                      {" "}
                      <Typography
                        variant="h5"
                        color="primary"
                        sx={styles.TextCampo}
                      >
                        EMAIL
                      </Typography>
                      {!editar ? (
                        <Typography variant="h5" sx={styles.TypographyCampo}>
                          {email}
                        </Typography>
                      ) : (
                        <TextField
                          variant="standard"
                          id="telefone"
                          placeholder="Insira o telefone"
                          value={email}
                          disabled
                          sx={styles.TextField}
                          onChange={(event) => { }}
                        />
                        //{" "}
                      )}
                    </Stack>
                  </Tooltip>
                </Stack>
              </Stack>
            </Paper>
          </Paper>
        </Box>
      </Modal>
      <Dialog
        open={dialogSalvar}
        onClose={() => {
          //setOpenEditModal(false);
        }}
        sx={{ borderRadius: 3 }}
      >
        <DialogTitle>
          {"Deseja realmente salvar os dados editados?"}
        </DialogTitle>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              // setOpenEditModal(false);
              setDialogSalvar(false);
              //setEditar("");
            }}
          >
            Cancelar
          </Button>

          <Button
            onClick={() => {
              setDialogSalvar(false);
              handleApi();
              setEditar(false);
            }}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        autoHideDuration={3000}
        open={alertSize}
        onClose={() => {
          setAlertSize(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Tamanho máximo permitido: 300KB</Alert>
      </Snackbar>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
