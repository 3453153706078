import { Box, Fade, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
const stylesTable = {
  paperParent: {
    display: "flex",
    width: "100%",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    overflow: "auto",
  },
};
export default function TableJson(params) {
  const { rows, titulo, loadingPage } = params;
  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    { field: "{#SERVICE}", headerName: "SERVICE", width: 160 },
    { field: "{#URL}", headerName: "URL", flex: 1, minWidth: 220 },
    {
      field: "{#TIPO}",
      headerName: "TIPO",
      width: 90,
      renderCell: (params) => {
        const tipo = params.row["{#TIPO}"] === 0 ? "Serviço" : "Jogo";
        return <div>{tipo}</div>;
      },
    },
  ];
  return (
    <Box sx={stylesTable.paperParent}>
      {loadingPage ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          <Stack direction={"row"} gap="10px">
            <Skeleton variant="rounded" width={"20%"} height={"30vh"} />{" "}
            <Skeleton variant="rounded" width={"65%"} height={"30vh"} />{" "}
            <Skeleton variant="rounded" width={"15%"} height={"30vh"} />{" "}
          </Stack>
        </>
      ) : (
        <Fade in={loadingPage} {...(loadingPage ? { timeout: 500 } : {})}>
          <>
            <Typography
              sx={{
                width: "83%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="h5"
            >
              {titulo}
            </Typography>
            <DataGrid
              sx={{
                display: "grid",

                fontSize: 15,
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                ".MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center",
                },
              }}
              autoHeight={true}
              // localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={rows}
              columns={columns}
              pageSizeOptions={[10, 50, 100]}
              initialState={{
                ...rows.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              disableColumnMenu
              disableRowSelectionOnClick={true}
            />
          </>
        </Fade>
      )}
    </Box>
  );
}
