import {
  Typography,
  Checkbox,
  List,
  ListItemButton,
  ListItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
export default function Lista({
  filterList,
  typeData,
  rowSelected,
  updateListChecked,
  listRowscheckeds = [],
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const headlerClick = (row, index) => {
    row["merge"] = [];
    rowSelected(row);
    setSelectedIndex(index);
  };
  useEffect(() => {
    if (filterList[selectedIndex]?.status === 0) {
      rowSelected(null);
      return;
    }
    rowSelected(filterList[selectedIndex]);
  }, [filterList]);

  return (
    <List
      sx={{
        mt: 1.5,

        width: "100%",
        overflow: "auto",
        height: typeData == "Hosts" ? 240 : 300,
      }}
    >
      {filterList.map((row, i) => {
        return (
          <ListItem key={row.nome} disablePadding>
            {(row.status == 0 || row.status == 2) && <Checkbox disabled />}
            {(row.status == 1 || row.status == 3) && (
              <Checkbox
                checked={listRowscheckeds.some((check) =>
                  typeData !== "Hosts"
                    ? row.idDefault === check.idDefault
                    : row.hostId === check.hostId
                )}
                onChange={() => updateListChecked(row)}
                inpuslotProps={{ "aria-label": "controlled" }}
              />
            )}

            <ListItemButton
              onClick={row.status == 0 ? () => { } : () => headlerClick(row, i)}
              selected={row.status == 0 ? false : selectedIndex === i}
            >
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "25px",
                  width: "95%",
                  textAlign: "left",
                }}
              >
                {row.nome}
              </Typography>
              <Tooltip
                title={
                  typeData == "Hosts"
                    ? row.alertErro != undefined
                      ? row.alertErro
                      : row.status == 0
                        ? "Host está atualizado"
                        : row.status == 1
                          ? row.templates.some((temp) => temp.status == 3)
                            ? "Host passível de atualização (Templates faltando)"
                            : "Host passível de atualização (Templates desatualizados)"
                          : row.templates.some((temp) => temp.status == 4)
                            ? "Host não pode ser atualizado (Itens ou macros divergentes)"
                            : "Host não pode ser atualizado (Templates não constam na predefinição)"
                    : typeData == "Templates"
                      ? row.status == 0
                        ? "Template já atualizado"
                        : row.status == 1
                          ? "Template desatualizado"
                          : row.status == 3
                            ? "Template não consta no cliente"
                            : "Template com divergências"
                      : ""
                }
              >
                {(row.status == 1 || row.status == 3) &&
                  typeData == "Hosts" &&
                  row.templates.some((temp) => temp.status == 3) ? (
                  <ReportProblemIcon
                    sx={{
                      position: "absolute",
                      right: 15,
                      color: "chip.inProgress",
                    }}
                  />
                ) : (
                  <ErrorIcon
                    sx={{
                      position: "absolute",
                      right: 15,
                      color:
                        typeData == "Hosts"
                          ? row.alertErro != undefined
                            ? "chip.pending"
                            : row.status == 0
                              ? "chip.completed"
                              : row.status == 1
                                ? "chip.waitingReview"
                                : "chip.inProgress"
                          : typeData == "Templates"
                            ? row.status == 0
                              ? "chip.completed"
                              : row.status == 1
                                ? "chip.waitingReview"
                                : row.status == 2
                                  ? "chip.pending"
                                  : "chip.waitingReview"
                            : "",
                    }}
                  />
                )}
              </Tooltip>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>

    // <Tabs
    //   orientation="vertical"
    //   variant="scrollable"
    //   value={selectedIndex}
    //   onChange={headlerClick}
    //   sx={{ borderRight: 0, borderColor: "divider" }}
    // >
    //   {filterList.map((row, i) => {
    //     return (
    //       <Tab
    //         key={row.nome + i}
    //         label={
    //           <>
    //             <Typography
    //               sx={{
    //                 overflow: "hidden",
    //                 textOverflow: "ellipsis",
    //                 whiteSpace: "nowrap",
    //                 paddingRight: "25px",
    //                 width: "95%",
    //                 textAlign: "left",
    //               }}
    //             >
    //               {row.nome}
    //             </Typography>
    //             <Tooltip
    //               title={
    //                 typeData == "Hosts"
    //                   ? row.status == 0 && row.error.length == 0
    //                     ? "Host já está atualizado"
    //                     : row.status == 1 || row.status == 0
    //                     ? "Erro : Não foi possível atualizar o Host "
    //                     : "Host foi atualizado"
    //                   : typeData == "Templates"
    //                   ? row.status == 0
    //                     ? "Template já atualizado"
    //                     : row.status == 1
    //                     ? "Template desatualizado"
    //                     : row.status == 2
    //                     ? "Template não consta no Default"
    //                     : "Template não consta no Cliente"
    //                   : ""
    //               }
    //             >
    //               <ErrorIcon
    //                 sx={{
    //                   position: "absolute",
    //                   right: 15,
    //                   color:
    //                     typeData == "Hosts"
    //                       ? row.status == 0 && row.error.length == 0
    //                         ? "chip.completed"
    //                         : row.status == 1 || row.status == 0
    //                         ? "secondary.main"
    //                         : "chip.waitingReview"
    //                       : typeData == "Templates"
    //                       ? row.status == 0
    //                         ? "chip.completed"
    //                         : row.status == 1
    //                         ? "chip.waitingReview"
    //                         : row.status == 2
    //                         ? "chip.pending"
    //                         : "chip.waitingReview"
    //                       : "",
    //                 }}
    //               />
    //             </Tooltip>
    //           </>
    //         }
    //         sx={{
    //           justifyContent: "start",
    //           minHeight: "45px",
    //           maxWidth: "none",
    //         }}
    //       />
    //     );
    //   })}
    // </Tabs>
  );
}
