export function validateIp(ip, type = "all", withMask = true, withPort = true) {
  // const ipv6Regex =/^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?$/i;
  const ipv6Regex = /^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$|^(?:[A-F0-9]{1,4}:){1,7}:$|^:(?::[A-F0-9]{1,4}){1,7}$/i;
  const ipv6RegexWithMask =
    /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?\/(12[0-8]|1[01][0-9]|[1-9]?[0-9])$/i;
  const ipv6RegexWithPort =
    /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?:\d{1,5}$/i;
  //const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/;
  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const ipv4RegexWithMask =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[1-2]?[0-9])$/;
  const ipv4RegexWithPort =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(\d{1,5})$/;

  const validateIpv4 = (ip, withMask, withPort) => {
    if (
      ipv4Regex.test(ip) ||
      (ipv4RegexWithMask.test(ip) && withMask) ||
      (ipv4RegexWithPort.test(ip) && withPort)
    )
      return true;
    return false;
  };

  const validateIpv6 = (ip, withMask, withPort) => {
    if (
      ipv6Regex.test(ip) ||
      (ipv6RegexWithMask.test(ip) && withMask) ||
      (ipv6RegexWithPort.test(ip) && withPort)
    )
      return true;
    return false;
  };

  switch (type) {
    case "ipv6":
      return validateIpv6(ip, withMask, withPort);
    case "ipv4":
      return validateIpv4(ip, withMask, withPort);
    default:
      return (
        validateIpv4(ip, withMask, withPort) ||
        validateIpv6(ip, withMask, withPort)
      );
  }
}