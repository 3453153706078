const useStyles = ({ theme, openRightBar = false }) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    minHeight: "90vh",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: { xs: "0px 0px", sm: "30px 50px" },
    width: "100%",
  },
  paper: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: {
      xs: "calc(100vw - 56px)",
      sm: "calc(100vw - 160px)",
      lg: openRightBar ? "calc(100vw - 430px )" : "calc(100vw - 170px)",
    },

    alignItems: "start",
  },
  tabs: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  LoadingLogo: {
    position: "relative",
    marginLeft: "45%",
    marginTop: "10%",
  },
  CircularProgress: {
    color: "primary",
    position: "absolute",
    top: -9,
    left: -6,
    zIndex: 1,
  },
});

export default useStyles;
