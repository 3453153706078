import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import zabbix from "../../../assets/nocImg/zabbix_logo.png";
import grafana from "../../../assets/nocImg/grafana_logo.png";
import monday from "../../../assets/nocImg/monday.png";
import sheets from "../../../assets/nocImg/sheets.png";

import drive from "../../../assets/nocImg/drive.png";

function MyCard(props) {
  const {
    label,
    link = "",
    img,
    onClick = () => { },
    target = "_blank",
  } = props;
  return (
    <Card sx={{ borderRadius: 4 }}>
      <CardActionArea
        sx={{ padding: "10px" }}
        href={link}
        target={target}
        onClick={() => {
          onClick();
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"10px"}
          height={"80px"}
          width={"250px"}
        >
          <Box>
            <Typography
              component="div"
              variant="h6"
              textAlign={"center"}
              justifyContent={"center"}
              minWidth={"160px"}
            >
              {label}
            </Typography>
          </Box>
          <CardMedia
            component={"img"}
            height="194"
            alt="icon"
            image={img}
            sx={{ width: "80px", height: "auto", borderRadius: 2 }}
          />
        </Stack>
      </CardActionArea>
    </Card>
  );
}

const styles = {
  cardGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "20px",
  },
};

export default function NocLinks() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        alignItems: "flex-start",
        padding: "20px 50px",
      }}
    >
      <Typography component="div" variant="h5">
        Clientes
      </Typography>

      <Box sx={styles.cardGroup}>
        <MyCard
          label="Acessos"
          img={sheets}
          link={
            "https://docs.google.com/spreadsheets/d/1mqsihyl9fOb1CnEi8Bfr5kBZaEYPI6e2R1JLWgectJo/edit"
          }
        // onClick={() => {          navigate("/noc/acessos");         }}
        />
        <MyCard
          label="Área do cliente"
          img={sheets}
          link={
            "https://docs.google.com/spreadsheets/d/1LE3VbSbKURbmLjPSwF1Uh55gsAwZp77ATLtnspQyT2k/edit#gid=0"
          }
        />
        <MyCard
          label="Relatórios"
          img={sheets}
          link={
            "https://docs.google.com/spreadsheets/d/10cyYEucun7yltpLbhML9Ns5ds_sAHyApJM1kOAHqhh4/edit"
          }
        />
        <MyCard
          label="Canais de alerta"
          img={sheets}
          link={
            "https://docs.google.com/spreadsheets/d/11E2_EJRg0_ZhZDmjsOFyfK55JUAzQey4twmFpwtf1c8/edit#gid=0"
          }
        />
      </Box>
      <Typography component="div" variant="h5">
        Zabbix
      </Typography>
      <Box sx={styles.cardGroup}>
        <MyCard
          label="Zabbix-Default"
          img={zabbix}
          link={"https://default.mw-solucoes.com"}
        />
        <MyCard
          label="Zabbix 4.4"
          img={zabbix}
          link={
            "http://45.88.189.206/index.php?request=zabbix.php%3Faction%3Ddashboard.view"
          }
        />
        <MyCard
          label="Zabbix 5.2"
          img={zabbix}
          link={
            "http://mcn04.mw-solucoes.com/index.php?request=zabbix.php%3Faction%3Ddashboard.view "
          }
        />
        <MyCard
          label="Zabbix 5.4"
          img={zabbix}
          link={
            "http://80.241.211.34/index.php?request=zabbix.php%3Faction%3Ddashboard.view"
          }
        />
      </Box>

      <Typography component="div" variant="h5">
        Grafana
      </Typography>
      <Box sx={styles.cardGroup}>
        <MyCard
          label="Grafana 7.5.16"
          img={grafana}
          link={"http://45.88.189.206:3000/d/4uHdLoyWk/home?orgId=1&refresh=1m"}
        />
        <MyCard
          label="Grafana 8.4.7"
          img={grafana}
          link={"http://80.241.211.34:3000/login"}
        />
        <MyCard
          label="Versionamento"
          img={grafana}
          link={
            "https://grafana.mw-solucoes.com/d/123452344123adsazsdczs1123121212/0-dev-repositorios-versionamento?orgId=1&var-buscadorScript=&var-buscadorTemplate=&var-buscadorDashboard="
          }
        />
      </Box>
      <Typography component="div" variant="h5">
        Execução
      </Typography>
      <Box sx={styles.cardGroup}>
        <MyCard
          label="Central de instruções"
          img={sheets}
          link={
            "https://docs.google.com/spreadsheets/d/1pkW1uH0bfR9hHzWIS3zpVHLOv9MZoi72OGeCI7uJpuo/edit#gid=0"
          }
        />
        <MyCard
          label="Monday"
          img={monday}
          link={
            "https://mw-solucoes.monday.com/auth/login_monday/email_password"
          }
        />
        <MyCard
          label="Localizador"
          img={sheets}
          link={
            "https://docs.google.com/spreadsheets/d/1cwhHCjcph-rcypriYAu9qOJj8TCxdt3fODGfJh_HTLU/edit#gid=1307341903"
          }
        />
        <MyCard
          label="Hands-on"
          img={drive}
          link={
            "https://drive.google.com/drive/folders/1PDUdUa9a-LRpJ_kB8-xnzmDSwRBIKp1h"
          }
        />
        <MyCard
          label="Scripts"
          img={zabbix}
          link={"https://zabbix.mw-solucoes.com/externalscripts/"}
        />
        <MyCard
          label="Guia de ativação"
          img={monday}
          link={"https://mw-solucoes.monday.com/boards/2133310202"}
        />
      </Box>
    </Box>
  );
}
