import {
  Box,
  Paper,
  Snackbar,
  Alert,
  Typography,
  Stack,
  TextField,
  Divider,
  Autocomplete,
  Fade,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
  useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";

import TabelaCadastrados from "./tabelaCadastrados";
import TabelaArquivados from "./tabelaArquivados";
import TabelaPreCadastro from "./tabelaPreCadastrados";
import api from "../../services/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import imgEmpty from "../../assets/wallet.svg";

const styles = {
  accordionSettings: {
    width: "100%",
    borderRadius: 2,
    padding: "0px 10px",
    border: "none",
    "&::before": {
      display: "none",
    },
  },
  container: {
    minWidth: "800px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80vw",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    // padding: "10px",
    gap: "1rem",
    marginTop: "30px",
  },
  paperUnselected: {
    backgroundColor: "rgba(0,0,0,0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    filter: "opacity(0.6)",
    height: "300px",
    width: "100%",
  },
  paperCliente: {
    borderRadius: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "20px",
    width: "100%",
    flexDirection: "column",
  },
  titles: {
    fontWeight: 500,
    color: "primary.main",
    fontSize: "16px",
    m: "5px 0px",
  },
};

export default function AreaDoCliente({ socket }) {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  //cliente e produto
  const [cliente, setCliente] = useState();
  const [clientes, setClientes] = useState([]);
  const [predefinicoes, setPredefinicoes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [tipo, setTipo] = useState("easymon");
  const [easyMon, setEasyMon] = useState(null);
  const [proxys, setProxys] = useState([]);
  const [expanded, setExpanded] = useState(1);
  //loading das tabelas
  const [loadingPreCadastro, setLoadingPreCadastro] = useState(false);
  const [loadingCadastrados, setLoadingCadastrados] = useState(false);
  const [loadingArquivados, setLoadingArquivados] = useState(false);
  // outros laoding
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPredefinicoes, setLoadingPredefinicoes] = useState(true);
  // hosts das tabelas
  const [hostsPreCadastro, setHostsPreCadastro] = useState([]);
  const [hostsZabbix, setHostsZabbix] = useState([]);

  //pagina
  const [snackbar, setSnackbar] = useState(null);
  //acessos id's
  const [acessoClientesId, setAcessoClientesId] = useState(null);
  const [acessoProdutoId, setAcessoProdutoId] = useState(null);
  //buttons
  const [disableButtonEasyMon, setDisableButtonEasyMon] = useState(true);
  const [disableButtonCreate, setDisableButtonCreate] = useState(true);

  useEffect(() => {
    handleApiGetPredefinicoes();
    handleApiGetClientes();
  }, []);
  useEffect(() => {
    if (tabValue === 0) {
      handleApiGetPredefinicoes();
    }
  }, [tabValue]);

  const getClientesLabel = (clientes) =>
    `${clientes.numero} - ${clientes.empresa}`;

  const getProdutosLabel = (produtos) => `${produtos.nome}`;

  useEffect(() => {
    if (cliente && easyMon) {
      handleApiGetHosts(acessoClientesId, acessoProdutoId);
      setDisableButtonCreate(true);
    } else {
      setHostsZabbix([]);
    }
  }, [easyMon]);

  useEffect(() => {
    if (cliente) {
      setDisableButtonEasyMon(false);
      handleApiGetProdutos(cliente.id, tipo);
    }
  }, [cliente]);

  function onChangePreCadastro(hostsEdit) {
    if (hostsEdit.length == 0) return;
    setLoadingPreCadastro(true);
    const usuarioId = JSON.parse(localStorage.getItem("1")).refreshToken
      .usuarioId;

    socket.emit(
      "updatePreCadastro",
      { id: cliente.id, hosts: hostsEdit, usuarioId },
      (response) => {
        if (response.status == 200) {
          setChangeTablePreCadastro(response);
          setSnackbar({
            children: "As modificações foram salvas com sucesso no cliente!",
            severity: "success",
          });
        } else {
          setSnackbar({
            children: "Error: Não foi possível salvar as modificações",
            severity: "error",
          });
        }
        setLoadingPreCadastro(false);
      }
    );
    return;
  }

  function setChangeTablePreCadastro(response) {
    setHostsPreCadastro((prevHosts) => {
      const hostsMap = new Map(prevHosts.map((host) => [host.id, host]));
      response?.data?.forEach((updateHost) => {
        hostsMap.set(updateHost.id, updateHost);
      });
      return Array.from(hostsMap.values());
    });
  }
  function deletePreCadastro(hostDelete) {
    if (hostDelete.length == 0) return;
    const usuarioId = JSON.parse(localStorage.getItem("1")).refreshToken
      .usuarioId;
    socket.emit(
      "deletePreCadastro",
      { id: cliente.id, hosts: hostDelete.map((a) => a.id), usuarioId },
      (response) => {
        if (response.status == 200) {
          deleteChangePreCadastro(hostDelete);
          handleApiGetHosts(acessoClientesId, acessoProdutoId);
          setSnackbar({
            children: "Dados removidos do pré-cadastro",
            severity: "success",
          });
        } else {
          setSnackbar({
            children:
              "Error: Não foi possível remover do pré-cadastro do cliente",
            severity: "error",
          });
        }
        setLoadingPreCadastro(false);
      }
    );
    return;
  }
  function deleteChangePreCadastro(hosts) {
    setHostsPreCadastro((prevHosts) => {
      // logica para deletar hosts
      const deletedHostIds = hosts.map((deletedHost) => deletedHost.id);
      return prevHosts.filter((host) => !deletedHostIds.includes(host.id));
    });
  }

  useEffect(() => {
    if (cliente && easyMon) {
      const handlePreCadastroChange = (resp) => {
        if (cliente.id !== resp.clientId) return;
        setChangeTablePreCadastro(resp);
      };
      const handlePreCadastroDelete = (resp) => {
        if (cliente.id !== resp.clientId) return;
        deleteChangePreCadastro(resp?.data);
      };
      socket.on("onChangePreCadastro", handlePreCadastroChange);
      socket.on("onDeletePreCadastro", handlePreCadastroDelete);
      socket.emit("getPreCadastros", { id: cliente.id }, (response) => {
        if (response.status === 200) {
          setHostsPreCadastro(response.data);
        } else {
          console.error(response.error);
          setSnackbar({
            children: "Error: Não foi possível buscar dados do zabbix",
            severity: "error",
          });
        }
      });
      // Limpa o listener quando o componente desmonta ou dependências mudam
      return () => {
        socket.off("onChangePreCadastro", handlePreCadastroChange);
        socket.off("onDeletePreCadastro", handlePreCadastroDelete);
      };
    }
  }, [cliente, easyMon]);

  async function handleApiGetHosts(acessoClientesId, acessoProdutoId) {
    {
      try {
        setDisableButtonCreate(true);
        setLoadingPage(true);
        const response = await api.post("/zabbix/hosts/findManyPingSnmp", {
          acessoClientesId,
          acessoProdutoId,
        });

        if (response.data.status === "Error") {
          console.error(response.data);
          setSnackbar({
            children: "Error: Não foi possível buscar dados do zabbix",
            severity: "error",
          });
          setLoadingPage(false);
        } else {
          //console.log(response.data);
          setHostsZabbix(response.data.hosts);
          setProxys(response.data.proxys);
          setDisableButtonCreate(false);
          setSnackbar({
            children: "Dados encontrados com sucesso",
            severity: "success",
          });
          setLoadingPage(false);
        }
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });

        console.error(error);
        setLoadingPage(false);
      }
    }
  }

  // useEffect(() => {
  //   if (cliente && easyMon) {
  //     handleApiGetHosts(acessoClientesId, acessoProdutoId);
  //   } else {
  //     setHostsZabbix([]);
  //     setDisableButtonCreate(true);
  //   }
  // }, [cliente, easyMon, acessoClientesId, acessoProdutoId]);

  async function handleApiGetPredefinicoes() {
    try {
      setLoadingPredefinicoes(true);
      const response = await api.get("/getPredefinicoes");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os dados",
          severity: "error",
        });
        setLoadingPredefinicoes(false);
      } else {
        if (Array.isArray(response.data)) {
          setPredefinicoes(response.data);
        }
        setLoadingPredefinicoes(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      console.log(error);
      setLoadingPredefinicoes(false);
    }
  }
  //busca clientes
  async function handleApiGetClientes() {
    try {
      const response = await api.get("/clienteAcesso");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        setClientes(response.data);
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  async function handleApiGetProdutos(acessoClientesId, tipo) {
    try {
      const response = await api.post("/produtoCliente", {
        acessoClientesId,
        tipo,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar Clientes",
          severity: "error",
        });
      } else {
        const atualizaProdutos = response.data;
        setProdutos(atualizaProdutos);
        if (Array.isArray(atualizaProdutos) && atualizaProdutos.length === 1) {
          setEasyMon(atualizaProdutos[0]);
          setAcessoProdutoId(atualizaProdutos[0]?.id);
        }
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  return (
    <>
      <Box sx={styles.container}>
        <Paper sx={styles.paperCliente}>
          <Box>
            <Stack direction={"row"} gap={4}>
              <Box>
                <Typography
                  color={"primary"}
                  fontWeight={500}
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  Selecione um Cliente
                </Typography>
                <Autocomplete
                  sx={{ m: 1, width: "180px", ml: 0 }}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                    setAcessoClientesId(newValue.id);
                    //console.log(newValue.id);
                    setEasyMon(null);
                    setAcessoProdutoId("");
                    setHostsZabbix([]);
                  }}
                  value={cliente || null}
                  options={clientes}
                  getOptionLabel={getClientesLabel}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Cliente"
                        variant="standard"
                      />
                      <Fade in={loadingPredefinicoes}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
              <Box>
                <Autocomplete
                  disabled={disableButtonEasyMon}
                  sx={{ m: 1, mt: 4.8, width: "200px" }}
                  onChange={(event, newValue) => {
                    setEasyMon(newValue);
                    setAcessoProdutoId(newValue?.id);
                    //console.log(newValue.id);
                  }}
                  value={easyMon}
                  options={produtos}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={getProdutosLabel}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Selecione o EasyMon"
                        variant="standard"
                      />
                      <Fade in={loadingPage}>
                        <LinearProgress />
                      </Fade>
                    </>
                  )}
                />
              </Box>
            </Stack>
          </Box>{" "}
        </Paper>
        {(loadingPage || loadingPredefinicoes) && (
          <>
            <Skeleton animation="wave" width={"100%"} height={70} />
            <Skeleton animation="wave" width={"100%"} height={70} />
            <Skeleton animation="wave" width={"100%"} height={70} />
          </>
        )}
        {(!acessoClientesId || !acessoProdutoId) &&
          !(loadingPage || loadingPredefinicoes) && (
            <Box sx={styles.paperUnselected}>
              <img
                style={{
                  width: "100px",
                  filter:
                    "invert(21%) sepia(35%) saturate(14%) hue-rotate(317deg) brightness(90%) contrast(87%)",
                }}
                src={imgEmpty}
              />
              <Typography color="#525353" fontSize="25px">
                Selecione o cliente e o produto
              </Typography>
            </Box>
          )}
        {acessoClientesId &&
          acessoProdutoId &&
          !(loadingPage || loadingPredefinicoes) && (
            <>
              <Accordion
                disableGutters
                sx={styles.accordionSettings}
                expanded={expanded == 1}
                onChange={() =>
                  setExpanded((prev) => {
                    if (prev == 1) return null;
                    else return 1;
                  })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={styles.titles}>
                    Pré Cadastro de Hosts
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider sx={{ width: "100%", mt: "-10px" }} />

                  <TabelaPreCadastro
                    setSnackbar={setSnackbar}
                    predefinicoes={predefinicoes}
                    hosts={hostsPreCadastro.filter((host) => host.status == 0)}
                    setHosts={setHostsPreCadastro}
                    hostsZabbix={hostsZabbix}
                    deletePreCadastro={deletePreCadastro}
                    onChangePreCadastro={onChangePreCadastro}
                    loading={loadingPreCadastro}
                    acessoClientesId={acessoClientesId}
                    acessoProdutoId={acessoProdutoId}
                    handleApiGetHosts={handleApiGetHosts}
                    proxys={proxys}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                disableGutters
                sx={styles.accordionSettings}
                expanded={expanded == 2}
                onChange={() =>
                  setExpanded((prev) => {
                    if (prev == 2) return null;
                    else return 2;
                  })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography sx={styles.titles}>Hosts Cadastrados</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider sx={{ width: "100%", mt: "-10px" }} />
                  <TabelaCadastrados
                    loadingPage={loadingPage}
                    loadingPredefinicoes={loadingPredefinicoes}
                    predefinicoes={predefinicoes}
                    rows={hostsZabbix}
                    proxys={proxys}
                    setRows={setHostsZabbix}
                    key={2}
                    acessoClientesId={acessoClientesId}
                    acessoProdutoId={acessoProdutoId}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                disableGutters
                sx={styles.accordionSettings}
                expanded={expanded == 3}
                onChange={() =>
                  setExpanded((prev) => {
                    if (prev == 3) return null;
                    else return 3;
                  })
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    paddingRight="10px"
                  >
                    <Typography
                      sx={[styles.titles, { color: "secondary.main" }]}
                    >
                      Lixeira
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: theme.palette.text.disabled,
                      }}
                    >
                      Arquivos da lixeira serão excluidos após 30 dias
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <TabelaArquivados
                    hosts={hostsPreCadastro.filter((host) => host.status == 1)}
                    onChangePreCadastro={onChangePreCadastro}
                    loading={loadingPreCadastro}
                  />
                </AccordionDetails>
              </Accordion>
            </>
          )}

        {/* <Paper sx={styles.paperHosts}>
          <Stack direction={"column"} justifyContent={"space-between"}>
            <Box sx={styles.box}>
              {tabValue === 0 && (
                
              )}
              {tabValue === 1 && (
                <AdicaoEmMassa
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  proxys={proxys}
                  setSnackbar={setSnackbar}
                  key={2}
                  setTabValue={setTabValue}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                  disableButtonCreate={disableButtonCreate}
                />
              )}
              {tabValue === 2 && (
                <ImportarCsv
                  loadingPredefinicoes={loadingPredefinicoes}
                  predefinicoes={predefinicoes}
                  proxys={proxys}
                  setSnackbar={setSnackbar}
                  setTabValue={setTabValue}
                  acessoClientesId={acessoClientesId}
                  acessoProdutoId={acessoProdutoId}
                  disableButtonCreate={disableButtonCreate}
                  key={3}
                />
              )}
            </Box>
          </Stack>
        </Paper> */}
      </Box>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
