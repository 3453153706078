import { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  Stack,
  Button,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Box,
  Autocomplete,
} from "@mui/material";

import api from "../../../../services/api";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
const stylesModal = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    padding: "10px",
  },
  campoFiltro: {
    width: "200px",
  },
  campoFiltroSecondary: {
    width: "420px",
  },
  button: { height: "40px", minWidth: "120px" },
};

export default function ModalEditaDemanda(props) {
  const {
    openEditModal,
    setOpenEditModal,
    selectedRow,
    setUpdateRow,
    colaboradores,
    clientes,
    categorias,
    subCategorias,
  } = props;

  const [responsavel, setResponsavel] = useState(null);
  const [solicitante, setSolicitante] = useState(null);
  const [status, setStatus] = useState("");
  const [codigo, setCodigo] = useState("");
  const [id, setId] = useState("");
  const [categoria, setCategoria] = useState("");
  const [subCategoria, setSubCategoria] = useState(null);
  const [data, setData] = useState(dayjs(new Date()));
  const [previsao, setPrevisao] = useState(dayjs(new Date().getTime() + 86400000 * 3));
  const [descricao, setDescricao] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableButtonEdit, setDisableButtonEdit] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [parceiro, setParceiro] = useState(null);
  const [acessoClientesId, setAcessoClientesId] = useState("");
  //dados de ususario do localstorage
  //snackbar
  const [snackbar, setSnackbar] = useState(null);
  useEffect(() => {
    if (selectedRow) {
      setId(selectedRow.id);
      if (selectedRow.responsavel) {
        let responsavelAux = colaboradores.filter(
          (obj) => obj.usuarioId === selectedRow.responsavelId
        );
        if (Array.isArray(responsavelAux)) {
          setResponsavel(responsavelAux[0]);
        }
      }
      setSolicitante(selectedRow.solicitante);
      setCodigo(selectedRow.codigo);
      setStatus(selectedRow.status);
      setCategoria(selectedRow.categoria);
      setSubCategoria(selectedRow.subCategoria);
      setData(dayjs(selectedRow.data));
      setDescricao(selectedRow.descricao);
      setStatus(selectedRow.status);
      if (clientes.length > 0) {
        let parceiroAux = clientes.filter(
          (obj) =>
            obj.parceiro?.toLowerCase() === selectedRow.parceiro?.toLowerCase()
        );
        if (Array.isArray(parceiroAux) && parceiroAux) {
          setParceiro(parceiroAux[0]);
        }
        let clienteAux = parceiroAux[0]?.clientes?.filter(
          (obj) => obj.cliente === selectedRow.cliente.cliente
        );

        if (Array.isArray(clienteAux)) {
          setCliente(clienteAux[0]);
        }
      }
      setPrevisao(dayjs(selectedRow.previsao));
      setAcessoClientesId(selectedRow.acessoClientesId);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (!cliente || !parceiro || !descricao) {
      setDisableButtonEdit(true);
    } else {
      setDisableButtonEdit(false);
    }
  }, [cliente, parceiro, descricao]);
  function handleEditRow(params) {
    let objectRow = {};
    objectRow.responsavelId = params.responsavel;
    objectRow.solicitanteId = params.solicitante;
    objectRow.responsavel = params.usuario ? params.usuario.Perfil : "";
    objectRow.solicitante = params.usuarioCliente
      ? params.usuarioCliente.PerfilCliente
      : "";
    objectRow.codigo = params.codigo;
    objectRow.descricao = params.descricao;
    objectRow.categoria = params.categoria;
    objectRow.subCategoria = params.subCategoria;
    objectRow.status = params.status;
    objectRow.previsao = new Date(params.previsao);
    objectRow.data = new Date(new Date(params.createAt) - 3 * 60 * 60 * 1000); //correção de fuso horario, com diferença de 3 horas
    objectRow.id = params.id;
    objectRow.topicos = params._count.TopicoRelatorio;
    objectRow.cliente = {
      cliente: params.acessoClientes?.empresa,
      numero: params.acessoClientes?.numero,
      id: params.acessoClientes?.id,
    };
    objectRow.parceiro = params.acessoClientes.parceiro;
    objectRow.acessoClientesId = params.acessoClientes.id;

    setUpdateRow(objectRow);
  }
  async function handleApiPutRelatorios() {
    try {
      setDisableButton(true);
      const response = await api.put("/suporte/update", {
        id,
        descricao,
        previsao,
        responsavel: responsavel ? responsavel.usuarioId : null,
        acessoClientesId: cliente ? cliente.id : null,
        status,
        categoria: categoria ? categoria : null,
        subCategoria: subCategoria ? subCategoria : null,
        logsLoginId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar dados",
          severity: "error",
        });
        setDisableButton(false);
      } else {
        setDisableButton(false);
        if (response.data) {
          handleEditRow(response.data);
        }
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setDisableButton(false);
    }
  }
  return (
    <div>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <Paper sx={stylesModal.paper} elevation={3}>
          <DialogTitle color="primary">{"Editar Demanda"}</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <TextField
              disabled
              id="input-solicitante"
              variant="standard"
              label="Solicitante"
              value={solicitante ? solicitante.nome : ""}
              sx={stylesModal.campoFiltroSecondary}
              type="text"
            />
            <Stack direction={"row"} gap={"20px"}>
              <Autocomplete
                disabled
                sx={stylesModal.campoFiltro}
                onChange={(event, newValue) => {
                  setParceiro(newValue);
                  setCliente(null);
                }}
                value={parceiro ? parceiro : null}
                options={clientes.map((name, index) => name)}
                getOptionLabel={(option) => option.parceiro.toUpperCase()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parceiro"
                    variant="standard"
                    required
                  />
                )}
              />
              <Autocomplete
                disabled
                sx={stylesModal.campoFiltro}
                onChange={(event, newValue) => {
                  setCliente(newValue);
                }}
                value={cliente ? cliente : null}
                options={
                  parceiro ? parceiro.clientes.map((name) => name) : null
                }
                getOptionLabel={(option) =>
                  `${option.numero} ${option.cliente}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    variant="standard"
                    required
                  />
                )}
              />
            </Stack>
            <Autocomplete
              sx={stylesModal.campoFiltroSecondary}
              id="combo-box-demo"
              options={colaboradores}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Responsável" />
              )}
              value={responsavel ? responsavel : null}
              onChange={(event, newValue) => {
                setResponsavel(newValue);
              }}
              getOptionLabel={(option) => option.nome}
            />
            <Stack direction={"row"} gap={"20px"}>
              {" "}
              <TextField
                disabled
                id="filtro-codigo"
                variant="standard"
                label="Código"
                value={codigo}
                sx={stylesModal.campoFiltro}
                onChange={(event) => {
                  setCodigo(event.target.value);
                }}
                type="text"
              />{" "}
              <FormControl variant="standard">
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  labelId="select-status"
                  id="filtro-status"
                  value={status}
                  sx={stylesModal.campoFiltro}
                  label="Status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value={0}>Pendente</MenuItem>
                  <MenuItem value={1}>Em andamento</MenuItem>
                  <MenuItem value={2}>Concluída</MenuItem>
                  <MenuItem value={3}>Aguardando Revisão</MenuItem>
                  <MenuItem value={4}>Aguardando Retorno</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <TextField
              required
              id="input-descricao"
              variant="standard"
              label="Descrição"
              value={descricao ? descricao : null}
              sx={stylesModal.campoFiltroSecondary}
              onChange={(event) => {
                if (event.target.value.length <= 255)
                  setDescricao(event.target.value);
              }}
              multiline
              rows={4}
              slotProps={{
                maxLength: 255
              }}
              type="text"
              helperText="Limite de 255 digitos"
            />
            <Stack direction={"row"} gap={"20px"}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="datadecriacao"
                  label="Data de Criação"
                  disabled
                  color="primary"
                  sx={stylesModal.campoFiltro}
                  value={data}
                  onChange={(newValeu) => {
                    setData(newValeu);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"pt-br"}
              >
                <DatePicker
                  id="previsao"
                  label="Previsão"
                  color="primary"
                  disabled
                  sx={stylesModal.campoFiltro}
                  value={previsao}
                  onChange={(newValeu) => {
                    setPrevisao(newValeu);
                  }}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>{" "}
            <Stack direction={"column"} gap={"20px"}>
              <FormControl
                variant="standard"
                sx={stylesModal.campoFiltroSecondary}
              >
                <InputLabel id="demo-multiple-checkbox-label">
                  Categorias
                </InputLabel>
                <Select
                  label={"Categorias"}
                  value={categoria}
                  onChange={(event) => {
                    setCategoria(event.target.value);
                    setSubCategoria("");
                  }}
                >
                  {categorias.map((name, index) => (
                    <MenuItem key={index} value={name.categoria}>
                      {
                        //   <Checkbox checked={categoria.indexOf(name.categoria) > -1} />
                      }
                      {name.categoria}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                <Autocomplete
                  sx={stylesModal.campoFiltroSecondary}
                  onChange={(event, newValue) => {
                    setSubCategoria(newValue);
                  }}
                  value={subCategoria}
                  options={subCategorias
                    .filter((val) => val.categoria.includes(categoria))
                    .map((name, index) => name.subCategoria)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub-categoria"
                      variant="standard"
                    />
                  )}
                />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              // variant="contained"
              sx={stylesModal.button}
              onClick={() => {
                setOpenEditModal(false);
              }}
            >
              CANCELAR
            </Button>{" "}
            <Button
              // variant="contained"
              disabled={disableButtonEdit}
              sx={stylesModal.button}
              onClick={() => {
                setOpenEditDialog(true);
              }}
            >
              EDITAR
            </Button>
          </DialogActions>
        </Paper>
      </Modal>{" "}
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salva os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditDialog(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            disabled={disableButton}
            onClick={() => {
              setOpenEditModal(false);
              handleApiPutRelatorios();
              setOpenEditDialog(false);
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </div>
  );
}
