// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ResponsiveBar } from "@nivo/bar";
import { Typography } from "@mui/material";
import { Box, Paper } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

const colors = ["#ae3385", "#ae7533", "#451189", "#3399ae"];
export default function MyResponsiveBar({ data, keyy}) {
  const [Data, setData] = useState([]);
  const [Fill, setFill] = useState([]);

  useEffect(() => {
    let animation = setTimeout(() => {
      setData(data);
    }, 20);

    return () => {
      clearTimeout(animation);
    };
  }, [data]);

  useEffect(() => {
    Data.map((datax, i) => {
      let da = [
        {
          match: {
            id: "value",
          },
          id: "lines",
        },
      ];
      setFill((fills) => [...fills, ...da]);
    });
  }, [Data]);
  const TotalLabels = ({ bars, yScale, }) => {
    // space between top of stacked bars and total label
   
  
    return bars.map(({ width, height, y, data }, i) => {
      let total = data.value;
      if (!total) total=0;
      let name = data.data.id.split(" ")[0]
      if(name.length>9) name = name.substring(0,7)+"..."
      return (
       <Fragment key={`${name+keyy}+${i}`} >
        <g>
            <text
                transform={`translate(${width + 10}, ${y + height / 2})`}
                textAnchor="left"
                dominantBaseline="central"
                style={{
                    fill: "white",
                    fontSize:"14px",
                    mixBlendMode:'difference'
                  }}>
                {total}
            </text>
        </g>
        <g>
          <text
              transform={`translate(${-75}, ${y + height / 2})`}
              textAnchor="left"
              dominantBaseline="central"
              style={{
                  fill: "white",
                  fontSize:"15px",
                  mixBlendMode:'difference',
                }}>
              {name}
          </text>
      </g>
      </Fragment>
        )
    })
    
  };
  return (
    <ResponsiveBar
      data={Data}
      key={keyy}
      theme={{
        background: "transparent",
        fontSize: 15,
        axis: {
          ticks: {
            text: {
              fontSize: 13,
              fill: "#ffffff",
            },
          },
        },
      }}
      keys={["value"]}
      layout="horizontal"
      enableLabel={false}
      indexBy="id"
      groupMode="grouped"
      margin={{left: 90, right:35 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: false }}
      colors={({ data, index }) =>
        data.color ? data.color : String(colors[index])
      }
      tooltip={(e) => (
        <Paper
          sx={{
            background: "white",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            zIndex:100
          }}
        >
          <Box
            sx={{
              background: e.color,
              width: "15px",
              height: "15px",
              mr: "5px",
            }}
          ></Box>
          <Typography color="black">
            {e.value}
          </Typography>
        </Paper>
      )}
      enableGridY={false}
      borderColor="white"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={"white"}
      layers={["grid", "axes", "bars",TotalLabels, "markers", "legends"]}
    />
  );
}
