const useStyles = (theme) => ({
  conteiner: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  body: { display: "flex", width: "100%" },
  accordion: {
    display: "flex",
    // maxWidthwidth: "calc(100vw - 200px)",
    width: "100%",
    flexDirection: "column",
  },
  accordionContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    gap: "16px",
    justifyContent: "stretch",
  },
  girdContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    padding: "20px",
    overflowY: "auto",
    maxHeight: "550px",
    display: "flex",

    flexDirection: "column",
  },
  modalTypography: { mb: "10px" },
  modalContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  paper: {
    display: "flex",
    padding: "20px",
    justifyContent: "center",
  },
  TextField: {
    width: "200px",
  },
  dialogPaper: {
    color: "#5B2E87",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderRadius: "8px",
    width: "56vw",
    maxWidth: "844px",
    maxHeight: "80vh",
    overflowY: "auto",
    minWidth: "400px",
  },
});

export default useStyles;
