import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Relogio from "@mui/icons-material/AccessAlarm";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "#fff",
  ...theme.typography.body1,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.primary,
}));
const Styles = {
  Container: {
    backgroundColor: "transparente",
    // borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "55px",
    width: "100%",
    minWidth: "419px",
  },
};
export default function Footer(props) {
  const [horas, setHoras] = useState(0);
  const [minutos, setMinutos] = useState(0);
  const [segundos, setSegundos] = useState(0);
  const [totalTimeInseconds, setTotalTimeInseconds] = useState(0);

  const navigate = useNavigate();

  const handleExpiresIn = () => {
    let result = localStorage.getItem("1");
    result = JSON.parse(result);
    if (result) {
      //console.log("tokenhora");
      //console.log(result.refreshToken.expiresIn);
      return result.refreshToken.expiresIn;
    } else {
      return "";
    }
  };
  //console.log(Date.now() / 1000);
  //console.log(handleExpiresIn().getTime);
  useEffect(() => {
    setTimeout(() => {
      setTotalTimeInseconds(totalTimeInseconds - 1);
      let tempoExpiracao = handleExpiresIn();
      let tempoEmSegundos = parseInt(tempoExpiracao - Date.now() / 1000);
      if (Date.now() / 1000 <= tempoExpiracao) {
        setHoras(Math.floor(tempoEmSegundos / (60 * 60)));
        setMinutos(Math.floor(tempoEmSegundos / 60) % 60);
        setSegundos(tempoEmSegundos % 60);
        //console.log(`tempo em segundos${tempoEmSegundos}`);
        //console.log(handleExpiresIn());
      } else {
        navigate("/login");
      }
    }, 1000);
  }, [totalTimeInseconds]);

  handleExpiresIn();
  return (
    <Box display="block" sx={Styles.Container}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 2 }}
        sx={{ justifyContent: "flex-end", marginRight: "8vh" }}
      >
        <Item elevation={2}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 1.5 }}
            //sx={{ justifyContent: "flex-end", marginRight: "8vh" }}
          >
            <Relogio fontSize="medium" color="primary" />
            <Typography fontWeight="400">Sua sessão expira em:</Typography>
            <Typography>{horas.toString().padStart(2, "0")}</Typography>
            <Typography variant="body1">h</Typography>
            <Divider orientation="vertical" flexItem></Divider>
            <Typography>{minutos.toString().padStart(2, "0")}</Typography>
            <Typography variant="body1">m</Typography>
            <Divider orientation="vertical" flexItem></Divider>
            <Typography>{segundos.toString().padStart(2, "0")}</Typography>
            <Typography variant="body1">s</Typography>
          </Stack>
        </Item>
      </Stack>
    </Box>
  );
}
