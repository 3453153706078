import { Box, CircularProgress, Icon } from "@mui/material";
import mwIconMinimalist from "../../assets/logo5.png";
import checkToken from "../../services/CheckToken";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const styles = {
  box: {
    m: 1,
    width: "96vw",
    height: "96vh",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "fit-content",
    height: "fit-content",
    margin: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: 80,
    height: "auto",
  },
  circularProgress: {
    color: "primary",
    position: "absolute",
    zIndex: 1,
  },
};

export default function ProtectedRoute({
  children,
  setCheckLogin,
  checkLogin,
}) {
  useEffect(() => {
    const tokenValidate = async () => {
      const data = await checkToken();
      if (data) setCheckLogin(true);
      else setCheckLogin(false);
    };
    tokenValidate();
  }, []);

  if (checkLogin === null) {
    return (
      <Box sx={styles.box}>
        <Icon style={styles.icon}>
          <img src={mwIconMinimalist} style={styles.img} />
        </Icon>
        <CircularProgress
          size={100}
          sx={styles.circularProgress}
          thickness={2.5}
        />
      </Box>
    );
  }

  if (!checkLogin) {
    return <Navigate to="/login" />;
  }

  return children;
}
