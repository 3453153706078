import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";

export default function CreateModalPort(params) {
  const {
    setOpenCreateModal,
    openCreateModal,
    setSnackbar,
    setLoadingTable,
    styles,
    handleNewPort,
    acessoClientesId,
    acessoProdutoId,
  } = params;
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [port, setPort] = useState("");
  const [operator, setOperator] = useState(null);

  useEffect(() => {
    if (!port || !operator) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
  }, [port, operator]);
  function clearModal() {
    setPort("");
    setOperator("");
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableCreateButton === false) {
      handleApiPostPort();
    }
  };
  async function handleApiPostPort() {
    try {
      setLoadingTable(true);
      setDisableCreateButton(true);
      const response = await api.post("/relatoriosFlow/port/create", {
        port: parseInt(port),
        operator,
        acessoClientesId,
        acessoProdutoId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleNewPort(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableCreateButton(false);
      setOpenCreateModal(false);
      clearModal();
    }
  }
  return (
    <>
      <Modal
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          clearModal();
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Novo Port </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"}>
              <FormControl sx={styles.TextField} variant="standard">
                <InputLabel>Operador</InputLabel>
                <Select
                  value={operator}
                  label="Operador"
                  onChange={(event) => {
                    setOperator(event.target.value);
                  }}
                >
                  <MenuItem value={">"}>{">"}</MenuItem>
                  <MenuItem value={"<"}>{"<"}</MenuItem>
                  <MenuItem value={"="}>{"="}</MenuItem>
                </Select>
              </FormControl>{" "}
              <TextField
                sx={styles.TextField}
                id="textfield-porta"
                label="Porta"
                variant="standard"
                type="number"
                onKeyDown={handleKeyDown}
                value={port}
                onChange={(e) => setPort(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenCreateModal(false);
                clearModal();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableCreateButton}
              onClick={() => {
                handleApiPostPort();
              }}
            >
              Criar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
