import {
  DarkModeOutlined,
  LightModeOutlined,
  Logout,
  PersonOutline,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function MenuLayout(props) {
  const {
    socket,
    colorMode,
    setColorMode = () => {},
    anchorEl,
    setAnchorEl = () => {},
    setOpenModal = () => {},
    administrador,
  } = props;
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePerfil = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleAdm = () => {
    navigate("/administracao");
  };

  const handleLogout = () => {
    socket.disconnect();
    localStorage.clear();
    navigate("/login");
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      disableScrollLock={true}
      sx={{ marginLeft: "-5px" }}
    >
      <MenuItem onClick={handlePerfil}>
        <ListItemIcon>
          <PersonOutline />
        </ListItemIcon>
        <ListItemText>Perfil</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (colorMode) {
            setColorMode(false);
            localStorage.setItem("3", JSON.stringify({ colorMode: false }));
          } else {
            setColorMode(true);
            localStorage.setItem("3", JSON.stringify({ colorMode: true }));
          }
        }}
        disabled={false}
      >
        {!colorMode ? (
          <ListItemIcon>
            <DarkModeOutlined />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <LightModeOutlined />
          </ListItemIcon>
        )}
        <ListItemText>Modo Noturno</ListItemText>
      </MenuItem>
      {administrador && (
        <MenuItem onClick={handleAdm}>
          <ListItemIcon>
            <SupervisorAccountOutlined />
          </ListItemIcon>
          <ListItemText>Administração</ListItemText>
        </MenuItem>
      )}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText>Sair</ListItemText>
      </MenuItem>
    </Menu>
  );
}
