// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ResponsiveBar } from "@nivo/bar";
import { Typography } from "@mui/material";
import { Box, Paper } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

const colors = ["#ae3385", "#ae7533", "#451189", "#3399ae"];
export default function MyResponsiveBar({ data, keyy}) {
  const [Data, setData] = useState([]);
  const [Fill, setFill] = useState([]);

  useEffect(() => {
    let animation = setTimeout(() => {
      setData(data);
    }, 50);

    return () => {
      clearTimeout(animation);
    };
  }, [data]);

  useEffect(() => {
    Data.map((datax, i) => {
      let da = [
        {
          match: {
            id: "value",
          },
          id: "lines",
        },
      ];
      setFill((fills) => [...fills, ...da]);
    });
  }, [Data]);

  const indexBy = "id";

  const TotalLabels = ({ bars, yScale, }) => {
    if (Data.length==0) return
    const labelMargin = 20;
    return bars.map(({ data: { data, indexValue }, x, width }, i) => {
      let total = data.value;
      if (!total) total=0;
      return (
        <Fragment key={`${keyy}-${i}`}>
        <g transform={`translate(${x}, ${yScale(total) - labelMargin})`}>
          <text
           overflow={'hidden'}
            // add any class to the label here
            className="bar-total-label"
            x={width / 2}
            y={labelMargin / 2}
            textAnchor="middle"
            alignmentBaseline="central"
            // add any style to the label here
            style={{
              fill: "white",
              fontSize:"15px",
              mixBlendMode:'difference'
            }}
          >
            {total}
          </text>
        </g>
        <g transform={`translate(${x}, ${yScale(0)})`}>
          <text
            // add any class to the label here
            className="bar-total-label"
            x={width / 2}
            y={labelMargin/2}
            textAnchor="middle"
            alignmentBaseline="central"
            style={{
              fill: "white",
              fontSize:"13px",
              mixBlendMode:'difference'
            }}
          >
            {data.id}
          </text>
        </g>
        </Fragment>
      );
    });
  };

  return (
    <ResponsiveBar
      data={Data}
      key={keyy}
      theme={{
        background: "transparent",
        fontSize: 16,
        axis: {
          ticks: {
            text: {
              fontSize: 22,
              fill: "#ffffff",
            },
          },
        },
      }}
      keys={["value"]}
      enableLabel={false}
      indexBy="id"
      groupMode="grouped"
      margin={{ bottom: 30, top:20 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: false }}
      colors={({ data, index }) =>
        data.color ? data.color : String(colors[index])
      }
      tooltip={(e) => (
        <Paper
          sx={{
            background: "white",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            zIndex:5
          }}
        >
          <Box
            sx={{
              background: e.color,
              width: "15px",
              height: "15px",
              mr: "5px",
            }}
          ></Box>
          <Typography color="black">
            {e.value}
          </Typography>
        </Paper>
      )}
      enableGridY={false}
      borderColor="white"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={"white"}
      layers={["grid", "axes", "bars", TotalLabels, "markers", "legends"]}
    />
  );
}
