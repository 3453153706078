import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../../services/api";

export default function EditModalInterfaces(params) {
  const {
    setOpenEditModal,
    openEditModal,
    setSnackbar,
    setLoadingTable,
    styles,
    selectedRow,
    routers,
    handleEditInterfaces,
  } = params;
  const [disableEditButton, setDisableEditButton] = useState(false);
  const [index, setIndex] = useState("");
  const [interfaceFlow, setInterfaceFlow] = useState("");
  const [router, setRouter] = useState({});
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && disableEditButton === false) {
      handleApiPutInterfaces();
    }
  };
  useEffect(() => {
    if (selectedRow) {
      setInterfaceFlow(selectedRow.interface);
      setIndex(selectedRow.index);
      if (routers.length > 0) {
        const routerAux = routers.filter(
          (router) => router?.nome_bgp === selectedRow?.exportador
        );
        if (Array.isArray(routerAux) && routerAux) {
          setRouter(routerAux[0]);
        }
      }
    }
  }, [selectedRow]);
  useEffect(() => {
    if (!interfaceFlow || !index || !router) {
      setDisableEditButton(true);
    } else {
      setDisableEditButton(false);
    }
  }, [interfaceFlow, index, router]);
  async function handleApiPutInterfaces() {
    try {
      setLoadingTable(true);
      setDisableEditButton(true);
      const response = await api.put("/relatoriosFlow/interfaces/update", {
        id: selectedRow.id,
        routerId: router.id,
        interface: interfaceFlow,
        index: parseInt(index),
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível salvar dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados Salvos com sucesso",
          severity: "success",
        });
        if (response.data) {
          handleEditInterfaces(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    } finally {
      setLoadingTable(false);
      setDisableEditButton(false);
      setOpenEditModal(false);
    }
  }
  return (
    <>
      <Modal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <Paper sx={styles.paperModal} elevation={3}>
          <DialogTitle color="primary">Edite Interfaces </DialogTitle>{" "}
          <DialogContent sx={styles.dialogContent}>
            <Stack direction="row" spacing={"20px"}>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel>Exportador</InputLabel>
                <Select
                  value={router}
                  label="Exportador"
                  onChange={(event) => {
                    setRouter(event.target.value);
                  }}
                >
                  {routers?.map((router, index) => (
                    <MenuItem key={index} value={router}>
                      {router.nome_bgp ? router.nome_bgp : "Indefinido"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
            </Stack>
            <Stack direction="row" spacing={"20px"}>
              <TextField
                sx={styles.TextField}
                id="textfield-interface"
                label="Interface"
                variant="standard"
                onKeyDown={handleKeyDown}
                value={interfaceFlow}
                onChange={(e) =>
                  setInterfaceFlow(e.target.value.replace(/\s/g, ""))
                }
              />
              <TextField
                sx={styles.TextField}
                id="textfield-index"
                label="Index"
                variant="standard"
                type="number"
                value={index}
                onChange={(e) => setIndex(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                setOpenEditModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={disableEditButton}
              onClick={() => {
                handleApiPutInterfaces();
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </Paper>
      </Modal>
    </>
  );
}
