const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  boxTitle: { display: "flex", flexDirection: "row", alignItems: "center" },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    width: "52vh",

    padding: "20px",
    justifyContent: "center",
  },
  TextField: {
    width: "200px",
  },
  DialogTitle: { color: "primary.main" },
  boxProduto: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "8px",
  },
});

export default useStyles;
